<template>
  <div class="live-main">
    <div class="live-system" :class="{'live-on-room': currentRoom !== null}">
      <!-- modais -->
      <b-modal ref="live-print-modal" title="Imprimir conversa" id="live-print-modal">
        <div class="iframe-content"><iframe srcdoc="&nbsp;" ref="live-print-content"></iframe></div>
        <template #modal-footer="{ cancel }">
          <b-button size="sm" variant="secondary" @click="cancel()">
            Cancelar
          </b-button>
          <b-button size="sm" variant="primary" @click="requestPrint()">
            Imprimir
          </b-button>
        </template>
      </b-modal>
      <b-modal ref="live-send-file-modal" hide-footer title="Enviar arquivo" id="live-send-file-modal">
        <div class="form-group row">
          <div class="col-md-9">
            <input
              ref="uploadedFile"
              type="file"
              accept=".doc, .png, .jpeg, .jpg, .ogg, .webp, .doc, .docx, .zip, .exe, .pdf"
              hidden
              @change="uploadFile"
            />
            <div class="input-group mb-3">
              <div class="form-control">{{ form.file }}</div>
              <div class="input-group-append">
                <button
                  class="btn btn-primary"
                  type="button"
                  @click="openFileUpload"
                >
                  <template v-if="form.file">Trocar Arquivo</template>
                  <template v-else>Selecionar Arquivo</template>
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <button class="btn btn-success w-100" :disabled="isSending" @click="sendFile">Enviar</button>
          </div>
          <div class="col-md-12" v-if="uploadProgress > 0">
            <div class="progress">
              <div
                class="
                  progress-bar
                  progress-bar-striped
                  progress-bar-animated
                "
                role="progressbar"
                :style="`width: ${uploadProgress}%;`"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {{ uploadProgress }}%
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <hr>
            <div class="alert alert-warning">Arquivos permitidos: jpeg, png, ogg, mp4 (apenas H.264), webp (max 100kb, 512x512), doc, pdf, zip</div>
            <hr>
          </div>
          <div class="col-sm-12">
            <h3 class="title is-3">Gravar Audio</h3>
            <p class="subtitle"><strong>Pressione</strong> no migrofone para gravar</p>
            <div class="row">
              <div class="col-sm-2">
                <!-- <VueRecordAudio @stream="onStream" :mode="'press'" @result="onResult"/> -->
              </div>
              <div class="col-sm-10">
                <div class="recorded-audio">
                  <div class="recorded-item">
                    <div class="audio-container">
                      <audio :src="audioBlob" controls="controls" style="float: left; margin-top: 4px;"></audio>
                      <button
                        class="live-enter btn-send-audio"
                        @click="sendRecord"
                        v-if="readySend"
                      >
                        <span class="sr-only">Enviar mensagem</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal ref="live-agents" hide-footer title="Selecione um agente">
        <!-- <div>{{$live.departments}}</div> -->
        <div class="live-transfer-list">
          <div class="qt-loader qt-loader-mini" v-if="$live.agentsLoading">
            <b-spinner variant="primary" type="grow" small></b-spinner>
            <b-spinner variant="primary" type="grow" small></b-spinner>
            <b-spinner variant="primary" type="grow" small></b-spinner>
          </div>
          <div class="p-20" v-else-if="!$live.agents.length">
            Não há outros agentes online
          </div>
          <b-list-group v-else>
            <template v-for="agent of $live.agents">
              <b-list-group-item button :key="agent.id" @click="transferToAgent(agent.id)">
                Transferir para <strong>{{agent.name}}</strong>
              </b-list-group-item>
            </template>
          </b-list-group>
        </div>
      </b-modal>
      <b-modal ref="live-departments" hide-footer title="Selecione um departamento">
        <!-- <div>{{$live.departments}}</div> -->
        <div class="live-transfer-list">
          <div class="qt-loader qt-loader-mini" v-if="$live.departmentsLoading">
            <b-spinner variant="primary" type="grow" small></b-spinner>
            <b-spinner variant="primary" type="grow" small></b-spinner>
            <b-spinner variant="primary" type="grow" small></b-spinner>
          </div>
          <div class="p-20" v-else-if="!$live.departments.length">
            Não há departamentos cadastrados
          </div>
          <b-list-group v-else>
            <!-- <template v-for="department of $live.departments"> -->
            <template v-for="department of $live.departments">
              <b-list-group-item button :key="department.id" @click="transferToDepartment(department.id)">
                Transferir para <strong>{{department.name}}</strong>
              </b-list-group-item>
            </template>
          </b-list-group>
        </div>
      </b-modal>
      <b-modal ref="live-mute-modal" hide-footer>
        <template #modal-title>
          Silenciar por
        </template>
        <b-list-group class="live-audio">
          <b-list-group-item
            button
            :active="this.muteStatus === 'disabled'"
            v-if="this.muteStatus !== 'disabled'"
            @click="changeMute('disable')">
            <i class="fas fa-check"></i>
            Reativar áudio
          </b-list-group-item>
          <b-list-group-item :active="this.muteStatus === '8_hours'" @click="changeMute('8_hours')" button>
            <i class="fas fa-check"></i>
            8 horas
          </b-list-group-item>
          <b-list-group-item :active="this.muteStatus === '1_week'" @click="changeMute('1_week')" button>
            <i class="fas fa-check"></i>
            Uma semana
          </b-list-group-item>
          <b-list-group-item :active="this.muteStatus === '1_month'" @click="changeMute('1_month')" button>
            <i class="fas fa-check"></i>
            Um mês
          </b-list-group-item>
          <b-list-group-item :active="this.muteStatus === 'always'" @click="changeMute('always')" button>
            <i class="fas fa-check"></i>
            Sempre
          </b-list-group-item>
        </b-list-group>
        <!-- <label class="d-block">
          <input
            type="radio"
            name="live_muted"
            value="disable"
            @click="changeMute"
            :checked="this.muteStatus === 'disabled'"
          >
          Desabilitar
        </label>
        <label class="d-block">
          <input
            type="radio"
            name="live_muted"
            value="8_hours"
            @click="changeMute"
            :checked="this.muteStatus === '8_hours'"
          >
          8 horas
        </label>
        <label class="d-block">
          <input
            type="radio"
            name="live_muted"
            value="1_week"
            @click="changeMute"
            :checked="this.muteStatus === '1_week'"
          >
          Um semana
        </label>
        <label class="d-block">
          <input
            type="radio"
            name="live_muted"
            value="1_month"
            @click="changeMute"
            :checked="this.muteStatus === '1_month'"
          >
          Um mês
        </label>
        <label class="d-block">
          <input
            type="radio"
            name="live_muted"
            value="always"
            @click="changeMute"
            :checked="this.muteStatus === 'always'"
          >
          Sempre
        </label> -->
      </b-modal>
      <!-- modais -->
      <div class="live-menu">
        <header>
          <div class="live-reconnecting" v-if="$live.reconnecting !== -1">
            <i class="fas fa-exclamation-circle"></i>
            <span v-if="$live.reconnecting !== 0">Reconectando em {{ $live.reconnecting }} segundos</span>
            <span v-else>Conectando...</span>
          </div>
          <template v-else-if="isManager">
            <button class="live-sound" @click="closeAgent()" v-if="currentAgent">
              <i class="fas fa-angle-left"></i>
            </button>
            <div>
              <h3 v-if="currentAgent">
                <span class="trimmed">{{currentAgent.name}}</span>
                <i class="live-agent-status" :class="{
                  'bg-success': currentAgent.status === 'AVAILABLE',
                  'bg-warning': currentAgent.status === 'UNAVAILABLE',
                  'bg-danger': currentAgent.status === 'OFFLINE',
                  'bg-info': (
                    currentAgent.status === 'SHORT_BREAK' ||
                    currentAgent.status === 'BREAK' ||
                    currentAgent.status === 'LONG_BREAK'
                  ),
                }"></i>
              </h3>
              <h3 v-else>Agentes</h3>
            </div>
          </template>
          <template v-else>
            <button class="live-sound" @click.prevent="chooseMute">
              <!-- <i :class="{ fas: true, 'fa-volume-mute': isMuted, 'fa-volume-up': !isMuted }"></i> -->
              <i class="fas fa-bell-slash" v-if="isMuted"></i>
              <i class="fas fa-bell" v-else></i>
            </button>
            <div v-if="$live.queue > 0">
              <h3>
                {{$live.queue > 999 ? '999+' : $live.queue}}
                cliente{{$live.queue > 1 ? 's' : ''}}
              </h3>
              Em espera
            </div>
            <div v-else>
              <h3 v-if="$live.nextRequesting"></h3>
              <h3 v-else-if="$live.queuedEnabled">Não há clientes</h3>
              <h3 v-else>Não há clientes em espera</h3>
              <!-- <div>muteTime: {{muteTime}}</div>
              <div>muteStatus: {{muteStatus}}</div>
              <div>isMuted: {{isMuted}}</div> -->
            </div>
            <div class="live-next-request" v-if="$live.nextRequesting">
              <b-spinner type="grow" small></b-spinner>
              <b-spinner type="grow" small></b-spinner>
              <b-spinner type="grow" small></b-spinner>
            </div>
            <button
              class="btn btn-warning live-next"
              @click="next"
              v-else-if="$live.queue > 0 && $live.queuedEnabled"
              :disabled="$live.queueLimited">Próximo</button>
          </template>
        </header>
        <div class="live-no-ready" v-if="$live.alreadyConnected">
          <div>
            <img src="@/assets/services.svg">
            <h4>O seu "Live" está conectado em outro dispositivo</h4>
          </div>
        </div>
        <div class="live-load" v-else-if="!$live.ready">
          <b-spinner variant="primary" type="grow" small></b-spinner>
          <b-spinner variant="primary" type="grow" small></b-spinner>
          <b-spinner variant="primary" type="grow" small></b-spinner>
        </div>
        <div class="live-menu-contents" v-else-if="isManager && currentAgent === null">
          <div class="filter-agents">
            <input type="text" class="form-control" placeholder="Busca por nome ou status..." v-model="filterAgents">
          </div>
          <div class="live-agents-list">
            <div class="sr-only">
              $live.managedAgents:
              {{$live.managedAgents}}
            </div>
            <template v-for="agent in $live.managedAgents">
              <button
                :key="`live_agent_${agent.id}`"
                class="live-choose"
                :class="{ active: currentAgent && currentAgent.id === agent.id }"
                :disabled="!agent.status"
                @click.prevent="chooseAgent(agent)"
                v-if="agent.id !== $live.myUserId && searchAgents(agent)">
                <div class="items">
                  <div class="item-left">
                    <div class="agent-profile">
                      <i class="fas fa-headset"></i>
                    </div>
                  </div>
                  <div class="item-right relative">
                    <h4>{{agent.name}}</h4>
                    <!-- <div class="last-message-item" v-html="$live.markdown(agent.lastMessage)"></div> -->
                    <!-- <span class="time-message-item">{{agent.lastUpdate | relativeDate}}</span> -->
                    <time class="time-message-item" v-if="!agent.status">Nunca acessou</time>
                    <time
                      class="time-message-item badge"
                      :title="`${$live.ready} ?? ${currentTime} x ${agent.lastUpdate}`" v-else>
                      {{[currentTime, agent.lastUpdate] | showTime}}
                    </time>
                    <span class="news-message" v-if="agent.status && agent.rooms > 0">{{agent.rooms}}</span>
                    <!-- <pre>{{room}}</pre> -->
                    <footer class="message-footer">
                      <span class="sr-only">
                        currentTime: {{currentTime ? currentTime.toISOString() : null}}
                        lastUpdate: {{agent.lastUpdate}}
                      </span>
                      <span class="agent-rooms" v-if="!agent.rooms">Sem atendimentos</span>
                      <i class="live-agent-status" :class="{
                        'bg-dark': !agent.status,
                        'bg-success': agent.status === 'AVAILABLE',
                        'bg-warning': agent.status === 'UNAVAILABLE',
                        'bg-danger': agent.status === 'OFFLINE',
                        'bg-info': (
                          agent.status === 'SHORT_BREAK' ||
                          agent.status === 'BREAK' ||
                          agent.status === 'LONG_BREAK'
                        ),
                      }"></i>
                    </footer>
                  </div>
                </div>
              </button>
            </template>
          </div>
        </div>
        <div class="live-menu-contents" v-else>
          <b-dropdown block> <!-- no-caret -->
            <template #button-content>
              <div v-if="tabHistoryIndex === 0">
                <i class="fas fa-headset"></i>
                {{activeListingTitle}}
                <span class="badge badge-success" v-if="!isManager && $live.unread">
                  {{$live.unread}}
                </span>
              </div>
              <div v-else-if="tabHistoryIndex === 1">
                <i class="fas fa-history"></i> Finalizados
              </div>
              <div v-else-if="tabHistoryIndex === 2 && !currentAgent">
                <i class="fas fa-hourglass-half"></i> Pendentes
              </div>
              <div v-else-if="tabHistoryIndex === 3 && !currentAgent">
                <i class="fas fa-undo"></i> Abandonados
              </div>
            </template>
            <b-dropdown-item-button @click="tabHistoryIndex = 0">
              <i class="fas fa-headset"></i>
              <span>{{activeListingTitle}}</span>
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="tabHistoryIndex = 1; goToTranscript()">
              <i class="fas fa-history"></i>
              <span>Finalizados</span>
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="tabHistoryIndex = 2; goToOffline()" v-if="!currentAgent">
              <i class="fas fa-hourglass-half"></i>
              <span>Pendentes</span>
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="tabHistoryIndex = 3; goToForsaken()" v-if="!currentAgent">
              <i class="fas fa-undo"></i>
              <span>Abandonados</span>
            </b-dropdown-item-button>
          </b-dropdown>
          <b-tabs v-model="tabHistoryIndex" fill>
            <b-tab
              active
              :title="currentRoom !== null ? 'Voltar' : activeListingTitle"
              @click="goToIndex()">
              <!-- <p>currentAgent.rooms: {{currentAgent?.rooms}}</p> -->
              <!-- <p>$live.hasOnCall: {{$live.hasOnCall}}</p> -->
              <div class="live-tab-queue" v-if="$live.queuedEnabled">
                <div class="live-load" v-if="$live.queueTotal === -1 && $live.status !== 'OFFLINE'">
                  <b-spinner variant="primary" type="grow" small></b-spinner>
                  <b-spinner variant="primary" type="grow" small></b-spinner>
                  <b-spinner variant="primary" type="grow" small></b-spinner>
                </div>
                <!-- <pre v-if="currentAgent !== null">currentAgent.rooms: {{currentAgent.rooms}}</pre> -->
                <div class="live-no-ready" v-if="!agentsOnCall">
                  <div>
                    <img src="@/assets/services.svg">
                    <h4>Não há atendimentos</h4>
                  </div>
                </div>
                <template v-else>
                  <button class="live-choose live-old-history" v-if="oldRoom !== null">
                    <div class="items">
                      <div class="item-left">
                        <i class="fas fa-history"></i>
                      </div>
                      <div class="item-right relative">
                        <h4>
                          <i class="fas fa-folder-open"></i>
                          {{oldRoom.protocol}}
                        </h4>
                        <!-- <div class="last-message-item">{{oldRoom.lastMessage}}</div> -->
                        <!-- <span class="time-message-item">{{oldRoom.lastMessageSentAt | relativeDate}}</span> -->
                        <time class="time-message-item" :title="oldRoom.closedAt">{{oldRoom.closedAt | relativeDate}}</time>
                        <footer class="message-footer icon">
                          <i class="fas fa-globe" v-if="oldRoom.channel === 'widget'"></i>
                          <i class="fab fa-facebook-messenger" v-else-if="oldRoom.channel === 'messenger'"></i>
                          <i class="fab fa-whatsapp" v-else-if="oldRoom.channel === 'whatsapp'"></i>
                          <i class="fab fa-whatsapp" v-else-if="oldRoom.channel === 'whatsapp-java'"></i>
                          <i class="fab fa-google" v-else-if="oldRoom.channel === 'rcs'"></i>
                          <i class="fab fa-google" v-else-if="oldRoom.channel === 'rcs-google'"></i>
                          <i class="fab fa-telegram" v-else-if="oldRoom.channel === 'telegram'"></i>
                          <i class="fab fa-instagram" v-else-if="oldRoom.channel === 'instagram'"></i>
                          <i class="fas fa-envelope" v-else-if="oldRoom.channel === 'email'"></i>
                          <GoogleBusinessIcon v-else-if="oldRoom.channel === 'google-business'" />
                          <i class="fas fa-question" v-else></i>
                        </footer>
                      </div>
                    </div>
                  </button>
                  <!--
                  this.$live.queueTotal: {{this.$live.queueTotal}}
                  this.$live.hasOnCall: {{this.$live.hasOnCall}}
                  -->
                  <!-- <div class="sr-only" v-if="isManager">
                    $live.history:
                    <pre>{{$live.history}}</pre>

                    $live.managedAgents:
                    <pre>{{$live.managedAgents}}</pre>
                  </div> -->
                  <div class="sr-only">agentsOnCall: {{agentsOnCall}}</div>
                  <template v-for="room in $live.history">
                    <!-- <button
                      v-if="room && room.roomId" -->
                    <button
                      v-if="room && room.status === 'OPEN' && (
                        isManager ? (
                          room.agent_id === currentAgent.id || room.agentId === currentAgent.id
                        ) : room.type === 'me'
                      )"
                      @click.prevent="chooseRoom(room, null)"
                      :key="`live_queue_${room.roomId}`"
                      class="live-choose"
                      :class="{ active: currentRoom && currentRoom.roomId === room.roomId }">
                      <!-- <pre>{{room}} x {{currentRoom}}</pre> -->
                      <div class="items">
                        <div class="item-left">
                          <picture v-if="$live.profilePics[room.roomId]">
                            <img :src="$live.profilePics[room.roomId]" alt="profile" @error="hideImg" />
                            <img src="@/assets/profile-v2.svg" alt="profile" class="live-photo-profile" />
                          </picture>
                          <img src="@/assets/profile-v2.svg" alt="profile" v-else/>
                        </div>
                        <div class="item-right relative">
                          <div class="sr-only">{{room}}</div>
                          <h4>{{room.username}}</h4>
                          <!-- <div style="font-size: 8pt;">
                            stomp: {{room.stomp}}<br>
                            roomId: {{room.roomId}}<br>
                            agentId: {{room.agentId}}<br>
                            myUserId: {{$live.myUserId}}<br>
                            {{room.type}} x {{room.status}}
                          </div> -->
                          <div class="last-message-item">
                            <span class="last-message-icon" v-if="room.lastMessageType">
                              <template v-if="room.lastMessageType === 'image'">
                                <i class="fas fa-image"></i> Foto
                              </template>
                              <template v-else-if="room.lastMessageType === 'video'">
                                <i class="fas fa-video"></i> Vídeo
                              </template>
                              <template v-else-if="room.lastMessageType === 'audio'">
                                <i class="fas fa-microphone"></i> Áudio
                              </template>
                              <template v-else>
                                <i class="fas fa-file"></i> Arquivo
                              </template>
                            </span>
                            <span v-html="$live.markdown(room.lastMessage)"></span>
                          </div>
                          <!-- <span class="time-message-item">{{room.lastMessageSentAt | relativeDate}}</span> -->
                          <time class="time-message-item" :title="room.lastMessageSentAt">{{room.lastMessageSentAt | relativeDate}}</time>
                          <span class="news-message" v-if="$live.newMessages[room.roomId] && room.stomp !== null">
                            {{$live.newMessages[room.roomId] > 999 ? '999+' : $live.newMessages[room.roomId]}}
                          </span>
                          <!-- <pre>{{JSON.stringify(room, null, 2)}}</pre> -->
                          <footer class="message-footer icon">
                            <i class="fas fa-globe" v-if="room.channel === 'widget'"></i>
                            <i class="fab fa-facebook-messenger" v-else-if="room.channel === 'messenger'"></i>
                            <i class="fab fa-whatsapp" v-else-if="room.channel === 'whatsapp'"></i>
                            <i class="fab fa-whatsapp" v-else-if="room.channel === 'whatsapp-java'"></i>
                            <i class="fab fa-google" v-else-if="room.channel === 'rcs'"></i>
                            <i class="fab fa-google" v-else-if="room.channel === 'rcs-google'"></i>
                            <i class="fab fa-telegram" v-else-if="room.channel === 'telegram'"></i>
                            <i class="fab fa-instagram" v-else-if="room.channel === 'instagram'"></i>
                            <i class="fas fa-envelope" v-else-if="room.channel === 'email'"></i>
                            <GoogleBusinessIcon v-else-if="room.channel === 'google-business'" />
                            <i class="fas fa-question" v-else></i>
                          </footer>
                        </div>
                      </div>
                    </button>
                  </template>
                </template>
              </div>

              <!--0 -->
              <div class="live-tab-my-listing" v-else>
                <!-- <div class="live-no-ready" v-if="!$live.hasList">
                  <div>
                    <img src="@/assets/services.svg">
                    <h4>Não há atendimentos</h4>
                  </div>
                </div> -->
                <template v-for="room in $live.history">
                  <div
                    class="parent-btn"
                    :class="{
                      active: currentRoom && currentRoom.roomId === room.roomId,
                      queue: room.status === 'QUEUE',
                      open: room.status === 'OPEN',
                      locked: room.status === 'QUEUE' && isManager,
                    }"
                    role="button"
                    :key="`live_queue_${room.roomId}`"
                    v-if="room && isManager ? (
                      room.agentId === currentAgent.id && (
                        room.status === 'QUEUE' || room.status === 'OPEN'
                      )
                    ) : (
                      (room.status === 'QUEUE' && room.type === 'my-listing') ||
                      (room.status === 'OPEN' && room.type === 'me')
                    )">
                    <button
                      type="button"
                      class="live-choose"
                      :class="{
                        active: currentRoom && currentRoom.roomId === room.roomId,
                        queue: room.status === 'QUEUE',
                        open: room.status === 'OPEN',
                        locked: room.status === 'QUEUE' && isManager,
                      }"
                      @click.prevent="chooseRoomMyListing(room)">
                      <!-- <pre>{{isManager}} // {{room.status}} / {{room.roomId}} / {{room.type}}</pre> -->
                      <div class="items">
                        <div class="item-left">
                          <picture v-if="$live.profilePics[room.roomId]">
                            <img :src="$live.profilePics[room.roomId]" alt="profile" @error="hideImg" />
                            <img src="@/assets/profile-v2.svg" alt="profile" class="live-photo-profile" />
                          </picture>
                          <img src="@/assets/profile-v2.svg" alt="profile" v-else/>
                          <aside class="my-list-status" v-if="!$live.queuedEnabled">
                            <span
                              class="badge badge-secondary"
                              v-if="room.status === 'QUEUE'">Na fila</span>
                            <span
                              class="badge badge-success"
                              v-else>Atendendo</span>
                          </aside>
                        </div>
                        <div class="item-right relative">
                          <div class="sr-only">{{room}}</div>
                          <h4>{{room.username}}</h4>
                          <div class="last-message-item">
                            <span class="last-message-icon" v-if="room.lastMessageType">
                              <template v-if="room.lastMessageType === 'image'">
                                <i class="fas fa-image"></i> Foto
                              </template>
                              <template v-else-if="room.lastMessageType === 'video'">
                                <i class="fas fa-video"></i> Vídeo
                              </template>
                              <template v-else-if="room.lastMessageType === 'audio'">
                                <i class="fas fa-microphone"></i> Áudio
                              </template>
                              <template v-else>
                                <i class="fas fa-file"></i> Arquivo
                              </template>
                            </span>
                            <span v-html="$live.markdown(room.lastMessage)"></span>
                          </div>
                          <!-- <span class="time-message-item">{{room.lastMessageSentAt | relativeDate}}</span> -->
                          <time class="time-message-item" :title="room.lastMessageSentAt">{{room.lastMessageSentAt | relativeDate}}</time>
                          <span class="news-message" v-if="$live.newMessages[room.roomId] && room.stomp !== null">
                            {{$live.newMessages[room.roomId] > 999 ? '999+' : $live.newMessages[room.roomId]}}
                          </span>
                          <!-- <pre>{{JSON.stringify(room, null, 2)}}</pre> -->
                          <footer class="message-footer icon">
                            <i class="fas fa-globe" v-if="room.channel === 'widget'"></i>
                            <i class="fab fa-facebook-messenger" v-else-if="room.channel === 'messenger'"></i>
                            <i class="fab fa-whatsapp" v-else-if="room.channel === 'whatsapp'"></i>
                            <i class="fab fa-whatsapp" v-else-if="room.channel === 'whatsapp-java'"></i>
                            <i class="fab fa-google" v-else-if="room.channel === 'rcs'"></i>
                            <i class="fab fa-google" v-else-if="room.channel === 'rcs-google'"></i>
                            <i class="fab fa-telegram" v-else-if="room.channel === 'telegram'"></i>
                            <i class="fab fa-instagram" v-else-if="room.channel === 'instagram'"></i>
                            <i class="fas fa-envelope" v-else-if="room.channel === 'email'"></i>
                            <GoogleBusinessIcon v-else-if="room.channel === 'google-business'" />
                            <i class="fas fa-question" v-else></i>
                          </footer>
                        </div>
                      </div>
                    </button>
                    <aside
                      class="my-listing-actions"
                      v-if="room.status === 'QUEUE' && !$live.queuedEnabled">
                      <button
                        type="button"
                        class="decline"
                        @click="myListingAction($event, room, false)"
                        v-if="$live.acceptanceOption || isManager">
                        <i class="fas fa-times"></i>
                        <span class="sr-only">Declinar</span>
                      </button>
                      <button
                        type="button"
                        class="accept"
                        @click="myListingAction($event, room, true)"
                        v-if="$live.acceptanceOption && !isManager">
                        <i class="fas fa-check"></i>
                        <span class="sr-only">Aceitar</span>
                      </button>
                    </aside>
                  </div>
                </template>
              </div>
              <!--0 -->

            </b-tab>
            <b-tab title="Finalizados" @click="goToTranscript()">
              <div class="live-tab-history">
                <button class="live-choose live-old-history" v-if="oldRoom !== null">
                  <div class="items">
                    <div class="item-left">
                      <i class="fas fa-history"></i>
                    </div>
                    <div class="item-right relative">
                      <h4>
                        <i class="fas fa-folder-open"></i>
                        {{oldRoom.protocol}}
                      </h4>
                      <!-- <div class="last-message-item">{{oldRoom.lastMessage}}</div> -->
                      <!-- <span class="time-message-item">{{oldRoom.lastMessageSentAt | relativeDate}}</span> -->
                      <time class="time-message-item" :title="oldRoom.closedAt">{{oldRoom.closedAt | relativeDate}}</time>
                      <!-- <pre>{{oldRoom}}</pre> -->
                      <footer class="message-footer icon">
                        <i class="fas fa-globe" v-if="oldRoom.channel === 'widget'"></i>
                        <i class="fab fa-facebook-messenger" v-else-if="oldRoom.channel === 'messenger'"></i>
                        <i class="fab fa-whatsapp" v-else-if="oldRoom.channel === 'whatsapp'"></i>
                        <i class="fab fa-whatsapp" v-else-if="oldRoom.channel === 'whatsapp-java'"></i>
                        <i class="fab fa-google" v-else-if="oldRoom.channel === 'rcs'"></i>
                        <i class="fab fa-google" v-else-if="oldRoom.channel === 'rcs-google'"></i>
                        <i class="fab fa-telegram" v-else-if="oldRoom.channel === 'telegram'"></i>
                        <i class="fab fa-instagram" v-else-if="room.channel === 'instagram'"></i>
                        <i class="fas fa-envelope" v-else-if="oldRoom.channel === 'email'"></i>
                        <GoogleBusinessIcon v-else-if="oldRoom.channel === 'google-business'" />
                        <i class="fas fa-question" v-else></i>
                      </footer>
                    </div>
                  </div>
                </button>
                <!-- <div>{{[$live.associativeTranscriptsTotal, fromGlobal]}}</div> -->
                <template v-if="$live.associativeTranscriptsTotal[fromGlobal]">
                  <template v-for="room in $live.associativeTranscriptsHistory">
                    <button
                      :key="`live_history_${room.roomId}`"
                      class="live-choose"
                      :class="{ active: currentRoom && currentRoom.roomId === room.roomId }"
                      @click.prevent="chooseRoom(room, 'transcript')"
                      v-if="room.parentRoomId.indexOf(fromGlobal) === 0"
                    >
                      <div class="items">
                        <div class="item-left">
                          <picture v-if="$live.profilePics[room.roomId]">
                            <img :src="$live.profilePics[room.roomId]" alt="profile" @error="hideImg" />
                            <img src="@/assets/profile-v2.svg" alt="profile" class="live-photo-profile" />
                          </picture>
                          <img src="@/assets/profile-v2.svg" alt="profile" class="live-photo-profile" v-else/>
                        </div>
                        <div class="item-right relative">
                          <h4>{{room.username}}</h4>
                          <div class="last-message-item">
                            <span class="last-message-icon" v-if="room.lastMessageType">
                              <template v-if="room.lastMessageType === 'image'">
                                <i class="fas fa-image"></i> Foto
                              </template>
                              <template v-else-if="room.lastMessageType === 'video'">
                                <i class="fas fa-video"></i> Vídeo
                              </template>
                              <template v-else-if="room.lastMessageType === 'audio'">
                                <i class="fas fa-microphone"></i> Áudio
                              </template>
                              <template v-else>
                                <i class="fas fa-file"></i> Arquivo
                              </template>
                            </span>
                            <span v-html="$live.markdown(room.lastMessage)"></span>
                          </div>
                          <!-- <span class="time-message-item">{{room.lastMessageSentAt | relativeDate}}</span> -->
                          <time class="time-message-item" :title="room.closedAt">{{room.closedAt | relativeDate}}</time>
                          <!-- <pre>{{room}}</pre> -->
                          <footer class="message-footer icon">
                            <i class="fas fa-globe" v-if="room.channel === 'widget'"></i>
                            <i class="fab fa-facebook-messenger" v-else-if="room.channel === 'messenger'"></i>
                            <i class="fab fa-whatsapp" v-else-if="room.channel === 'whatsapp'"></i>
                            <i class="fab fa-whatsapp" v-else-if="room.channel === 'whatsapp-java'"></i>
                            <i class="fab fa-google" v-else-if="room.channel === 'rcs'"></i>
                            <i class="fab fa-google" v-else-if="room.channel === 'rcs-google'"></i>
                            <i class="fab fa-telegram" v-else-if="room.channel === 'telegram'"></i>
                            <i class="fab fa-instagram" v-else-if="room.channel === 'instagram'"></i>
                            <i class="fas fa-envelope" v-else-if="room.channel === 'email'"></i>
                            <GoogleBusinessIcon v-else-if="room.channel === 'google-business'" />
                            <i class="fas fa-question" v-else></i>
                          </footer>
                        </div>
                      </div>
                    </button>
                  </template>
                </template>
                <div class="live-no-ready" v-if="!$live.associativeTranscriptsLoading[fromGlobal] && !$live.associativeTranscriptsTotal[fromGlobal]">
                  <div>
                    <img src="@/assets/services.svg">
                    <h4>Não há histórico</h4>
                  </div>
                </div>
                <div class="live-load" v-else-if="$live.associativeTranscriptsLoading[fromGlobal]">
                  <b-spinner variant="primary" type="grow" small></b-spinner>
                  <b-spinner variant="primary" type="grow" small></b-spinner>
                  <b-spinner variant="primary" type="grow" small></b-spinner>
                </div>
                <button
                  class="live-transcript-load-more"
                  @click="$live.requestTranscripts(fromGlobal, currentAgent)"
                  v-else-if="!$live.associativeTranscriptsNoMore[fromGlobal]">Carregar mais</button>
              </div>
            </b-tab>
            <b-tab title="Pendentes" @click="goToOffline()" v-if="!currentAgent">
              <div class="live-tab-offline">
                <!-- <template v-if="$live.associativeTranscriptsTotal.offline"> -->
                <template>
                  <template v-for="room in offClients">
                    <!-- <button
                      :key="`live_history_${room.roomId}`"
                      class="live-choose"
                      :class="{ active: currentRoom && currentRoom.roomId === room.roomId }"
                      @click.prevent="chooseRoom(room, 'transcript')"
                      v-if="room.parentRoomId.indexOf('offline') !== -1"
                    > -->
                    <button
                      :key="`live_history_${room.roomId}`"
                      class="live-choose"
                      :class="{ active: currentRoom && currentRoom.roomId === room.roomId }"
                      @click.prevent="chooseRoom(room, null)"
                    >
                      <div class="items">
                        <div class="item-left">
                          <picture v-if="$live.profilePics[room.roomId]">
                            <img :src="$live.profilePics[room.roomId]" alt="profile" @error="hideImg" />
                            <img src="@/assets/profile-v2.svg" alt="profile" class="live-photo-profile" />
                          </picture>
                          <img src="@/assets/profile-v2.svg" alt="profile" class="live-photo-profile" v-else/>
                        </div>
                        <div class="item-right relative">
                          <h4>{{room.username}}</h4>
                          <div class="last-message-item">
                            <span class="last-message-icon" v-if="room.lastMessageType">
                              <template v-if="room.lastMessageType === 'image'">
                                <i class="fas fa-image"></i> Foto
                              </template>
                              <template v-else-if="room.lastMessageType === 'video'">
                                <i class="fas fa-video"></i> Vídeo
                              </template>
                              <template v-else-if="room.lastMessageType === 'audio'">
                                <i class="fas fa-microphone"></i> Áudio
                              </template>
                              <template v-else>
                                <i class="fas fa-file"></i> Arquivo
                              </template>
                            </span>
                            <span v-html="$live.markdown(room.lastMessage)"></span>
                          </div>
                          <!-- <span class="time-message-item">{{room.lastMessageSentAt | relativeDate}}</span> -->
                          <time class="time-message-item" :title="(room.lastMessageSentAt || room.createdAt)">
                            {{(room.lastMessageSentAt || room.createdAt) | relativeDate}}
                          </time>
                          <!-- <pre>{{room}}</pre> -->
                          <footer class="message-footer icon">
                            <i class="fas fa-globe" v-if="room.channel === 'widget'"></i>
                            <i class="fab fa-facebook-messenger" v-else-if="room.channel === 'messenger'"></i>
                            <i class="fab fa-whatsapp" v-else-if="room.channel === 'whatsapp'"></i>
                            <i class="fab fa-whatsapp" v-else-if="room.channel === 'whatsapp-java'"></i>
                            <i class="fab fa-google" v-else-if="room.channel === 'rcs'"></i>
                            <i class="fab fa-google" v-else-if="room.channel === 'rcs-google'"></i>
                            <i class="fab fa-telegram" v-else-if="room.channel === 'telegram'"></i>
                            <i class="fab fa-instagram" v-else-if="room.channel === 'instagram'"></i>
                            <i class="fas fa-envelope" v-else-if="room.channel === 'email'"></i>
                            <GoogleBusinessIcon v-else-if="room.channel === 'google-business'" />
                            <i class="fas fa-question" v-else></i>
                          </footer>
                        </div>
                      </div>
                    </button>
                  </template>
                </template>
                <!-- <div class="live-no-ready" v-if="!$live.associativeTranscriptsLoading.offlines && !$live.associativeTranscriptsTotal.offline">
                  <div>
                    <img src="@/assets/services.svg">
                    <h4>Não há pendencias</h4>
                  </div>
                </div>
                <div class="live-load" v-else-if="$live.associativeTranscriptsLoading.offline">
                  <b-spinner variant="primary" type="grow" small></b-spinner>
                  <b-spinner variant="primary" type="grow" small></b-spinner>
                  <b-spinner variant="primary" type="grow" small></b-spinner>
                </div> -->
                <!-- <button
                  class="live-transcript-load-more"
                  @click="$live.requestTranscripts('offline', currentAgent)"
                  v-else-if="!$live.associativeTranscriptsNoMore.offline">Carregar mais</button> -->
              </div>
            </b-tab>
            <b-tab title="Abandonados" @click="goToForsaken()" v-if="!currentAgent">
              <div class="live-tab-forsaken">
                <div class="live-no-ready" v-if="!forsaken">
                  <div>
                    <img src="@/assets/services.svg">
                    <h4>Não há abandonados</h4>
                  </div>
                </div>
                <template v-else>
                  <template v-for="room in idleClients">
                    <button
                      :key="`live_history_${room.roomId}`"
                      class="live-choose"
                      @click="chooseRoom(room, 'idle')"
                    >
                      <div class="items">
                        <div class="item-left">
                          <picture v-if="$live.profilePics[room.roomId]">
                            <img :src="$live.profilePics[room.roomId]" alt="profile" @error="hideImg" />
                            <img src="@/assets/profile-v2.svg" alt="profile" class="live-photo-profile" />
                          </picture>
                          <img src="@/assets/profile-v2.svg" alt="profile" class="live-photo-profile" v-else/>
                        </div>
                        <div class="item-right relative">
                          <h4>{{room.username}}</h4>
                          <div class="last-message-item">
                            <span class="last-message-icon" v-if="room.lastMessageType">
                              <template v-if="room.lastMessageType === 'image'">
                                <i class="fas fa-image"></i> Foto
                              </template>
                              <template v-else-if="room.lastMessageType === 'video'">
                                <i class="fas fa-video"></i> Vídeo
                              </template>
                              <template v-else-if="room.lastMessageType === 'audio'">
                                <i class="fas fa-microphone"></i> Áudio
                              </template>
                              <template v-else>
                                <i class="fas fa-file"></i> Arquivo
                              </template>
                            </span>
                            <span v-html="$live.markdown(room.lastMessage)"></span>
                          </div>
                          <!-- <span class="time-message-item">{{room.lastMessageSentAt | relativeDate}}</span> -->
                          <time class="time-message-item" :title="room.closedAt || room.createdAt">
                            <template v-if="room.closedAt">
                              {{room.closedAt | relativeDate}}
                            </template>
                            <template v-else>
                              {{room.createdAt | relativeDate}}
                            </template>
                          </time>
                          <!-- <pre>{{room}}</pre> -->
                          <footer class="message-footer icon">
                            <i class="fas fa-globe" v-if="room.channel === 'widget'"></i>
                            <i class="fab fa-facebook-messenger" v-else-if="room.channel === 'messenger'"></i>
                            <i class="fab fa-whatsapp" v-else-if="room.channel === 'whatsapp'"></i>
                            <i class="fab fa-whatsapp" v-else-if="room.channel === 'whatsapp-java'"></i>
                            <i class="fab fa-google" v-else-if="room.channel === 'rcs'"></i>
                            <i class="fab fa-google" v-else-if="room.channel === 'rcs-google'"></i>
                            <i class="fab fa-telegram" v-else-if="room.channel === 'telegram'"></i>
                            <i class="fab fa-instagram" v-else-if="room.channel === 'instagram'"></i>
                            <i class="fas fa-envelope" v-else-if="room.channel === 'email'"></i>
                            <GoogleBusinessIcon v-else-if="room.channel === 'google-business'" />
                            <i class="fas fa-question" v-else></i>
                          </footer>
                        </div>
                      </div>
                    </button>
                  </template>
                </template>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
      <div class="live-inner" v-if="currentRoom">
        <LiveView
          ref="liveViewItem"
          @callLight="callLight"
          :roomId="currentRoom.roomId"
          :readOnly="currentRoom.status !== 'OPEN'" />
      </div>
      <div class="live-meta" :class="{'live-meta-show': metaShow}" v-if="currentRoom !== null">
        <header class="live-meta-header">
          <button class="live-meta-close" @click="closeMeta()">
            <i class="fas fa-times"></i>
          </button>
          <button class="live-photo">
            <img
              :src="$live.profilePics[currentRoom.roomId]"
              v-if="currentRoom && $live.profilePics[currentRoom.roomId]">
            <i class="fas fa-user" v-else></i>
          </button>
          <div class="live-header-name" v-if="currentRoom !== null">
            {{currentName}}
          </div>
        </header>
        <b-tabs fill v-model="tabMetaIndex">
          <b-tab active>
            <template #title>
              <i class="fas fa-info-circle"></i>
              <span class="sr-only">Detalhes do atendimento</span>
            </template>

            <div class="live-info">
              <h3>Detalhes do atendimento</h3>
              <dl>
                <!--
                <dt>
                  <span>
                    Mídias
                  </span>
                  <span>
                    Mídias
                  </span>
                  <span class="to-right c-pointer" @click="callLight(messagesMidia[0])" v-if="messagesMidia.length > 0">
                    {{messagesMidia.length}} <svg viewBox="0 0 10 21" width="10" height="21" class=""><path fill="currentColor" d="M1 15.75l5.2-5.2L1 5.35l1.5-1.5 6.5 6.7-6.6 6.6-1.4-1.4z"></path></svg>
                  </span>
                </dt>
                <div class="list-gallery mb-3">
                  <div v-for="(message, i) in currentRoom.attachments" class="list-gallery-item" :key="`galery_list-item${i}`" >
                    <div
                      :key="`message_item_${message.id}`"
                      class="list-gallery-image"
                    >
                      <button class="list-gallery-image-sub" @click="callLight(message)">
                        <div class="list-gallery-image-sub-item">
                          <div class="list-gallery-image-sub-imagem" :style="`background-image: url('${message.uri}')`"></div>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div v-if="messagesMidia.length == 0">
                    Nenhuma mídia
                  </div>
                </div>
                -->
                <template v-if="currentRoom.protocol">
                  <dt>Protocolo</dt>
                  <dd class="input-group mb-3 copy">
                    <input type="text" class="form-control" :value="currentRoom.protocol" readonly>
                    <div class="input-group-append">
                      <button
                        class="btn live-btn"
                        type="button"
                        @click="copyData('Protocolo copiado', currentRoom.protocol)"
                      >
                        Copiar
                      </button>
                    </div>
                    <!-- <div>
                      {{JSON.stringify(currentRoom, null, 4)}}
                    </div> -->
                  </dd>
                </template>
                <template v-if="$live.tabs.length !== 0 && currentRoom.protocol && !currentRoom.fromIdle">
                  <dt>Tabulação</dt>
                  <dd class="mb-3">
                    <div class="qt-loader qt-loader-mini" v-if="changingTabRoom">
                      <b-spinner variant="primary" type="grow" small></b-spinner>
                      <b-spinner variant="primary" type="grow" small></b-spinner>
                      <b-spinner variant="primary" type="grow" small></b-spinner>
                    </div>
                    <select-input
                      class="yu-custom-select"
                      label="name"
                      v-model="currentTab"
                      :reduce="(tab) => tab.id"
                      :options="$live.tabs"
                      :clearable="false"
                      :disabled="roomClosed"
                      v-else />
                    <!-- :disabled="isManager" -->
                  </dd>
                </template>
                <template v-if="currentRoom.fromOffline">
                  <button class="btn live-manager-enter bg-danger mark-as-solved" @click="markAsSolved()">
                    <i class="fas fa-check"></i> Marcar como resolvido
                  </button>
                </template>
                <!-- <template v-else-if="$live.accountTags && $live.accountTags.length && !currentRoom.fromIdle"> -->
                <template v-else-if="!currentRoom.fromIdle">
                  <dt>Tags</dt>
                  <dd class="mb-3 tags">
                    <select-input
                      taggable
                      multiple
                      push-tags
                      label="tags"
                      v-model="currentRoom.tags"
                      :options="availableRoomTags"
                      :reduce="(tag) => tag"
                      :disabled="roomClosed"
                      @input="updateTags" />
                    <!-- <div class="sr-only">
                      <pre>room.tags: {{currentRoom.tags}}</pre>
                      <pre>room.originalTags: {{currentRoom.originalTags}}</pre>
                    </div> -->
                  </dd>
                </template>
                <!-- <pre>currentRoom.tags: {{currentRoom.tags}}</pre>
                <pre>$live.accountTags: {{$live.accountTags}}</pre>
                <pre>availableRoomTags: {{availableRoomTags}}</pre> -->
                <template v-if="!currentRoom.fromIdle">
                  <dt>Inicio do atendimento</dt>
                  <dd class="mb-3">
                    {{currentRoom.createdAt | formatDate24}}
                  </dd>
                  <template v-if="roomClosed">
                    <dt>Encerramento do atendimento</dt>
                    <dd class="mb-3">
                      {{currentRoom.closedAt | formatDate24}}
                    </dd>
                  </template>
                </template>
                <dt>Canal</dt>
                <dd>
                  <span class="badge badge-secondary badge-select">
                  <template v-if="currentRoom.channel === 'widget'">webchat</template>
                  <template v-else-if="currentRoom.channel === 'whatsapp-java'">whatsapp</template>
                  <template v-else>{{currentRoom.channel}}</template>
                  </span>
                  <strong v-if="currentRoom.origin">
                    -
                    <template v-if="currentRoom.channel === 'whatsapp' || currentRoom.channel === 'whatsapp-java'">
                      {{currentRoom.origin.replace(/^(\d{2})(\d{3})(\d{3})(\d{4,})$/, '+$1 $2 $3 $4')}}
                    </template>
                    <template v-else>{{currentRoom.origin}}</template>
                  </strong>
                </dd>
                <template></template>
                <!-- <dt>Iniciado em</dt>
                <dd class="input-group mb-3">
                  <span class="badge badge-secondary badge-select"></span>
                </dd> -->
                <dt>Sessão</dt>
                <dd v-if="currentRoom.session">
                  <i class="session" :class="{
                    'bg-success': currentRoom.session === 'opened',
                    'bg-warning': currentRoom.session === 'closing',
                    'bg-danger': currentRoom.session === 'closed',
                  }"></i>
                  <span class="session-time">
                    <template v-if="currentRoom.session === 'opened'">Aberta</template>
                    <template v-else-if="currentRoom.session === 'closing'">Encerrando</template>
                    <template v-else-if="currentRoom.session === 'closed'">Encerrada</template>
                    ({{sessionMinutes}})
                  </span>
                  <!-- <pre class="sr-only">{{currentRoom}}</pre> -->
                </dd>
              </dl>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <i class="fas fa-user"></i>
              <span class="sr-only">Detalhes do contato</span>
            </template>

            <div class="live-info" v-if="currentRoom !== null">
              <h3>Detalhes do contato</h3>

              <!-- -->
              <div class="sr-only">{{currentRoom}}</div>

              <!-- <template v-for="(value, key, index) in currentRoom.contact"> -->
              <template v-for="(item, index) in editFields">
                <div :key="index" class="field-edit">
                  <!-- {{key}} : {{value}} -->
                  <h3>{{item.label}}</h3>
                  <div class="input-group mb-3 copy">
                    <template v-if="editStatus[item.key] !== 'editing' && editStatus[item.key] !== 'saving'">
                      <input
                        :value="currentRoom.contact[item.key]"
                        :placeholder="`Defina um ${item.label} ...`"
                        class="form-control read"
                        type="text"
                        readonly>
                      <div class="input-group-append">
                        <button
                          class="btn live-btn"
                          type="button"
                          v-if="!isManager"
                          @click="editInput(item.key)">Editar</button>
                      </div>
                    </template>
                    <template v-else>
                      <input
                        :type="item.key === 'birthDate' ? 'date' : 'text'"
                        class="form-control edit"
                        v-model="currentRoom.contact[item.key]"
                        :ref="`live-${item.key}-input`"
                        :readonly="editStatus[item.key] === 'saving'"
                        @keydown.enter="saveInput(item.key)"
                        @keydown.esc="cancelInput(item.key)">
                      <div class="input-group-append">
                        <button
                          class="btn live-btn"
                          type="button"
                          :disabled="editStatus[item.key] === 'saving'"
                          @click="saveInput(item.key)">
                          <span v-if="editStatus[item.key] === 'editing'">Salvar</span>
                          <b-spinner small label="Loading..." v-else></b-spinner>
                        </button>
                      </div>
                    </template>
                  </div>
                </div>
              </template>
              <!-- -->
            </div>
          </b-tab>
          <b-tab v-if="hasFiles">
            <template #title>
              <i class="fas fa-folder-open"></i>
              <span class="sr-only">Arquivos</span>
            </template>

            <!-- {{tabFileType}} -->

            <b-tabs class="live-list-files flex-nowrap" fill v-model="tabFileType">
              <b-tab>
                <template #title>
                  <i class="fas fa-photo-video"></i>
                  Mídia
                </template>
              </b-tab>
              <b-tab>
                <template #title>
                  <i class="fas fa-play"></i>
                  Áudio
                </template>
              </b-tab>
              <b-tab>
                <template #title>
                  <i class="fas fa-folder-open"></i>
                  Docs
                </template>
              </b-tab>
            </b-tabs>
            <div class="row live-grid-files">
            <template v-for="msg in fromTranscript ? $live.associativeTranscriptsMessages : $live.messages">
              <template v-if="msg.roomId === currentRoom.roomId && msg.attachments.length">
                <template v-for="file of msg.attachments">
                  <div
                    class="col-4"
                    :key="file.id"
                    v-if="
                      (tabFileType === 0 && (file.type === 'video' || file.type === 'image')) ||
                      (tabFileType === 1 && file.type === 'audio') ||
                      (
                        tabFileType === 2 &&
                        file.type !== 'audio' &&
                        file.type !== 'image' &&
                        file.type !== 'video'
                      )
                    ">
                    <button
                      @click="showFile(file)"
                      v-if="file.type === 'video' || file.type === 'audio' || file.type === 'image'">
                      <video
                        :src="file.uri"
                        :type="file.media_type"
                        v-if="file.type === 'video'"></video>
                      <div class="live-file-doc" v-else-if="file.type === 'audio'">
                        {{file.uri | fileExtension}}
                      </div>
                      <img
                        :src="file.uri"
                        v-else-if="file.type === 'image'" />
                      <i class="fas fa-video" v-if="file.type === 'video'"></i>
                      <i class="fas fa-image" v-else-if="file.type === 'image'"></i>
                      <i class="fas fa-headphones" v-else-if="file.type === 'audio'"></i>
                      <span>{{file.name}}</span>
                      <!-- <span>{{file.uri | basename}}</span> -->
                    </button>
                    <a :href="file.uri" target="_blank" v-else>
                      <div class="live-file-doc">
                        {{file.uri | fileExtension}}
                      </div>
                      <i class="fas fa-file"></i>
                      <span>{{file.name}}</span>
                      <!-- <span>{{file.uri | basename}}</span> -->
                    </a>
                  </div>
                </template>
              </template>
            </template>
            <div class="live-no-files col-12">
              <div>
                Não há
                <span v-if="tabFileType === 0">vídeos ou fotos</span>
                <span v-if="tabFileType === 1">áudios</span>
                <span v-if="tabFileType === 2">documentos</span>
              </div>
            </div>
            </div>
          </b-tab>
          <b-tab @click="associativeHistory()">
            <template #title>
              <i class="fas fa-history"></i>
              <span class="sr-only">Histórico do contato</span>
            </template>
            <!-- {{currentRoom.roomId}}
            {{$live.associativeTranscriptsTotal}}
            {{$live.associativeTranscriptsTotal[currentRoom.roomId]}} -->
            <div class="live-info" v-if="currentRoom !== null">
              <h3>Histórico do contato</h3>
              <div class="qt-loader qt-loader-mini" v-if="$live.associativeTranscriptsLoading[currentRoom.roomId]">
                <b-spinner variant="primary" type="grow" small></b-spinner>
                <b-spinner variant="primary" type="grow" small></b-spinner>
                <b-spinner variant="primary" type="grow" small></b-spinner>
              </div>
              <div class="p-20" v-else-if="!$live.associativeTranscriptsTotal[currentRoom.roomId] || $live.associativeTranscriptsTotal[currentRoom.roomId] === 0">
                Não há histórico
              </div>
              <template v-else>
                <template v-for="history of $live.associativeTranscriptsHistory">
                  <button
                    type="button"
                    class="live-history-item"
                    :class="{
                      active: oldRoom && oldRoom.roomId === history.roomId,
                    }"
                    :key="history.id"
                    @click="chooseTranscript(history)"
                    v-if="currentRoom !== null && history.parentRoomId.indexOf(currentRoom.roomId) !== -1"
                  >
                    <!-- <pre>{{ history }}</pre> -->
                    <div class="channel">
                      <i class="fas fa-globe" v-if="history.channel === 'widget'"></i>
                      <i class="fab fa-facebook-messenger" v-else-if="history.channel === 'messenger'"></i>
                      <i class="fab fa-whatsapp" v-else-if="history.channel === 'whatsapp'"></i>
                      <i class="fab fa-whatsapp" v-else-if="history.channel === 'whatsapp-java'"></i>
                      <i class="fab fa-google" v-else-if="history.channel === 'rcs'"></i>
                      <i class="fab fa-google" v-else-if="history.channel === 'rcs-google'"></i>
                      <i class="fab fa-telegram" v-else-if="history.channel === 'telegram'"></i>
                      <i class="fab fa-instagram" v-else-if="history.channel === 'instagram'"></i>
                      <i class="fas fa-envelope" v-else-if="history.channel === 'email'"></i>
                      <GoogleBusinessIcon v-else-if="history.channel === 'google-business'" />
                      <i class="fas fa-question" v-else></i>
                    </div>
                    <p><strong>Protocolo:</strong> {{history.protocol}}</p>
                    <p v-html="$live.markdown(history.lastMessage)"></p>
                    <time :title="history.lastMessageSentAt">
                    {{ (history.createdAt || lastMessageSentAt) | relativeDate }}
                    <!-- {{history}} -->
                    </time>
                  </button>
                </template>
              </template>
            </div>
          </b-tab>
        </b-tabs>
      </div>
      <div class="live-home" v-else>
        <div>
          <div class="live-home-inner">
            <template v-if="isManager">
              <i class="fas fa-user-secret fa-fw live-home-agents"></i>
              <p></p>
            </template>
            <template v-else>
              <img
                src="@/assets/logo-v2-min.svg"
                alt="yupchat mascote"
                class="live-home-img"
              >
              <a
                :href="`/live/simulator#${$live.accountId}`"
                class="live-simulate-client"
                target="_yup-chat-simulator"
                :disabled="!$live.ready || !$live.accountId"
              >
                Simular um cliente <i class="fas fa-external-link-alt"></i>
              </a>
            </template>
            <!-- <button
              class="live-simulate-client"
              @click="requestInjectWidget"
              :disabled="!$live.ready || widget !== 0">
              Simular um cliente <i class="fas fa-external-link-alt"></i>
            </button> -->
          </div>
        </div>
      </div>
    </div>

    <div class="live-light" v-if="messageInfo">
      <div class="live-light-header">
        <div class="live-light-header-info">
          <div class="l-image">
            <i data-v-066a33e9="" class="fas fa-user"></i>
          </div>
          <div class="user-info-header">
            <strong>{{messageInfo.username}}</strong>
            <span :title="messageInfo.sentAt">{{messageInfo.sentAt | roundTime}}</span>
          </div>
        </div>
        <div class="live-light-header-close">
          <a class="c-pointer" style="margin-right: 20px" :href="messageInfo.uri" target="_blank">
            <svg viewBox="0 0 24 24" width="24" height="24" class=""><path fill="#000" d="M18.9 10.3h-4V4.4H9v5.9H5l6.9 6.9 7-6.9zM5.1 19.2v2H19v-2H5.1z"></path></svg>
          </a>
          <a class="c-pointer" style="margin-right: 20px" @click="messageInfo = null">
            <svg viewBox="0 0 24 24" width="24" height="24" class=""><path fill="#000" d="M19.8 5.8l-1.6-1.6-6.2 6.2-6.2-6.2-1.6 1.6 6.2 6.2-6.2 6.2 1.6 1.6 6.2-6.2 6.2 6.2 1.6-1.6-6.2-6.2 6.2-6.2z"></path></svg>
          </a>
        </div>
        <div style="clear:both"></div>
      </div>
      <div class="live-light-body">
        <img :src="messageInfo.uri" class="img-fluid" style="max-height: 500px;"/>
        <div class="live-light-left">
          <a class="live-light-close c-pointer" style="text-align: center;" @click="backMedia">
            <svg viewBox="0 0 30 30" width="30" height="30" style="margin: 0 auto;"><path fill="#fff" d="M19.214 21.212L12.865 15l6.35-6.35-1.933-1.932L9 15l8.282 8.282 1.932-2.07z"></path></svg>
          </a>
        </div>
        <div class="live-light-right">
          <a class="live-light-close c-pointer" style="text-align: center;" @click="nextMedia">
            <svg viewBox="0 0 30 30" width="30" height="30" style="margin: 0 auto;"><path fill="#fff" d="M11 21.212L17.35 15 11 8.65l1.932-1.932L21.215 15l-8.282 8.282L11 21.212z"></path></svg>
          </a>
        </div>
        <hr style="margin-bottom: 5px;">
        <div class="list-gallery" style="height: 80px; ">
          <div style="width: 8%" v-for="(message, i) in messagesMidia" class="list-gallery-item" :key="`galery-light_list-item${i}`" >
            <div
              :key="`message_item_${message.id}`"
              class="list-gallery-image"
              v-if="message.roomId === currentRoom.roomId"
            >
              <div class="list-gallery-image-sub">
                <div class="list-gallery-image-sub-item">
                  <div class="list-gallery-image-sub-imagem" :style="`background-image: url('${message.uri}')`"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="live-popup-item"
      :class="{
        audio: popupFile && popupFile.type === 'audio',
        video: popupFile && popupFile.type === 'video',
      }"
      v-dragglable="popupFile"
      v-if="popupFile"
    >
      <button @click="popupFile = null">
        <span class="sr-only">Fechar popup</span>
        <i class="fas fa-times"></i>
      </button>
      <header>
        <i class="fas fa-ellipsis-h"></i>
      </header>
      <video
        ref="mediaInteractive"
        controls
        :src="popupFile.uri"
        v-if="popupFile.type === 'video'"></video>
        <!-- :type="popupFile.media_type" -->
      <audio
        ref="mediaInteractive"
        controls
        :src="popupFile.uri"
        v-else-if="popupFile.type === 'audio'"></audio>
        <!-- :type="popupFile.media_type" -->
    </div>

    <vue-easy-lightbox
      :visible="galleryURI !== null"
      :imgs="galleryURI"
      :index="galleyIndex"
      @hide="galleryHide" />
  </div>
</template>

<script>
import Vue from 'vue';
import LiveView from '@/views/live/view.vue';
import Swal from 'sweetalert2';
import SelectInput from '@/components/SelectInput.vue';
import BucketService from '@/services/bucket.service';
// import Tags from '@/components/Tags.vue';
import GoogleBusinessIcon from '@/components/GoogleBusinessIcon.vue';

export default {
  name: 'Live',
  components: {
    SelectInput,
    LiveView,
    // Tags,
    GoogleBusinessIcon,
  },
  computed: {
    idleClients() {
      // v-if="room.type === 'idle'"
      const filtred = this.$live.history.filter((room) => room.type === 'idle');

      // console.log('idleClients', filtred);

      filtred.sort((a, b) => {
        const result = new Date(a.createdAt) - new Date(b.createdAt);

        if (result < 0) return -1;

        if (result > 0) return 1;

        return 0;
      });

      return filtred;
    },
    offClients() {
      const filtred = this.$live.history.filter((room) => room.type === 'client_off');

      // console.log('offClients', filtred);

      filtred.sort((a, b) => {
        const result = new Date(a.createdAt) - new Date(b.createdAt);

        if (result < 0) return -1;

        if (result > 0) return 1;

        return 0;
      });

      return filtred;
    },
    actualRoom() {
      return this.oldRoom ?? this.currentRoom;
    },
    roomClosed() {
      return this.currentRoom.status !== 'OPEN' && !!this.currentRoom.closedAt;
    },
    sessionMinutes() {
      const room = this.oldRoom ?? this.currentRoom;
      const time = room?.sessionTime;

      if (!time) return 0;

      const value = (this.currentTime - time) / 1000 / 60;

      const days = Math.ceil(value / 1440);

      if (days >= 1) return `${days} dia${days < 2 ? '' : 's'}`;

      const hours = Math.floor(value / 60);

      if (hours >= 1) return `${hours} hora${hours < 2 ? '' : 's'}`;

      return `${Math.floor(value)} minuto${value < 2 ? '' : 's'}`;
    },
    availableRoomTags() {
      const currentTags = this.currentRoom?.tags;

      const accountTags = this.$live.accountTags;

      if (!currentTags || accountTags.length === 0) return accountTags;

      return accountTags.filter((tag) => tag !== null && tag !== '' && typeof tag !== 'undefined' && currentTags.indexOf(tag) === -1);
    },
    isManager() {
      return (this.$router?.currentRoute?.path || '').indexOf('manage') !== -1;
    },
    activeListingTitle() {
      if (this.$live.queuedEnabled) return 'Ativas';

      if (this.isManager) return 'Fila do agente';

      return 'Minha fila';
    },
    fromGlobal() {
      if (this.isManager && this.currentAgent?.id) {
        return `global:${this.currentAgent.id}`;
      }

      return 'global';
    },
    agentsOnCall() {
      // return true;

      if (this.isManager) {
        // return this.currentAgent !== null && this.currentAgent.rooms > 0;

        const agent = this.currentAgent;

        if (agent === null) return false;

        // return agent.rooms > 0;

        const agentId = agent.id;

        return this.$live.history.some((room) => room.status === 'OPEN' && room.agentId === agentId);
      }

      return this.$live.hasOnCall > 0;
    },
    forsaken() {
      return this.$live.history.findIndex((room) => room.type === 'idle') !== -1;
    },
    hasFiles() {
      const room = this.oldRoom ?? this.currentRoom;

      if (!room) return false;

      const messages = this.fromTranscript ? this.$live.associativeTranscriptsMessages : this.$live.messages;

      if (!messages || !messages.length) return false;

      const roomId = room.roomId;

      for (let i = 0, j = messages.length; i < j; i += 1) {
        const msg = messages[i];
        if (msg.roomId === roomId && msg.attachments.length) return true;
      }

      return false;
    },
    currentName() {
      return this.actualRoom.username;
    },
    muteTime() {
      return this.$live.muteTime;
    },
    isMuted() {
      return this.$live.isMuted;
    },
    muteStatus() {
      return this.$live.muteStatus;
    },
  },
  filters: {
    showTime(data) {
      if (!data[0]) return '--:--';

      const value = (data[0] - new Date(data[1])) / 1000;

      if (value <= 0) return '00:00';

      if (value >= 86400) {
        const days = Math.floor(value / 86400);
        return `${days} dia${days >= 2 ? 's' : ''}`;
      }

      // setTimeout(() => { agent.lastUpdate = value + 1; }, 1000);

      const hours = Math.floor(value / 60 / 60);
      const mins = Math.floor((value / 60) % 60);
      const secs = Math.floor(value % 60);
      return `${hours}:${mins}:${secs}`.replace(/\b(\d)\b/g, '0$1').replace(/^00:/, '');
    },
  },
  watch: {
    currentRoom(newVal) {
      if (!newVal) return;

      console.log('watch:currentRoom');

      if (newVal.tabId) {
        this.currentTab = newVal.tabId;
      } else {
        this.currentTab = null;
      }

      // this.currentRoomTags = newVal?.tags ?? [];

      this.currentRoomTags = newVal.tags;

      const currentTags = this.currentRoom.tags;

      console.log('watch:currentRoom', { newVal, currentTags });
    },
    oldRoom(newVal) {
      console.log('omni:oldRoom');

      if (newVal && newVal.tabId) {
        this.currentTab = newVal.tabId;
      } else {
        this.currentTab = null;
      }

      console.log('watch:oldRoom', newVal);
    },
    currentTab(newVal) {
      console.log('omni:currentTab');

      const room = this.oldRoom ?? this.currentRoom;

      if (!room) return;

      console.log('watch:currentTab', newVal, room.tabId);

      if (newVal !== room.tabId) this.$live.changeTabRoom(room, newVal);
    },
  },
  data() {
    return {
      foobarbaz: {
        foo: 1,
        bar: 2,
        baz: 3,
      },
      currentTime: null,
      popupFile: null,
      galleryURI: null,
      galleyIndex: -1,

      changingTabRoom: false,
      currentTab: null,
      currentAgent: null,
      filterAgents: '',
      tabs: [],

      widget: 0,
      audioBlob: null,
      readySend: false,
      isSending: false,
      uploadProgress: 0,
      form: {},
      formData: {},
      data: {},
      dataAudio: null,
      messagesMidia: [],
      indexMidia: 0,
      messageInfo: null,
      oldRoom: null,
      oldMessages: [],

      currentRoom: null,

      metaShow: false,
      loadingQueue: false,

      tabHistoryIndex: 0,
      pageHistory: 1,

      tabMetaIndex: 0,
      tabFileType: 0,

      fromTranscript: false,
      // transcriptsFirstLoad: false,
      // offlineFirstLoad: false,

      edit: {
        name: { status: null, value: '' },
        phone: { status: null, value: '' },
        mail: { status: null, value: '' },
      },

      editFields: [
        { key: 'firstName', label: 'Nome' },
        { key: 'lastName', label: 'Sobrenome' },
        { key: 'birthDate', label: 'Nascimento' },
        { key: 'email', label: 'e-mail' },
        { key: 'mobileNumber', label: 'Celular' },
        { key: 'phone', label: 'Fixo' },
        // { key: 'gender', label: 'Genero' },
        { key: 'country', label: 'País' },
        { key: 'state', label: 'Estado' },
        { key: 'city', label: 'Cidade' },
        { key: 'district', label: 'Bairro' },
        { key: 'address', label: 'Endereço' },
        { key: 'number', label: 'Numero' },
        { key: 'complement', label: 'Completo' },
        { key: 'zip', label: 'CEP' },
        { key: 'description', label: 'Descrição' },
        { key: 'company', label: 'Empresa' },
      ],
      editStatus: {},

      autoMaskTimeout: 0,

      currentRoomTags: [],
    };
  },
  // beforeDestroy() {
  //   console.log('omni:beforeDestroy');
  // },
  destroyed() {
    // console.log('omni:destroyed');
    this.closeAgent();
  },
  mounted() {
    console.log('omni:mounted');

    this.detectScroll();

    this.$root.$on('live:agents', (agents) => {
      const agentId = this.currentAgent?.id;

      if (agentId) {
        console.log('live:agents', agents, agents.indexOf(agentId) !== -1, this.currentAgent?.rooms);

        if (agents.indexOf(agentId) !== -1 && this.currentAgent?.rooms) {
          this.$live.subscribeAgentRooms(this.currentAgent);
        }
      }
    });

    this.$root.$on('live:open', (room) => {
      console.log('live:open', room);

      this.oldRoom = null;
      this.currentRoom = null;
      this.metaShow = false;
      this.tabMetaIndex = 0;

      if (room.type === 'me' || room.type === 'agent-manager') this.tabHistoryIndex = 0;

      this.chooseRoom(room, null);
    });

    // this.$root.$on('live:transfer', (room) => {
    //   console.log('live:transfer', room);
    //   Swal.fire({
    //     title: 'Novo cliente',
    //     text: `O cliente ${room.name} (${room.email ?? room.phone}) foi transferido para você, deseja aceitar?`,
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'Sim',
    //   }).then((result) => {
    //     if (result.value) {
    //       // this.currentRoom = element;
    //       this.$live.transferAcceptOrReject(room.room_id, true);
    //     } else {
    //       this.$live.transferAcceptOrReject(room.room_id, false);
    //     }
    //   });
    //   if (window.confirm(`O cliente ${room.name} (${room.email ?? room.phone}) foi transferido para você, deseja aceitar?`)) {
    //     this.$live.transferAcceptOrReject(room.room_id, true);
    //   } else {
    //     this.$live.transferAcceptOrReject(room.room_id, false);
    //   }
    // });

    this.$live.on('user-history', (contactId) => {
      if (this.currentRoom && this.currentRoom.contactId === contactId && this.tabMetaIndex === 2) {
        this.tabMetaIndex = 1;
        this.tabMetaIndex = 2;
      }
    });

    this.$live.on('transferred', (element) => {
      if (this.$router.history.current.path.indexOf('/live') === 0) {
        Swal.fire({
          title: 'Novo cliente',
          text: `O atendente ${element.by.username} lhe transferiu o cliente ${element.username}, gostaria de visualizar agora?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
        }).then((result) => {
          if (result.value) {
            // this.currentRoom = element;
            this.chooseRoom(this.$live.roomInfo(element.roomId), null);
          }
        });
      } else {
        this.$root.$emit('live:notify', true);
      }
    });

    this.$live.on('joined', (room) => {
      this.chooseRoom(room, null);
    });

    setInterval(() => {
      if (this.$live.ready) this.currentTime = new Date();
    }, 1000);
  },
  methods: {
    test(aaa) {
      console.log('test', aaa);

      return aaa;
    },
    hideImg(evt) {
      console.log('hideImg', evt);
      evt.target.classList.toggle('invalid', true);
    },
    markAsSolved() {
      const room = this.currentRoom;

      if (!room) return;

      Swal.fire({
        title: 'Marcar como resolvido?',
        text: '',
        // icon: 'warning',
        icon: 'success',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) this.$live.markAsSolved(room);
      });
    },
    updateTags() {
      this.$live.updateTags(this.currentRoom);
    },
    searchAgents(agent) {
      let filter = this.filterAgents.trim();

      if (filter === '') return true;

      filter = filter.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLowerCase();

      const name = agent.name.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLowerCase();

      if (name && name.indexOf(filter) !== -1) return true;

      let status;

      switch (agent.status) {
        case 'AVAILABLE':
          status = 'disponivel';
          break;
        case 'UNAVAILABLE':
          status = 'indisponivel';
          break;
        case 'SHORT_BREAK':
          status = 'pausa curta';
          break;
        case 'BREAK':
          status = 'pausa';
          break;
        case 'LONG_BREAK':
          status = 'pausa longa';
          break;
        case 'OFFLINE':
          status = 'offline';
          break;
        default:
          return false;
      }

      // console.log({ status, filter });

      return status && status.indexOf(filter) !== -1;
    },
    galleryShow(url) {
      // this.galleryURI = url; // [url];
      this.galleryURI = [
        { src: url },
      ];
      this.galleyIndex = 0;
    },
    galleryHide() {
      this.galleryURI = null;
    },
    async showFile(file) {
      switch (file.type) {
        case 'image':
          this.galleryShow(file.uri);
          break;
        default:
          this.popupFile = file;
          this.tryAutoPlay(0);
      }
    },
    async tryAutoPlay(retry) {
      const media = this.$refs.mediaInteractive;

      console.log('tryAutoPlay', media);

      if (retry > 5) return;

      if (media) {
        await this.delay(500);
        media.pause();
        media.play();
      } else {
        await this.delay(500);
        this.tryAutoPlay(retry + 1);
      }
    },
    async delay(timeout) {
      return new Promise((resolve) => {
        setTimeout(resolve, timeout);
      });
    },
    requestInjectWidget() {
      const w = window;

      if (w.YupChat || w.widgetLoaded) return;

      w.widgetLoaded = true;

      const inject = document.createElement('script');
      inject.defer = true;
      inject.src = `${this.$live.widget}/yupchat.js`;
      inject.onload = () => {
        const account = this.$store.state.account;
        const widget = w.YupChat();
        widget.config({ align: 'left', title: account.name });
        widget.open(account.id);
      };
      inject.onerror = () => {
        setTimeout(() => {
          w.widgetLoaded = false;
          this.requestInjectWidget();
        }, 200);
      };
      document.body.appendChild(inject);
    },
    sendRecord() {
      this.sendAudio(this.dataAudio);
    },
    sendAudio(audio) {
      this.formData = new FormData();
      const file = audio;
      this.formData.append('file', file);
      this.isSending = true;
      this.formData.append('account_id', this.$live.contextApp.$store.state.account.id);
      this.formData.append('visibility', 'public');
      this.formData.append('convert_to', 'ogg');
      this.$forceUpdate();
      console.log(file);

      Promise.all([
        BucketService.uploadFile(this.formData, true, this.callBackProgress),
      ])
        .then(
          (responses) => {
            console.log(responses);
            this.uploadProgress = 0;
            const extension = responses[0].data.extension;
            const media = 'audio/ogg';

            const draft = {
              roomId: this.currentRoom.roomId,
              channel: this.currentRoom.channel,
              type: 'file',
              uri: responses[0].data.url,
              mediaType: media,
            };

            this.$live.emit('message', draft);
            this.$refs['live-send-file-modal'].hide();
          },
          (error) => {
            this.content = error;
            this.isSending = false;
            console.log(error);
          },
        )
        .finally(() => {
          this.isSending = false;
        });
    },
    onResult(data) {
      this.readySend = true;
      console.log('The blob data:', data);
      console.log('Downloadable audio', window.URL.createObjectURL(data));
      this.dataAudio = data;
    },
    onStream(stream) {
      console.log(stream);
      this.readySend = false;
    },
    callBackProgress(progress) {
      this.uploadProgress = progress;
    },
    sendFile() {
      this.isSending = true;
      this.formData.append('account_id', this.$live.contextApp.$store.state.account.id);
      this.formData.append('visibility', 'public');
      Promise.all([
        BucketService.uploadFile(this.formData, true, this.callBackProgress),
      ])
        .then(
          (responses) => {
            console.log(responses);
            this.uploadProgress = 0;
            const extension = responses[0].data.extension;
            let media = 'image/jpeg';

            switch (extension) {
              case 'ogg':
                media = 'audio/ogg';
                break;
              case 'mp4':
                media = 'video/mp4';
                break;
              case 'mpeg':
                media = 'video/mpeg';
                break;
              case 'webp':
                media = 'image/webp';
                break;
              case 'midi':
                media = 'audio/midi';
                break;
              case 'mp3':
                media = 'audio/mp3';
                break;
              case 'zip':
                media = 'application/zip';
                break;
              case 'pdf':
                media = 'application/pdf';
                break;
              case 'xml':
                media = 'application/xml';
                break;
              case 'jpeg':
                media = 'image/jpeg';
                break;
              case 'jpg':
                media = 'image/jpg';
                break;
              case 'png':
                media = 'image/png';
                break;
              default:
            }

            const draft = {
              roomId: this.currentRoom.roomId,
              channel: this.currentRoom.channel,
              type: 'file',
              uri: responses[0].data.url,
              mediaType: media,
            };

            this.$live.emit('message', draft);
            this.$refs['live-send-file-modal'].hide();
          },
          (error) => {
            this.content = error;
            this.isSending = false;
            console.log(error);
          },
        )
        .finally(() => {
          this.isSending = false;
        });
    },
    openFileUpload() {
      this.$refs.uploadedFile.click();
    },
    uploadFile(event) {
      const reader = new FileReader();
      this.importing = true;
      this.formData = new FormData();
      const file = event.target.files[0];
      this.formData.append('file', file);
      this.form.file = file.name;
      this.$forceUpdate();
    },
    nextMedia() {
      this.indexMidia += 1;
      this.messageInfo = this.messagesMidia[this.indexMidia];
    },
    backMedia() {
      this.indexMidia -= 1;
      this.messageInfo = this.messagesMidia[this.indexMidia];
    },
    callLight(message) {
      this.messageInfo = message;
      this.indexMidia = this.messagesMidia.findIndex((m) => m.id === message.id);
      console.log(this.indexMidia);
    },
    async copyData(title, data) {
      this.$toast.show({
        title: 'Sucesso',
        content: title,
        type: 'success',
      });

      this.$clipboard(data);
    },
    async print() {
      if (this.currentRoom) {
        const modal = this.$refs['live-print-modal'];
        const username = this.currentRoom.username;

        let roomId;
        let messages;

        if (this.oldRoom) {
          messages = this.$live.associativeTranscriptsMessages;
          roomId = this.oldRoom.roomId;
        } else if (this.fromTranscript) {
          messages = this.$live.associativeTranscriptsMessages;
          roomId = this.currentRoom.roomId;
        } else {
          messages = this.$live.messages;
          roomId = this.currentRoom.roomId;
        }

        if (!modal) {
          console.error('this.$refs[livePrintModal] not found');
          return;
        }

        await modal.show();

        // console.log('modal', modal);

        const frame = this.$refs['live-print-content'];

        // let content = '<html><head><style>p{border-top:thin solid #e9e8e8;margin:.4em 0 .8em;padding:.4em 0}p+div{margin:.4em 0;clear:both}code{overflow-wrap:break-word;white-space:pre-wrap;word-break:break-word}p>strong{float:left}p>time{float:right;font-size:.7em}</style><style media="print">button{display:none}</style></head><body><button onclick="print()">Imprimir</button>';

        let content = `<html><head><style>
        html{padding:0;margin:0}body{padding:1.2em;margin:0;font-size:.81em}
        .print-name{font-weight:bolder;float:left;width:12em;}
        .print-msg{border-bottom:thin solid #ececec;padding:.3em 0;page-break-inside:avoid;break-inside:avoid}
        .print-msg:last-child{border:none}
        .print-time{display:block;margin:.5em 0 1em 0;text-align:right;font-size:.96em}
        .print-inner{margin:0 0 0 12em}
        .print-msg.agent .print-name{color:#d90505}
        </style>
        </head><body>`;

        for (let i = 0, j = messages.length; i < j; i += 1) {
          const msg = messages[i];

          if (msg.roomId === roomId) {
            let username = '';

            if (msg.username) {
              username = msg.username;
            } else if (msg.from && msg.from.username) {
              username = msg.from.username;
            }

            let message;

            if (msg.attachments?.length) {
              message = `<em>&lt;${msg.attachments[0].name}&gt;</em>`;
            } else {
              message = this.$live.markdown(msg.message, true);
            }

            content += `<div class="print-msg ${msg.typeUser === 'AGENT' ? 'agent' : ''}">
            <strong class="print-name">
            ${msg.automatic ? '<automática>' : username}
            ${msg.typeUser === 'AGENT' ? ' (agente)' : ''}:
            </strong>
            <p class="print-inner">
            ${message}
            <time class="print-time">
            ${new Date(msg.sentAt).toLocaleString('pt-BR')}
            ${msg.status === 'FAIL' ? ' (não entregue)' : ''}
            </time>
            </p>
            </div>`;
          }
        }

        content += '</body></html>';

        frame.srcdoc = content;
      }
    },
    requestPrint() {
      this.$refs['live-print-content'].contentWindow.print();
    },
    async editInput(field) {
      Vue.set(this.editStatus, field, 'editing');
      this.editStatus[field] = 'editing';

      // if (input.scrollIntoView) input.scrollIntoView();

      await this.openEditInput(field);
    },
    async openEditInput(field) {
      const input = this.$refs?.[`live-${field}-input`]?.[0];

      if (!input) {
        console.log('editInput', `live-${field}-input`);
        await this.delay(20);
        await this.openEditInput(field);
      } else {
        const value = this.currentRoom.contact[field];
        // live-gender-input

        console.log('editInput', { field, input }, Object.keys(this.$refs), this.$refs);

        input.value = '';
        input.value = value;
        input.focus();
      }
    },
    saveInput(field) {
      let value = this.currentRoom.contact[field];

      if (field === 'phone' || field === 'mobileNumber') value = value.replace(/\D+/g, '');

      Vue.set(this.editStatus, field, 'saving');
      this.editStatus[field] = 'saving';

      this.$live.updateClient(this.currentRoom, field, value).then(() => {}).catch((err) => {
        console.error(err);
      }).finally(() => {
        this.cancelInput(field);
      });
    },
    cancelInput(field) {
      Vue.set(this.editStatus, field, '');
      this.editStatus[field] = '';
    },
    goToIndex() {
      // if (this.tabHistoryIndex !== 0) {
      //   // this.currentRoom = null;
      //   this.chooseRoom(null, false);
      // }
      this.chooseRoom(null, null);
    },
    goToTranscript() {
      // console.log('this.transcriptsFirstLoad', this.transcriptsFirstLoad);
      // if (!this.transcriptsFirstLoad) {
      //   this.transcriptsFirstLoad = true;
      //   this.$live.requestTranscripts(this.fromGlobal, this.currentAgent);
      // }

      this.$live.requestTranscripts(this.fromGlobal, this.currentAgent);
    },
    goToOffline() {
      // console.log('this.offlineFirstLoad', this.offlineFirstLoad);
      // if (!this.offlineFirstLoad) {
      //   this.offlineFirstLoad = true;
      //   this.$live.requestTranscripts('offline', this.currentAgent);
      // }

      // this.$live.requestTranscripts('offline', this.currentAgent);
    },
    goToForsaken() {
      console.log('Forsaken');
    },
    goToHistory() {
      this.$live.emit('history', {
        page: this.pageHistory,
        status: 'closed',
        all: false,
        perPage: 100,
      });

      this.pageHistory += 1;
    },
    transferToAgent(id) {
      const room = this.currentRoom;

      if (!room) return;

      // if (room && window.confirm('Deseja realmente transferir esse cliente?')) {
      //   this.currentRoom = null;
      //   this.$live.transferToAgent(room, id);
      //   this.$refs['live-agents'].hide();
      // }

      Swal.fire({
        title: 'Transferir',
        text: 'Deseja realmente transferir esse cliente?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          this.currentRoom = null;
          this.$live.transferToAgent(room, id);
          this.$refs['live-agents'].hide();
        }
      });
    },
    transferToDepartment(id) {
      const room = this.currentRoom;

      if (!room) return;

      // if (room && window.confirm('Deseja realmente transferir esse cliente?')) {
      //   this.currentRoom = null;
      //   this.$live.transferToDepartment(room, id);
      //   this.$refs['live-departments'].hide();
      // }

      Swal.fire({
        title: 'Transferir',
        text: 'Deseja realmente transferir esse cliente?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          this.currentRoom = null;
          this.$live.transferToDepartment(room, id);
          this.$refs['live-departments'].hide();
        }
      });
    },
    transferToQueue() {
      const room = this.currentRoom;

      Swal.fire({
        title: 'Devolver a fila',
        text: 'Deseja devolver o cliente para fila?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          this.currentRoom = null;
          this.$live.transferToQueue(room);
        }
      });
    },
    changeTabRoom(roomId) {
      this.changingTabRoom = true;

      this.$live.changeTabRoom(roomId).catch((error) => {
        this.$toast.show({
          title: 'Error',
          content: error,
          type: 'danger',
        });
      }).finally(() => {
        this.changingTabRoom = false;
      });
    },
    toggleMeta() {
      this.metaShow = !this.metaShow;
    },
    closeMeta() {
      this.metaShow = false;
    },
    log(info) {
      console.log(info);
    },
    showAgents() {
      if (this.$refs['live-agents']) {
        this.$live.requestAgents(this.currentAgent?.id);
        this.$refs['live-agents'].show();
      } else {
        console.error('$refs[live-agents] is invalid');
      }
    },
    showDepartments() {
      if (this.$refs['live-departments']) {
        this.$live.requestDepartments();
        this.$refs['live-departments'].show();
      } else {
        console.error('$refs[live-departments] is invalid');
      }
    },
    // transfer(userId) {
    //   this.transferToAgent(userId);
    // },
    chooseMute() {
      this.$refs['live-mute-modal'].show();
    },
    changeMute(type) {
      this.$refs['live-mute-modal'].hide();
      this.$live.changeMute(type);
    },
    next() {
      // const room = this.$live.next();

      // if (room) this.currentRoom = room;
      this.$live.next();
    },
    chooseAgent(agent) {
      console.log('chooseAgent#1', this.currentAgent !== agent, this.currentAgent, agent);

      if (this.currentAgent !== agent) {
        const lastAgent = this.currentAgent;

        // Observa as salas do agente atual
        this.currentAgent = agent;
        this.$live.subscribeAgentRooms(agent);

        // Se existir um agente anterior deverá fechar o observador para este
        if (lastAgent) this.$live.subscribeAgentRooms(lastAgent, false);
      }
    },
    closeAgent(onlyRoom = false) {
      console.log('closeAgent', onlyRoom, this.currentAgent);

      if (this.currentAgent) {
        // this.$live.subscribeAgentRooms(this.currentAgent, false);
        const room = this.currentRoom;

        if (onlyRoom === false) this.currentAgent = null;

        this.currentRoom = null;

        if (room) this.$live.unsubscribeRoom(room);
      }
    },
    myListingAction(event, room, accept) {
      console.log('myListingAction', room.status);

      if (event?.target?.blur) event.target.blur();

      if (room.status !== 'QUEUE') return;

      console.log('myListingAction', room.status, accept);

      if (accept) {
        if (!this.isManager) this.chooseRoom(room, null);
      } else {
        Swal.fire({
          title: 'Rejeitar',
          text: 'Tem certeza que deseja rejeitar?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
        }).then((result) => {
          if (result.value) this.$live.rejectRoom(room);
        });
      }
    },
    chooseRoomMyListing(room) {
      console.log('xxxxx', room.status === 'OPEN', this.$live.acceptanceOption, !this.isManager);

      if (room.status === 'OPEN' || (!this.$live.acceptanceOption && !this.isManager)) {
        this.chooseRoom(room, null);
      }
    },
    chooseRoom(room, fromTranscript) {
      console.log(':chooseRoom', room, ':newMessages', this.$live.newMessages);

      this.closeAgent(true);

      this.oldRoom = null;
      this.editNameStatus = null;
      this.editPhoneStatus = null;
      this.editEmailStatus = null;

      if (room) {
        this.editNameValue = room.username;

        if (room?.contact?.mobileNumber) this.editPhoneValue = this.$live.maskPhone(room.contact.mobileNumber);

        if (room.email) this.editMailValue = room.email;

        if (fromTranscript !== null) {
          this.$live.requestTranscriptMessages(room, fromTranscript === 'idle');
        } else if (!room.fromOffline && !room.fromIdle) {
          this.$live.open(room, null, true);
        }
      }

      // this.messagesMidia = [];

      // this.$live.messages.forEach((element) => {
      //   if (element && room) {
      //     if ((element.roomId === room.roomId)) {
      //       switch (element.mediaType) {
      //         case 'image/jpeg':
      //           this.messagesMidia.push(element);
      //           break;
      //         case 'image/png':
      //           this.messagesMidia.push(element);
      //           break;
      //         case 'image/jpg':
      //           this.messagesMidia.push(element);
      //           break;
      //         case 'image/webp':
      //           this.messagesMidia.push(element);
      //           break;
      //         case 'image/gif':
      //           this.messagesMidia.push(element);
      //           break;
      //         default:
      //           break;
      //       }
      //     }
      //   }
      // });

      this.currentRoom = room;
      this.metaShow = false;
      this.tabMetaIndex = 0;
      this.fromTranscript = fromTranscript;
    },
    chooseTranscript(transcript) {
      this.closeMeta();

      this.oldRoom = transcript;

      if (transcript) this.$live.requestTranscriptMessages(transcript);
    },
    chooseHistory(history) {
      this.closeMeta();

      // const room = this.$live.roomInfo(history.roomId);
      this.oldRoom = history;

      if (history) {
        this.$live.emit('messages', {
          perPage: 80,
          roomId: history.roomId,
        });
      }
    },
    closeHistory() {
      this.oldRoom = null;
    },
    detectScroll() {
      this.$live.on('$scroll', (roomId) => {
        // if (this.currentRoom) console.log('$scroll -> detectScroll', this.currentRoom.roomId, roomId);

        if (this.currentRoom && this.$refs.liveViewItem) {
          const currentRoomId = this.currentRoom.roomId;

          if (currentRoomId === roomId) this.$refs.liveViewItem.triggerScroll();
        }
      });
    },
    finish() {
      const room = this.currentRoom;

      console.log('finish', room);

      if (room && room.roomId) {
        Swal.fire({
          title: 'Finalizar conversa',
          text: 'Tem certeza que gostaria de finalizar essa conversa?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
        }).then((result) => {
          if (result.value) {
            // this.chooseRoom(null, false);
            this.$live.close(room);
          }
        });
      }
    },
    associativeHistory() {
      if (this.currentRoom) {
        this.$live.requestTranscripts(this.currentRoom);
      }
    },
    userHistory() {
      if (this.currentRoom) {
        this.$live.userHistory(this.currentRoom.contactId);
      }
    },
    autoMaskPhone() {
      // console.log('1#editPhoneValue', this.editPhoneValue);

      if (this.autoMaskTimeout !== 0) clearTimeout(this.autoMaskTimeout);

      this.autoMaskTimeout = setTimeout(() => {
        this.editPhoneValue = this.$live.maskPhone(this.editPhoneValue);

        // console.log('2#editPhoneValue', this.editPhoneValue);
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
.live-audio {
  .list-group-item::v-deep {
    i {
      vertical-align: middle;
      margin-right: .8rem;
      display: none;
      color: inherit;
    }
  }

  .list-group-item.active {
    i {
      display: inline-block;
    }
  }
}

.mark-as-solved {
  width: 100%;
  margin-bottom: 1em;

  &, i {
    color: #fff;
  }
}

.live-btn {
  font-size: 9pt;
  padding: 4px 20px;
  height: 32px;
}

.live-popup-item {
  position: fixed;
  left: 70px;
  bottom: 4px;
  z-index: 1000;
  background: rgba(0,0,0,.7);
  display: none;
  color: #fff;
  border-radius: .2em;
  width: 280px;

  @media (max-width: 992px) {
    left: 4px;
  }

  & > header {
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12pt;
    color: #fff;
    font-weight: bold;

    i, i::before { color: #fff; }
  }

  & > button {
    background: rgba(0,0,0,.5);
    border-radius: 0 4px 4px 0;
    border: none;
    position: absolute;
    z-index: 1003;
    right: -32px;
    top: 16px;
    font-size: 12pt;
    text-shadow: 1px 1px 1px rgba(0,0,0,.5);
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    &, & > i {
      color: #fff;
      padding: 0;
      margin: 0;
    }
  }

  .live-popup-sub {
    position: relative;
    z-index: 1001;
    overflow: hidden;
  }

  &.audio {
    display: block;
    height: 72px;

    audio {
      margin-left: 2%;
      width: 96%;
    }
  }

  &.video {
    display: block;
    height: 180px;

    video {
      width: 100%;
      height: 100%;
    }
  }

  video, audio {
    position: relative;
    z-index: 1002;
  }
}

::v-deep .tags {
  .b-form-tags.form-control {
        padding: .187rem .36rem;
  }

  .b-form-tag {
    font-size: 75%;
    font-weight: 400;
    line-height: 1.5;
    margin-right: .25rem;
    margin-bottom: .25rem;
  }

  .b-form-tag-remove {
    color: inherit;
    font-size: 125%;
    line-height: 1;
    float: none;
    margin-left: .25rem;
  }
}

::v-deep button {
  outline: none;
}

#live-print-modal .iframe-content {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

::v-deep #live-print-modal .modal-dialog {
  @media (min-width: 576px) {
    max-width: 90%;
  }

  min-width: 240px;

  .modal-content {
    min-height: 210px;
    height: 80vh;

    .modal-body {
      padding: 0;
    }

    iframe {
      height: 100%;
      width: 100%;
      border: none;
    }
  }
}

$border-color: #e8e4e4;

.live-list-files {
  height: auto !important;

  &::v-deep .tab-content {
    display: none !important;
  }

  &::v-deep .live-list-files {
    height: 52px !important;
  }
}

.live-meta::v-deep .nav-tabs {
  margin-bottom: 0 !important;
}

.live-no-files {
  padding: 2em;
  text-align: center;
  font-size: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.live-grid-files {
  padding: 0;
  margin: 0;
  overflow: auto;
  overflow-x: hidden;
  height: calc(100% - 52px);

  .col-4 {
    height: 100px;
    padding: .05em;

    /*
    &:first-child button {
      border-radius: 14px 0 0 0;
    }

    &:first-child button {
      border-radius: 14px 0 0 0;
    }
    */

    & ~ .live-no-files {
      display: none;
    }
  }

  a, button {
    height: 100%;
    width: 100%;
    border: none;
    overflow: hidden;
    padding: 0;
    position: relative;
    background: #a0a0a0;
    display: block;

    & > img, & > audio, & > video, .live-file-doc {
      min-width: 100%;
      min-height: 100%;
    }

    .live-file-doc {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24pt;
      font-weight: bold;
      text-transform: uppercase;
    }

    & > i {
      font-size: 80%;
      color: #fff;
      text-shadow: 1px 1px 1px rgba(0,0,0,.2);
      position: absolute;
      bottom: 5px;
      left: 5px;
    }

    & > span {
      position: absolute;
      bottom: 0;
      left: 0;
      background: rgba(0,0,0,.2);
      width: 100%;
      height: auto;
      padding: .5em;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 70%;
      text-align: right;
      overflow: hidden;
      direction: rtl;
      opacity: 0.001;
      color: #fff;
      font-weight: bold;
      transition: ease-out .2s;
    }

    &:hover > span {
      opacity: .99;
    }
  }
}

.live-load {
  text-align: center;
  padding: 24px 0;

  ::v-deep .spinner-grow {
    margin: 0 5px;
    animation-duration: 0.45s;
  }
}

.live-main {
  margin: -48px 0 0 -81px;
  overflow: hidden;
  width: calc(100% + 81px);
  height: 100vh;

  @media (max-width: 800px) {
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
  }
}

.live-system {
  min-width: 320px;
  width: 100%;
  height: 100%;
  padding: 62px 0 0 63px;
  background: #fff;

  display: flex;
  flex-direction: row;
  flex: 1;

  &::v-deep strong {
    font-weight: bold !important;
  }

  .live-agent-status {
    vertical-align: middle;
    display: inline-block;
    border-radius: 8px;
    height: 8px;
    width: 8px;
  }

  .live-agents-list {
    overflow: auto;
    overflow-x: hidden;
    height: calc(100% - 43px);
  }

  .live-menu ::v-deep .live-menu-contents {
    height: calc(100% - 80px);

    .filter-agents {
      background: #fff;
      border-bottom: thin solid #e8e8e8;

      input {
        border: none !important;
        border-radius: none !important;
        height: 42px !important;
      }
    }

    .tabs {
      height: 100%;
    }

    .dropdown-item {
      span {
        padding-left: .5em;
      }

      span, i {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  .live-meta ::v-deep .tabs {
    height: calc(100% - 180px);
  }

  ::v-deep .tab-content {
    height: calc(100% - 52px);
  }

  ::v-deep .tab-pane {
    height: 100%;
  }

  .live-menu::v-deep .nav-tabs {
    margin-bottom: 0 !important;
    display: none;
  }

  .dropdown::v-deep {
    .dropdown-menu {
      left: 1px !important;
      width: calc(100% - 1px) !important;
      margin: 0 !important;
      border-radius: 0 !important;
    }

    .btn.dropdown-toggle {
      border-radius: 0 !important;
      margin: 0;
      padding: 0 1.5em;
      height: 52px;
      font-weight: bold;
      color: #7c7c7c !important;
      background: #fff !important;
      text-align: left;
      position: relative;

      &, &:focus, &:hover {
        box-shadow: none !important;
        border: none !important;
        border-bottom: 1px solid #e8e4e4 !important;
      }

      & > div > .badge, &::after {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }

      & > div > .badge {
        right: 25px;
      }
    }
  }

  ::v-deep .nav-tabs .nav-link {
    padding-bottom: 14px;
    padding-top: 14px;
    height: 52px;

    &.active {
      border-bottom: 4px solid #0E1421 !important;
    }
  }

  ::v-deep [class^="live-"] > header, ::v-deep [class*=" live-"] > header {
    border-bottom-width: thin !important;
    height: 80px;
  }

  ::v-deep [class^="live-"] > header,
  ::v-deep [class*=" live-"] > header,
  ::v-deep [class^="live-"] > footer,
  ::v-deep [class*=" live-"] > footer {
    background-color: #fff;
  }

  @media (max-width: 992px) {
    padding-left: 81px;
  }

  @media (max-width: 800px) {
    padding-top: 110px;

    &.live-on-room {
      .live-menu { flex: 0 0 0 !important; }
      .live-inner { flex: 0 0 100% !important; }
    }
  }
}

body.rebranding {
  .live-system {
    padding: 62px 0 0 67px;
  }
}

.live-menu, .live-meta, .live-inner {
  transition: .2s ease;
  overflow: hidden;
}

::v-deep .live-photo {
  padding: 0 !important;
  margin: 20px 0 0 0;
  background: #ccc;
  height: 80px;
  width: 80px;
  border: none;
  border-radius: 50%;
  font-size: 32pt;
  margin-bottom: 5px;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  border-radius: 40px;
  overflow: hidden;

  &, * {
    text-align: center;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.live-meta-close {
  width: 36px;
  height: 36px;
  border: none;
  background: transparent;
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 16pt;
  display: none;

  @media (max-width: 1230px) {
    display: block;
  }
}

.live-menu,
.live-meta,
.live-system ::v-deep header[class*="live-"],
.live-system ::v-deep footer[class*="live-"] {
  border: 0 solid $border-color;
}

.live-menu {
  // float: left;
  width: 300px;
  flex: 0 0 300px;
  height: 100%;
  border-right-width: thin;

  @media (max-width: 800px) {
    flex: 0 0 100%;
  }

  header {
    background: #0E1421 !important;
    color: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 18px;
    overflow: hidden;

    .live-reconnecting {
      i {
        margin-right: 10px;
        font-size: 20pt;
        vertical-align: middle;
      }
    }

    div {
      flex: 1 0 auto;
      padding: 0 5px;
      font-size: 9pt;

      h3 {
        position: relative;
        font-weight: bold;
        font-size: 12pt;
        margin: 0;
        color: #fff;

        .live-agent-status {
          position: absolute;
          right: 0;
          top: 50%;
          margin-top: -2px;
        }

        .trimmed {
          vertical-align: middle;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          display: inline-block;
          max-width: 210px;
          width: 100%;
        }
      }
    }

    .live-sound {
      flex: 0 0 24px;
      border: none;
      background: transparent;

      i {
        color: #fff;
      }
    }

    .live-next, .live-next-request {
      flex: 0 0 110px;
    }

    .live-next-request {
      text-align: center;

      & > span {
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  ::v-deep .nav-tabs .nav-item {
    width: 50%;
  }
}

.live-transcript-load-more {
  border-radius: 36px;
  background: #fff;
  border: 2px solid #0E1421;
  color: #0E1421;
  margin: 16px 10%;
  width: 80%;
  padding: .7em 0;
  font-size: 9pt;
  text-transform: uppercase;
  font-weight: bold;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

.live-tab-queue,
.live-tab-history,
.live-tab-offline,
.live-tab-forsaken,
.live-tab-my-listing {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.agent-profile {
  background: #ececec;
  border-radius: 16px;
  display: inline-block;
  height: 32px;
  width: 32px;
  padding-top: 4px;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.live-choose {
  position: relative;
  padding: 0;
  margin-bottom: 0px;
  background: transparent;
  display: block;
  border: 0 solid transparent;
  border-right-width: 2px;
  width: 100%;
  border-radius: 0px;
  text-align: left;
  transition: .3s all ease-out;
  outline: 0px;

  &.locked {
    cursor: not-allowed !important;
  }

  &:hover {
    background: #efefef;
  }

  &[disabled] {
    background: transparent;
    opacity: .5;
  }

  &.active {
    background: #ececec;
    border-right-color: #0E1421;
  }

  .items {
    margin: 0 !important;
    height: 85px;
    // height: 185px;
  }

  .item-left {
    padding: 0 10px;
    float: left;
    width: 70px;
    height: 100%;
    position: relative;

    & > picture, & > img, & > i {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }

    & > picture {
      font-size: 0;
      padding: 0;
      margin: 0;
      overflow: hidden;
      height: 54px;
      width: 54px;
      display: block;
      border-radius: 22px;

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;

        &.invalid {
          display: none;
        }
      }

      img + img {
        display: none;
      }

      img.invalid + img {
        display: inline;
      }
    }
  }

  .item-right {
    border-bottom: 1px solid #e8e8e8;
    padding: 1.6em .8em .6em 0;
    margin-left: 70px;
    height: 100%;
  }

  h4 {
    font-size: 10pt;
    font-weight: bold;
    width: 88%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .agent-rooms {
    margin-right: 5px;
    display: inline-block;
    color: #c0c0c0;
    font-size: 7pt;
  }

  .time-message-item {
    font-size: 8pt;
    position: absolute;
    right: 8px;
    top: 8px;
    min-width: 56px;
    text-align: center;

    &.badge {
      color: #fff;
      background: #5c6166;
      padding: .05em .35em !important;
    }
  }

  .news-message {
    border-radius: 64px;
    padding: .3em .7em;
    background: rgba(0,0,0,.4);
    color: #fff;
    font-size: 8pt;
    position: absolute;
    right: .8em;
    top: 47%;
    transform: translateY(-50%);
    min-width: 22px;
    min-height: 22px;
    text-align: center;
  }

  .message-footer {
    text-align: right;
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: .3em .5em;

    &.icon {
      position: absolute;
      left: -32px;
      top: 8px;
      right: auto;
      width: auto;

      & > i {
        border-radius: 30px;
        border: 2px solid #fff;
        background: #fff;
      }
    }
  }

  .last-message-item {
    font-size: 9pt;
    color: #a1a1a1;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    width: 90%;
    overflow: hidden;
  }

  .last-message-icon {
    background-color: #eee;
    border-radius: 10px;
    display: inline-block;
    margin-right: .5em;
    padding: .2em .8em;
  }
}

.my-list-status {
  position: absolute;
  bottom: 6px;
  left: 0;
  z-index: 100;
  font-size: 72%;
  width: 70px;
  text-align: center;
}

.queue:hover {
  .my-listing-actions {
    transform: translateX(0) !important;
  }
}

.parent-btn {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.my-listing-actions {
  padding: 5px;
  position: absolute;
  width: 60%;
  bottom: 0;
  right: 0;
  white-space: nowrap;
  transition: all .2s ease-out;
  text-align: right;
  transform: translateX(100%);

  button {
    border: none;
    background: #ccc;
    color: #fff;
    width: 32px;
    height: 32px;
    margin: .2rem;
    border-radius: 32px;

    i {
      color: inherit;
      pointer-events: none;
    }
  }

  button.accept {
    background: #6eda2c;
  }

  button.decline {
    background: #f00;
  }
}

.live-tab-my-listing {
  button.open:not(.active) {
    border-right-color: #00b612;
  }
}

.live-meta {
  // float: right;
  background: #fff;
  flex: 0 0 300px;
  height: 100%;
  border-left-width: thin;

  .session {
    margin-right: 8px;
    display: inline-block;
    background: #ccc;
    border-radius: 9px;
    height: 18px;
    width: 18px;
    vertical-align: middle;
  }

  .session-time {
    vertical-align: middle;
  }

  @media (max-width: 1230px) {
    // box-shadow: 0 2px 6px rgba(0, 0, 0, .3);
    flex: 0 0 0;
    z-index: 100;
    position: fixed;
    top: 62px;
    right: 0;
    width: 0;

    & > * {
      min-width: 300px;
    }

    &.live-meta-show {
      box-shadow: 0 22px 25px 9px rgba(0,0,0,.2);
      height: calc(100% - 62px);
      width: 300px;

      @media (max-width: 700px) {
        width: 100%;
      }
    }
  }
}

.live-inner {
  background-color: #f8f8f8;
  height: 100%;
  width: 100%;

  @media (max-width: 700px) {
    width: 0;
    flex: 0 0 0;
  }
}

.live-info {
  padding: 14px;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;

  h3 {
    font-size: 11pt;
    font-weight: bold;
  }

  dl {
    padding: .4em 0;
    margin: 0;

    dt {
      font-size: 10pt;
    }

    dd {
      margin-bottom: 2em;
      padding: .2em .2em .2em .2em;

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }

      &, > * {
        font-size: 10pt;
      }
    }

    .copy {
      input {
        padding: 0;
        background: transparent;
        border: none;
        margin-right: 5px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      button {
        border-radius: 50px !important;
        border: thin solid #ccc;
      }
    }
  }
}

.live-meta-header {
  height: 180px !important;
  position: relative;
  text-align: center;
}

.live-home {
  flex: 0 0 calc(100% - 300px);
  height: 100%;
  overflow: hidden;
  background: #fafafa;

  .live-simulate-client {
    margin: 0 auto;
    display: block;
    border-radius: 5em;
    border: none;
    padding: .8em 1.4em;
    color: #0E1421;
    border: .18em solid #0E1421;
    text-align: center;
    transition: .2s all ease;

    &:hover {
      background: #0E1421;
      color: #fff;
    }

    &:disabled, &[disabled='disabled'] {
      pointer-events: none;
      cursor: not-allowed;
      opacity: .3;
    }

    i {
      color: inherit;
      margin-left: .2em;
    }
  }

  & > div {
    position: relative;
    height: 100%;
  }

  .live-home-inner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }

  .live-home-img {
    position: relative;
    left: 0;
    margin-bottom: 32px;
    pointer-events: none;
    max-width: 240px;
    width: 100%;
  }

  .live-home-agents {
    font-size: 150pt;
    color: #dfdfdf;
  }

  @media (max-width: 800px) {
    display: none;
  }
}

.live-header-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
  width: 262px;
  font-size: 13pt;
  font-weight: 500;
  margin: 0 auto;
}

.live-meta-header {
  & > div:first-child,
  & > div:first-child + div {
    height: 100%;
  }

  & > div:first-child {
    width: 60px;
    float: left;
    text-align: center;
    padding-top: 22px;
  }

  & > div:last-child {
    text-align: center;
  }

  & > div:first-child + div {
    float: right;
    width: 42px;
  }
}

.live-photo-profile {
  max-width: 50px !important;
  display: block;
  margin: 0 auto;
}

.live-no-ready {
  text-align: center;
  padding: 2em 0;
  pointer-events: none;
  height: 100%;
  display: flex;
  max-width: 200px;
  margin: 0 auto;
  align-items: center;

  h4 {
    margin-top: 20px;
    font-size: 10pt;
    display: inline-block;
    background: #eee;
    padding: 5px 10px;
    border-radius: 20px;
  }
}

::v-deep .dropdown-menu {
  top: auto !important;
  left: auto !important;
  transform: none !important;
}

.field-edit {
  margin-top: 1em;

  h3 {
    font-weight: normal;
    color: #c0c0c0;
  }

  button {
    border-radius: 25px !important;
    border: thin solid #c0c0c0;
    padding: .05em 1.5em;
  }

  .read + button {
    visibility: hidden;
  }

  &:hover button {
    visibility: visible;
  }

  input {
    padding: .8em;
    margin-right: 6px;

    &.read {
      border: none;
      background: transparent;
    }

    &.edit {
      border-radius: 25px;
    }
  }
}

.live-history-item {
  background: #fff;
  border-radius: .4em;
  border: thin solid #ccc;
  margin: .4em 1em .4em 0;
  padding: .6em;
  position: relative;
  width: 97%;
  text-align: left;
  transition: .15s all ease;

  &:hover {
    background: #ececec;
  }

  p {
    margin: 0;
    padding: .05em 2em .05em 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  time {
    font-size: 80%;
    color: #c0c0c0;
    display: block;
    text-align: right;
  }

  .channel {
    border: thin solid #ccc;
    border-radius: 50px;
    height: 32px;
    width: 32px;
    position: absolute;
    right: -16px;
    top: 25%;
    background: #fff;
    text-align: center;

    & + p + p {
      color: #a1a1a1;
    }

    i {
      position: relative;
      margin-top: 50%;
      width: 16px;
      height: 16px;
      top: -8px;
    }
  }
}

.live-old-history {
  border: solid #f7d85c;
  border-width: thin 0;
  position: sticky;
  top: 0;
  z-index: 100;
  background: #ffefad;

  .items {
    height: 60px;
  }

  .item-left {
    position: relative;

    & > i {
      font-size: 18pt;
    }
  }

  .item-right {
    border-bottom: none;
  }

  * {
    color: #bb9600;
  }
}

.live-history-item {
  &.active {
    border-color: #f7d85c;
    background: #ffefad;

    &:hover {
      background: #fde996;
    }

    * {
      color: #bb9600;
    }

    .channel {
      border-color: #bb9600;
    }
  }
}

#departments-live-modal {
  &::v-deep {
    .card-header {
      display: block;

      & > button {
        text-align: left;
      }
    }
    .card-body, .card-header {
      padding: 0 !important;
      height: auto !important;
      min-height: 0 !important;
    }
  }
}

.live-transfer-list {
  overflow: auto;
  overflow-x: hidden;
  max-height: 80vh;
}

.live-system::v-deep .qt-loader {
  text-align: center;
  padding: 1em;
  z-index: 99;

  .spinner-grow {
    margin: 0 .08em;
  }
}

.live-light{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 2000;
  background: #fffffffa;
}
.live-light-close {
  background: #0000094a;
  width: 50px;
  height: 50px;
  color: #000;
  display: flex;
  border-radius: 50%;
  box-shadow: 1px 1px 2px 0.2px #aeaeae;
  align-items: center;
  align-content: center;
  text-align: center;
}
.live-light-close:hover {
  background: #ccc;
}
.live-light-close i {
  color: #fff;
  display: block;
  margin: 0 auto;
}
.live-light-header {
  background: #fff;
}
.live-light-header-close {
  padding: 10px;
  float: right;
}
.live-light-header-info{
  float: left;
  padding: 10px;
}
.live-light-header-info .l-image{
  background: #ccc;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align:center;
  float: left;
  vertical-align: middle;
  padding-top: 10px;
}
.live-light-header-info .l-image i{
  font-size: 25px;
  align-self: center;
  vertical-align: middle;
}
.live-light-header-info .l-image img{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.live-light-header-info .user-info-header{
  float: left;
  margin-left: 10px;
}
.live-light-header-info .user-info-header strong{
  display: block;
}
.live-light-header-info .user-info-header span{
  font-size: 13px;
  margin-top: -5px;
  display: block;
}
.live-light-body {
  text-align: center;
  padding-top: 10px;
  overflow-y: auto;

}
.live-light-body img {
}
.list-gallery {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: left;
  overflow-y: hidden;
  height: 80px;
  padding-bottom: 10px !important;
}
.list-gallery .list-gallery-item{
  position: relative;
  width: 29%;
  height: 100%;
  overflow: hidden;
  margin: 5px;
  border-radius: 10px;
}

.list-gallery .list-gallery-item .list-gallery-image{
  cursor: pointer;
  background-position: 50%;
  background-size: cover;
  transform: none;
  position: absolute;
  top: 0;
  z-index: 1;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  transition: transform .08s cubic-bezier(.09,.87,.72,1);
}

.list-gallery .list-gallery-item .list-gallery-image-sub{
  width: 100%;
  height: 100%;
  position: relative;
}
.list-gallery .list-gallery-item .list-gallery-image-sub-item{
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.list-gallery .list-gallery-item .list-gallery-image-sub-imagem{
  cursor: pointer;
  background-position: 50%;
  background-size: cover;
  transform: none;
  position: absolute;
  top: 0;
  z-index: 1;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  transition: transform .08s cubic-bezier(.09,.87,.72,1);
}
.live-light-left{
  position: absolute;
  left: 60px;
  top:40%;
}
.live-light-right{
  position: absolute;
  right: 60px;
  top:40%;
}
.btn-send-audio{
  background: #4db6ac;
  width: 50px;
  border: none;
  height: 50px;
  border-radius: 50%;
  float: left;
  margin-left: 15px;
  margin-top: 4px;
  cursor: pointer;
}
.btn-send-audio:hover{
  background: #46a79d;
}
.live-enter::before {
  margin: 0 auto;
  display: block;
  content: "";
  height: 50%;
  width: 50%;
  background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 441.30001" version="1.0">%3Cpath fill="%23fff" d="M 39.500001,0.69252634 C 17.300001,5.1925263 1.4042055e-6,26.692526 1.4042055e-6,49.692526 c 0,3.2 5.3999999957945,26.200002 14.5999995957945,62.700004 8.1,31.7 16.7,65.7 19.2,75.4 l 4.4,17.8 H 129.9 c 85.2,0 91.8,0.1 95.1,1.8 10.7,5.3 10.7,21.1 0,26.4 -3.3,1.7 -9.9,1.8 -95.1,1.8 H 38.200001 l -4.4,17.7 c -2.5,9.8 -11.1,43.8 -19.2,75.5 -9.1999996,36.5 -14.5999995957945,59.5 -14.5999995957945,62.7 0,23.8 17.5999995957945,44.9 40.9999995957945,49.1 7.7,1.4 16.7,0.7 24.1,-1.9 12.6,-4.4 418.899999,-173.5 423.799999,-176.4 13.6,-8.1 23.1,-25.3 23.1,-41.7 0,-16.4 -9.5,-33.6 -23.1,-41.7 C 484,175.99253 77.900001,6.9925263 65.000001,2.3925263 c -6.6,-2.29999996 -18.6,-3.09999996 -25.5,-1.69999996 z"/>%3C/svg>') center no-repeat;
  background-size: contain;
}
</style>
