<template>
  <div class="live-conversation" @click="markAsRead" v-if="$parent.actualRoom">
    <header class="live-inner-header">
      <!--
        {{readOnly ? 'leitura' : 'atendimento' }}:
        ({{$parent.oldRoom ? $parent.oldRoom.roomId : '-'}})
      -->
      <button class="live-back" @click="exitRoom()">
        <i class="fas fa-arrow-left"></i>
      </button>
      <div class="live-photo">
        <div class="live-channel">
          <i class="fas fa-globe" v-if="$parent.actualRoom.channel === 'widget'"></i>
          <i class="fas fa-globe" v-else-if="$parent.actualRoom.channel === 'widget-java'"></i>
          <i class="fab fa-facebook-messenger" v-else-if="$parent.actualRoom.channel === 'messenger'"></i>
          <i class="fab fa-whatsapp" v-else-if="$parent.actualRoom.channel === 'whatsapp'"></i>
          <i class="fab fa-whatsapp" v-else-if="$parent.actualRoom.channel === 'whatsapp-java'"></i>
          <i class="fab fa-google" v-else-if="$parent.actualRoom.channel === 'rcs'"></i>
          <i class="fab fa-google" v-else-if="$parent.actualRoom.channel === 'rcs-google'"></i>
          <i class="fab fa-telegram" v-else-if="$parent.actualRoom.channel === 'telegram'"></i>
          <i class="fab fa-instagram" v-else-if="$parent.actualRoom.channel === 'instagram'"></i>
          <i class="fas fa-envelope" v-else-if="$parent.actualRoom.channel === 'email'"></i>
          <i class="fab fa-google" v-else-if="$parent.actualRoom.channel === 'google-business'"></i>
          <i class="fas fa-question" v-else></i>
        </div>
        <button>
          <img :src="$live.profilePics[$parent.actualRoom.roomId]" v-if="$live.profilePics[$parent.actualRoom.roomId]">
          <i class="fas fa-user" v-else></i>
        </button>
      </div>
      <div class="live-header-name">
        <span class="sub">
          {{$parent.currentName}}
        </span>
        <span class="live-status badge badge-success" v-if="$parent.actualRoom.status === 'OPEN'">
          Ativo
        </span>
        <!-- <span class="live-status global" v-else-if="$parent.currentRoom.parentRoomId.indexOf('global') !== -1">Finalizado</span> -->
        <span class="live-status badge badge-danger" v-else-if="$parent.actualRoom.localStatus === 'queue'">Fila</span>
        <span class="live-status badge badge-danger" v-else-if="$parent.actualRoom.localStatus === 'transfer'">Transferido</span>
        <span class="live-status badge badge-danger" v-else-if="$parent.actualRoom.status === 'CLOSED'">Finalizado</span>
        <span class="live-status badge badge-info" v-else-if="$parent.actualRoom && $parent.actualRoom.parentRoomId.indexOf('offline') !== -1">
          Pendente
        </span>
      </div>
      <!-- <pre style="position: fixed; background: #fff;z-index: 999;">
      {{JSON.stringify($parent.currentRoom, null, 4)}}
      </pre> -->

      <div class="live-header-tools">
        <b-button id="tip-print" @click="$parent.print()">
          <i class="fas fa-print"></i>
        </b-button>

        <!-- <template v-if="!readOnly && !$parent.isManager"> -->
        <template v-if="!readOnly">
          <b-button id="tip-signout" @click="$parent.finish()">
            <i class="fas fa-sign-out-alt"></i>
          </b-button>
          <div class="menu-item">
            <b-dropdown right>
              <template #button-content>
                <i class="fas fa-reply-all"></i>
              </template>
              <b-dropdown-item @click="$parent.showAgents">Transferir para outro agente</b-dropdown-item>
              <b-dropdown-item @click="$parent.showDepartments">Transferir para departamento</b-dropdown-item>
              <b-dropdown-item @click="$parent.transferToQueue">Devolver para fila</b-dropdown-item>
              <!-- <b-dropdown-item>Devolver para a fila</b-dropdown-item> -->
            </b-dropdown>
          </div>
          <!-- <b-button id="tip-reply" @click="$parent.showAgents()">
            <i class="fas fa-reply-all"></i>
          </b-button> -->
          <!-- <b-button id="tip-user">
            <i class="fas fa-user"></i>
          </b-button> -->
        </template>

        <b-button id="tip-meta" class="toggle-meta" @click="$parent.toggleMeta()">
          <i class="fas fa-columns"></i>
        </b-button>

        <!-- tooltips -->
        <b-tooltip target="tip-print" placement="bottom">
          {{$t('generic-str.printout')}}
        </b-tooltip>

        <template v-if="!readOnly">
          <b-tooltip target="tip-signout" placement="bottom">
            {{$t('live.end-att')}}
          </b-tooltip>
          <!-- <b-tooltip target="tip-reply" placement="bottom">
            Transferir atendimento
          </b-tooltip> -->
          <!-- <b-tooltip target="tip-user" placement="bottom">
            Foo bar
          </b-tooltip> -->
        </template>

        <b-tooltip target="tip-meta" placement="bottom">
          {{$t('live.show-info')}}
        </b-tooltip>
      </div>

      <b-dropdown right class="live-header-tools-m">
        <template #button-content>
          <i class="fas fa-ellipsis-v"></i>
        </template>
        <b-dropdown-item-button @click="$parent.print()">
          <i class="fas fa-print"></i>
          {{$t('generic-str.printout')}}
        </b-dropdown-item-button>
        <!-- <b-dropdown-item-button @click="$parent.showAgents" v-if="!readOnly && !$parent.isManager"> -->
        <b-dropdown-item-button @click="$parent.showAgents" v-if="!readOnly">
          <i class="fas fa-reply-all"></i>
          {{$t('live.transfer-agnt')}}
        </b-dropdown-item-button>
        <!-- <b-dropdown-item-button @click="$parent.showDepartments" v-if="!readOnly && !$parent.isManager"> -->
        <b-dropdown-item-button @click="$parent.showDepartments" v-if="!readOnly">
          <i class="fas fa-reply-all"></i>
          {{$t('live.transfer-dep2')}}
        </b-dropdown-item-button>
        <!-- <b-dropdown-item-button @click="$parent.transferToQueue" v-if="!readOnly && !$parent.isManager"> -->
        <b-dropdown-item-button @click="$parent.transferToQueue" v-if="!readOnly">
          <i class="fas fa-reply-all"></i>
          Devolver para fila
        </b-dropdown-item-button>
        <b-dropdown-item-button @click="$parent.toggleMeta()">
          <i class="fas fa-columns"></i>
          {{$t('live.show-info')}}
        </b-dropdown-item-button>

        <b-dropdown-divider v-if="!readOnly"></b-dropdown-divider>

        <b-dropdown-item-button @click="$parent.finish()" v-if="!readOnly">
          <i class="fas fa-sign-out-alt"></i>
          {{$t('live.end-att')}}
        </b-dropdown-item-button>
      </b-dropdown>
    </header>
    <aside class="live-history-header" v-if="$parent.oldRoom !== null">
      {{$t('live.protocol-history')}}
      <strong>{{$parent.oldRoom.protocol}}</strong>
      <button @click="$parent.closeHistory()">voltar</button>
    </aside>
    <!-- roomId >> {{$parent.oldRoom.roomId}} {{$live.associativeTranscriptsMessages}} -->
    <template v-if="$live.loadingMessages">
      <div class="qt-loader qt-loader-mini text-center">
        <b-spinner variant="primary" type="grow" small></b-spinner>
        <b-spinner variant="primary" type="grow" small></b-spinner>
        <b-spinner variant="primary" type="grow" small></b-spinner>
      </div>
    </template>
    <template v-else>
      <div
        ref="transcript"
        class="live-transcript"
        :class="{ 'live-transcript-old': $parent.oldRoom, readonly: readOnly && !$parent.oldRoom }"
      >
        <template v-if="$parent.oldRoom">
          <!--
          <div class="live-old-load" v-if="$parent.oldLoading">
            <b-spinner variant="primary" type="grow" small></b-spinner>
            <b-spinner variant="primary" type="grow" small></b-spinner>
            <b-spinner variant="primary" type="grow" small></b-spinner>
          </div>
          -->
          <template v-for="message in $live.associativeTranscriptsMessages">
            <div
              :key="`message_${message.id}`"
              class="live-message"
              :class="{
                'another-agent': message.typeUser === 'AGENT' && !message.me,
                'place-offline': message.offline,
                me: message.me,
              }"
              v-if="message.roomId === $parent.oldRoom.roomId"
            >
              <!-- <div>{{message.id}}</div> -->
              <!-- <div class="live-message-group-date" v-if="index % 10 === 1" :title="message.sentAt">
                <h5>{{ message.sentAt | relativeDate }}</h5>
              </div> -->
              <div class="live-message-box">
                <div class="live-message-inner">
                  <picture v-if="message.typeUser !== 'AGENT' && !message.me && message.picture && $live.profilePics[$parent.oldRoom.roomId]">
                    <img :src="message.picture || $live.profilePics[$parent.oldRoom.roomId]">
                  </picture>
                  <span
                    class="badge bg-primary live-agent"
                    v-if="message.typeUser === 'AGENT' && !message.me"
                  >Agente</span>
                  <span
                    class="badge badge-secondary live-agent place-offline"
                    v-else-if="message.offline">Pendente</span>
                  <strong v-if="!message.me">
                    <template v-if="message.automatic || message.typeUser === 'AGENT'">
                      {{message.username}}
                    </template>
                    <template v-else>
                      {{$parent.currentName}}
                    </template>
                  </strong>
                  <div class="live-message-content">
                    <div class="live-message-attachments" v-if="message.attachments && message.attachments.length > 0">
                      <template v-for="file in message.attachments">
                        <button
                          class="live-media-preview"
                          :key="file.id"
                          v-if="file.type === 'video'"
                          @click="$parent.showFile(file)"
                        >
                          <video :src="file.uri" :type="file.media_type"></video>
                        </button>
                        <button
                          class="live-media-preview"
                          :key="file.id"
                          v-else-if="file.type === 'audio'"
                          @click="$parent.showFile(file)"
                        >
                          <audio :src="file.uri" :type="file.media_type" controls preload="metadata"></audio>
                        </button>
                        <button
                          class="live-media-preview"
                          :key="file.id"
                          v-else-if="file.type === 'image'"
                          @click="$parent.showFile(file)"
                        >
                          <img :src="file.uri">
                        </button>
                        <template v-else>
                          <a :href="file.uri" :key="file.id" target="_blank" class="live-external">
                            <i class="fas fa-file"></i>
                            <span class="live-attach-name">{{file.name}}</span>
                            <!-- <span class="live-attach-name">{{file.uri | basename}}</span> -->
                            <div class="live-attach-type">{{file.media_type || file.type}}</div>
                          </a>
                        </template>
                      </template>
                    </div>
                    <div
                      class="live-message-text"
                      v-html="interactive($live.markdown(message.message, true))"
                      @click="handleInteractive"></div>
                  </div>
                  <div class="live-message-details">
                    <time class="live-message-time" :title="message.sentAt">{{ message.sentAt | relativeDate }}</time>
                    <span v-if="message.status === 'DELIVERED'" class="live-msg-status">🗸</span>
                    <span v-else-if="message.status === 'READ'" class="live-msg-status">🗸🗸</span>
                  </div>
                </div>
                <div class="live-message-source">
                  <template v-if="$parent.oldRoom.channel">
                    <i class="fas fa-globe" v-if="$parent.oldRoom.channel === 'widget'"></i>
                    <i class="fas fa-globe" v-else-if="$parent.oldRoom.channel === 'widget-java'"></i>
                    <i class="fab fa-facebook-messenger" v-else-if="$parent.oldRoom.channel === 'messenger'"></i>
                    <i class="fab fa-whatsapp" v-else-if="$parent.oldRoom.channel === 'whatsapp'"></i>
                    <i class="fab fa-whatsapp" v-else-if="$parent.oldRoom.channel === 'whatsapp-java'"></i>
                    <i class="fab fa-google" v-else-if="$parent.oldRoom.channel === 'rcs'"></i>
                    <i class="fab fa-google" v-else-if="$parent.oldRoom.channel === 'rcs-google'"></i>
                    <i class="fab fa-telegram" v-else-if="$parent.oldRoom.channel === 'telegram'"></i>
                    <i class="fab fa-instagram" v-else-if="$parent.oldRoom.channel === 'instagram'"></i>
                    <i class="fas fa-envelope" v-else-if="$parent.oldRoom.channel === 'email'"></i>
                    <i class="fab fa-google" v-else-if="$parent.oldRoom.channel === 'google-business'"></i>
                    <template v-if="$parent.oldRoom.channel === 'widget' || $parent.oldRoom.channel === 'widget-java'">
                      webchat
                    </template>
                    <template v-else-if="$parent.oldRoom.channel === 'whatsapp-java'">whatsapp</template>
                    <template v-else>$parent.oldRoom.channel</template>
                  </template>
                  <template v-else>
                    <i class="fas fa-comment-dots"></i> webchat
                  </template>
                </div>
              </div>
              <!-- -->
            </div>
          </template>

          <!-- fechamento -->
          <!-- <div class="live-message" v-if="$parent.currentRoom.parentRoomId && $parent.currentRoom.parentRoomId.indexOf('offline') !== -1"> -->
          <div class="live-message" v-if="$parent.currentRoom.status !== 'OPEN' && $parent.currentRoom.closedAt">
            <div class="live-message-box">
              <div class="live-message-inner">
                <span class="badge bg-danger live-agent">Fechamento</span>
                <div class="live-message-content">
                  <span>
                    Atendimento encerrado em <time :title="$parent.oldRoom.closedAt">{{$parent.oldRoom.closedAt | formatDate24}}</time>
                  </span>
                </div>
                <div class="live-message-details">
                  <time class="live-message-time" :title="$parent.oldRoom.closedAt">{{$parent.oldRoom.closedAt | relativeDate}}</time>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <!-- <pre>{{$parent.fromTranscript}}:{{($parent.fromTranscript ? $live.associativeTranscriptsMessages : $live.messages)}}</pre> -->
          <template v-for="message in currentMessages">
            <div
              class="live-message"
              :key="`message_${message.id}`"
              :class="{
                me: message.me,
                automatic: message.automatic,
                deleted: message.status === 'DELETED',
                fail: message.status === 'FAIL',
                'another-agent': message.typeUser === 'AGENT' && !message.me,
                'place-offline': message.offline,
              }"
              v-if="message.roomId === $parent.currentRoom.roomId"
            >
              <!-- <div>{{message.id}}</div> -->
              <!-- <div class="live-message-group-date" v-if="index % 10 === 1" :title="message.sentAt">
                <h5>{{ message.sentAt | relativeDate }}</h5>
              </div> -->

              <div class="live-message-box">
                <div class="live-message-inner">
                  <picture v-if="!message.automatic && message.typeUser !== 'AGENT' && !message.me && message.picture && $live.profilePics[$parent.currentRoom.roomId]">
                    <img :src="message.picture || $live.profilePics[$parent.currentRoom.roomId]">
                  </picture>
                  <span
                    class="badge bg-warning live-agent"
                    v-if="message.automatic"
                  >{{message.typeUser === 'BOT' ? 'BOT' : 'Automática'}}</span>
                  <span
                    class="badge bg-primary live-agent"
                    v-else-if="message.typeUser === 'AGENT' && !message.me"
                  >Agente</span>
                  <span
                    class="badge badge-secondary live-agent place-offline"
                    v-else-if="message.offline">Pendente</span>
                  <strong v-if="!message.automatic && !message.me">
                    <template v-if="message.automatic || message.typeUser === 'AGENT'">
                      {{message.username}}
                    </template>
                    <template v-else>
                      {{$parent.currentName}}
                    </template>
                  </strong>
                  <!-- <div class="live-message-opts" v-if="!$parent.fromTranscript && !message.automatic">
                    <b-dropdown :right="message.me" variant="outline-secondary">
                      <b-dropdown-item>Responder</b-dropdown-item>
                      <b-dropdown-item @click="$live.deleteMessage(message)" v-if="message.me">Deletar</b-dropdown-item>
                    </b-dropdown>
                  </div> -->
                  <div class="live-message-content">
                    <template v-if="message.status === 'DELETED'">
                      <span>
                        <i class="fas fa-ban"></i> Mensagem apagada
                      </span>
                    </template>
                    <template v-else>
                      <div class="live-message-attachments" v-if="message.attachments && message.attachments.length > 0">
                        <template v-for="file in message.attachments">
                          <button
                            class="live-media-preview"
                            :key="file.id"
                            v-if="file.type === 'video'"
                            @click="$parent.showFile(file)"
                          >
                            <video :src="file.uri" :type="file.media_type"></video>
                          </button>
                          <button
                            class="live-media-preview"
                            :key="file.id"
                            v-else-if="file.type === 'audio'"
                            @click="$parent.showFile(file)"
                          >
                            <audio :src="file.uri" :type="file.media_type" controls preload="metadata"></audio>
                          </button>
                          <button
                            class="live-media-preview"
                            :key="file.id"
                            v-else-if="file.type === 'image'"
                            @click="$parent.showFile(file)"
                          >
                            <img :src="file.uri">
                          </button>
                          <template v-else>
                            <a :href="file.uri" :key="file.id" target="_blank" class="live-external">
                              <i class="fas fa-file"></i>
                              <span class="live-attach-name">{{file.name}}</span>
                              <!-- <span class="live-attach-name">{{file.uri | basename}}</span> -->
                              <div class="live-attach-type">{{file.media_type || file.type}}</div>
                            </a>
                          </template>
                        </template>
                      </div>
                      <div
                        class="live-message-text"
                        v-html="interactive($live.markdown(message.message, true))"
                        @click="handleInteractive"></div>
                    </template>
                  </div>
                  <div class="live-message-details">
                    <time class="live-message-time" :title="message.sentAt">
                      {{ message.sentAt | relativeDate }}
                      <template v-if="message.status === 'FAIL'">
                      (não entregue) <i class="fas fa-exclamation-triangle icon-fail"></i>
                      </template>
                    </time>
                    <span v-if="message.status === 'DELIVERED'" class="live-msg-status">🗸</span>
                    <span v-else-if="message.status === 'READ'" class="live-msg-status">🗸🗸</span>
                  </div>
                </div>
                <div class="live-message-source">
                  <template v-if="$parent.actualRoom.channel">
                    <i class="fas fa-globe" v-if="$parent.actualRoom.channel === 'widget'"></i>
                    <i class="fas fa-globe" v-if="$parent.actualRoom.channel === 'widget-java'"></i>
                    <i class="fab fa-facebook-messenger" v-else-if="$parent.actualRoom.channel === 'messenger'"></i>
                    <i class="fab fa-whatsapp" v-else-if="$parent.actualRoom.channel === 'whatsapp'"></i>
                    <i class="fab fa-whatsapp" v-else-if="$parent.actualRoom.channel === 'whatsapp-java'"></i>
                    <i class="fab fa-google" v-else-if="$parent.actualRoom.channel === 'rcs'"></i>
                    <i class="fab fa-google" v-else-if="$parent.actualRoom.channel === 'rcs-google'"></i>
                    <i class="fab fa-telegram" v-else-if="$parent.actualRoom.channel === 'telegram'"></i>
                    <i class="fab fa-instagram" v-else-if="$parent.actualRoom.channel === 'instagram'"></i>
                    <i class="fas fa-envelope" v-else-if="$parent.actualRoom.channel === 'email'"></i>
                    <i class="fab fa-google" v-else-if="$parent.actualRoom.channel === 'google-business'"></i>
                    <template v-if="$parent.actualRoom.channel === 'widget' || $parent.actualRoom.channel === 'widget-java'">
                      webchat
                    </template>
                    <template v-else-if="$parent.actualRoom.channel === 'whatsapp-java'">whatsapp</template>
                    <template v-else>{{$parent.actualRoom.channel}}</template>
                  </template>
                  <template v-else>
                    <i class="fas fa-comment-dots"></i> webchat
                  </template>
                </div>
              </div>
              <!-- -->
            </div>
          </template>
          <div class="live-message upload me" v-if="uploading">
            <div class="live-message-box">
              <div class="live-message-inner">
                <b-spinner type="grow" small></b-spinner>
                <b-spinner type="grow" small></b-spinner>
                <b-spinner type="grow" small></b-spinner>
              </div>
            </div>
          </div>
          <!-- fechamento -->
          <div class="live-message" v-if="$parent.fromTranscript && !$parent.currentRoom.fromIdle && !$parent.currentRoom.fromOffline">
            <div class="live-message-box">
              <div class="live-message-inner">
                <span class="badge bg-danger live-agent">{{$tc('generic-str.status.lbl-finished', 2)}}</span>
                <div class="live-message-content">
                  <span>
                    {{$t('live.closed-at')}} <time :title="$parent.currentRoom.closedAt">{{$parent.currentRoom.closedAt | formatDate24}}</time>
                  </span>
                </div>
                <div class="live-message-details">
                  <time class="live-message-time" :title="$parent.currentRoom.closedAt">{{$parent.currentRoom.closedAt | relativeDate}}</time>
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- resume -->
        <div class="live-manager-actions live-resume-actions" v-if="resumeOpeningOpts">
          <button class="live-manager-enter" @click="reqResumeOpen()">
            <i class="fas fa-undo"></i>
            <template v-if="$parent.actualRoom.session === 'closed'">
              Iniciar conversa
            </template>
            <template v-else>
              Retomar conversa
            </template>
          </button>
        </div>
        <template v-else>
          <div class="live-resume-contact" v-if="resume.sending">
            <b-card no-body :class="{ 'd-none': resume.sending !== 'none' }">
              <b-tabs v-model="tabResumeIndex" card>
                <b-tab title="SMS" active>
                  <sms-input
                    v-model="resume.sms.form.body"
                    :normalize="resume.sms.form.normalize"
                    :variables="[]"
                  />
                  <div class="py-2" v-if="$parent.currentRoom.fromOffline">
                    <input
                      class="tgl tgl-light tgl-primary markassolved-modal-change"
                      id="markassolved-modal-active"
                      type="checkbox"
                      v-model="markAsSolvedAfter"
                    />
                    <label class="tgl-btn" for="markassolved-modal-active"></label> Marcar como resolvido
                  </div>
                  <div class="py-2">
                    <button
                      class="btn btn-primary btn-icon btn-outline-primary"
                      type="button"
                      :disabled="!smsFields"
                      @click="sendResumeSms()"
                    >
                      Enviar SMS
                    </button>
                  </div>
                </b-tab>
                <b-tab title="Whatsapp" @click="bootWhatsAppTemplates()" v-if="oldWhatsApp">
                  <!-- <pre>{{whatsAppTemplates}}</pre> -->
                  <!-- <pre>{{resume.whatsapp.form}}</pre> -->
                  <div>
                    <template v-if="!whatsAppActive">
                      <div class="py-4 text-center">WhatsApp intativo</div>
                    </template>
                    <div class="text-left pb-4" v-else>
                      <select-input
                        class="yu-whatsapp"
                        label="name"
                        :reduce="(template) => template"
                        :options="whatsAppTemplates"
                        :clearable="false"
                        v-model="whatsAppTemplate"
                        v-if="whatsAppTemplates.length" />
                      <template v-else>
                        <b-spinner type="grow" small></b-spinner>
                        <b-spinner type="grow" small></b-spinner>
                        <b-spinner type="grow" small></b-spinner>
                      </template>
                      <div class="whatsapp-bg" v-if="resume.whatsapp.form.body">
                        <div class="whatsapp-message">
                          <div class="whatsapp-preview p-4 card">
                            <header v-if="resume.whatsapp.form.header && resume.whatsapp.form.header.format === 'TEXT'">
                              {{resume.whatsapp.form.header.text}}
                            </header>
                            <p v-html="whatsAppBody"></p>
                            <footer v-if="resume.whatsapp.form.footer">
                              {{resume.whatsapp.form.footer.text}}
                            </footer>
                          </div>
                          <template v-if="resume.whatsapp.form.template.buttons">
                            <div v-for="(btn, i) in resume.whatsapp.form.template.buttons" :key="i">
                              <button
                                type="button"
                                class="btn-link-preview"
                                v-if="btn.type == 'PHONE_NUMBER'"
                                disabled
                              >
                                <i class="fas fa-phone"></i> {{btn.text}}
                              </button>
                              <button
                                type="button"
                                class="btn-link-preview"
                                v-if="btn.type === 'URL'"
                                disabled
                              >
                                <i class="fas fa-link"></i> {{btn.text}}
                              </button>
                              <button
                                type="button"
                                class="btn-link-preview"
                                v-if="btn.type === 'QUICK_REPLY'"
                                disabled
                              >
                                {{btn.text}}
                              </button>
                            </div>
                          </template>
                        </div>
                      </div>
                      <div class="row" v-if="resume.whatsapp.form.params">
                        <div
                          class="col-lg-6"
                          v-for="param in resume.whatsapp.form.params"
                          :key="param.index"
                        >
                          <label>Parâmetro {{param.index}}</label>
                          <input class="form-control" type="text" v-model="param.text"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="py-2" v-if="$parent.currentRoom.fromOffline">
                    <input
                      class="tgl tgl-light tgl-primary markassolved-modal-change"
                      id="markassolved-modal-active"
                      type="checkbox"
                      v-model="markAsSolvedAfter"
                    />
                    <label class="tgl-btn" for="markassolved-modal-active"></label> Marcar como resolvido
                  </div>
                  <div class="py-2">
                    <button
                      class="btn btn-primary btn-icon btn-outline-primary"
                      type="button"
                      :disabled="!whatsAppFields"
                      @click="sendResumeWhatsApp(false)"
                      v-if="whatsAppActive"
                    >
                      Enviar WhatsApp
                    </button>
                  </div>
                </b-tab>
                <b-tab v-if="newWhatsApp">
                  <template #title>
                    WhatsApp <span class="badge badge-warning">Beta</span>
                  </template>
                  <!-- <pre>{{whatsAppTemplates}}</pre> -->
                  <!-- <pre>{{resume.whatsapp.form}}</pre> -->
                  <!-- <div>
                    <template v-if="newWhatsApp">
                      <div class="my-4">
                        <select
                          :disabled="!newWhatsAppNumbersFetched"
                          class="form-control"
                          @change="fetchWhatsAppTemplatesNew(1)">
                          <option
                            v-for="number in newWhatsAppNumbers"
                            :key="number.id"
                            :value="number.whatsapp_identification"
                          >{{ number.phone_number | phone }} ({{number.name}})</option>
                        </select>
                      </div>

                      <div class="my-4">
                        <select
                          class="form-control"
                          :disabled="!newWhatsAppTemplatesFetched"
                          v-model="newWhatsAppTemplateIdSelected"
                          @change="setWhatsAppTemplate()">
                          <option
                            :key="index"
                            :value="template.id"
                            v-for="(template, index) in newWhatsAppTemplates">
                              {{ template.name }} ({{ template.language }})
                            </option>
                        </select>
                      </div>

                      <div class="my-4" v-if="newWhatsAppTemplateSelected">
                        <textarea class="form-control" disabled v-model="newWhatsAppTemplateSelected.body.text"></textarea>
                      </div>
                    </template>
                    <template v-else-if="!whatsAppActive">
                      <div class="py-4 text-center">WhatsApp intativo</div>
                    </template>
                    <div class="text-left pb-4" v-else>
                      <select-input
                        class="yu-whatsapp"
                        label="name"
                        :reduce="(template) => template"
                        :options="whatsAppTemplates"
                        :clearable="false"
                        v-model="whatsAppTemplate"
                        v-if="whatsAppTemplates.length" />
                      <template v-else>
                        <b-spinner type="grow" small></b-spinner>
                        <b-spinner type="grow" small></b-spinner>
                        <b-spinner type="grow" small></b-spinner>
                      </template>
                      <div class="whatsapp-bg" v-if="resume.whatsapp.form.body">
                        <div class="whatsapp-message">
                          <div class="whatsapp-preview p-4 card">
                            <header v-if="resume.whatsapp.form.header && resume.whatsapp.form.header.format === 'TEXT'">
                              {{resume.whatsapp.form.header.text}}
                            </header>
                            <p v-html="whatsAppBody"></p>
                            <footer v-if="resume.whatsapp.form.footer">
                              {{resume.whatsapp.form.footer.text}}
                            </footer>
                          </div>
                          <template v-if="resume.whatsapp.form.template.buttons">
                            <div v-for="(btn, i) in resume.whatsapp.form.template.buttons" :key="i">
                              <button
                                type="button"
                                class="btn-link-preview"
                                v-if="btn.type == 'PHONE_NUMBER'"
                                disabled
                              >
                                <i class="fas fa-phone"></i> {{btn.text}}
                              </button>
                              <button
                                type="button"
                                class="btn-link-preview"
                                v-if="btn.type === 'URL'"
                                disabled
                              >
                                <i class="fas fa-link"></i> {{btn.text}}
                              </button>
                              <button
                                type="button"
                                class="btn-link-preview"
                                v-if="btn.type === 'QUICK_REPLY'"
                                disabled
                              >
                                {{btn.text}}
                              </button>
                            </div>
                          </template>
                        </div>
                      </div>
                      <div class="row" v-if="resume.whatsapp.form.params">
                        <div
                          class="col-lg-6"
                          v-for="param in resume.whatsapp.form.params"
                          :key="param.index"
                        >
                          <label>Parâmetro {{param.index}}</label>
                          <input class="form-control" type="text" v-model="param.text"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="py-2" v-if="$parent.currentRoom.fromOffline">
                    <input
                      class="tgl tgl-light tgl-primary markassolved-modal-change"
                      id="markassolved-modal-active"
                      type="checkbox"
                      v-model="markAsSolvedAfter"
                    />
                    <label class="tgl-btn" for="markassolved-modal-active"></label> Marcar como resolvido
                  </div>
                  <div class="py-2">
                    <button
                      class="btn btn-primary btn-icon btn-outline-primary"
                      type="button"
                      :disabled="!whatsAppFields && !newWhatsAppTemplateSelected"
                      @click="sendResumeWhatsApp(true)"
                      v-if="newWhatsApp || whatsAppActive">
                      Enviar WhatsApp
                    </button>
                  </div> -->
                  <div class="sr-only">roomNumber: {{roomNumber}}</div>
                  <whatsapp-send-form
                    @success="resume.sending = 'done'"
                    :recipients="roomNumber"
                    :extras="false"
                    :clientRoomId="$parent.actualRoom.clientId" />
                </b-tab>
              </b-tabs>
            </b-card>
            <div class="p-4 text-center" v-if="resume.sending === 'sending'">
              <b-spinner type="grow" small></b-spinner>
              <b-spinner type="grow" small></b-spinner>
              <b-spinner type="grow" small></b-spinner>
            </div>
            <div v-else-if="resume.sending === 'done'">
              <div class="card">
                <div class="card-body p-4 text-center">
                  Solicitação de contato enviado com sucesso!
                </div>
              </div>
            </div>
          </div>
          <div class="live-manager-actions live-resume-actions" v-else>
            <!-- <button class="live-manager-enter bg-danger" @click="markAsSolved()" v-if="actualRoom.fromOffline">
              <i class="fas fa-check"></i> Marcar como resolvido
            </button> -->
            <button class="live-manager-enter" @click="resume.sending = 'none'; reqTriggerScroll();">
              <i class="fas fa-undo"></i> Iniciar conversa
            </button>
          </div>
        </template>
        <!-- resume -->
      </div>
    </template>
    <template v-if="$parent.oldRoom === null && !readOnly && !$live.loadingMessages && $live.profiles && $parent.currentRoom.session !== 'closed'">
      <div v-if="!managerEnter && $parent.isManager">
        <div class="live-manager-actions">
          <button class="live-manager-enter" @click="managerEnter = true;">
            <i class="fas fa-key"></i>
            Entrar na conversa
          </button>
        </div>
      </div>
      <div
        class="live-input"
        :data="`${92 + (message.split('\n').length - 1) * 16}px`"
        :style="{ height: `${92 + (message.split('\n').length - 1) * 16}px` }"
        v-else>
        <div class="live-file-preview card" v-if="beforeUpload !== null">
          <header class="card-header d-flex justify-content-between">
            <div class="py-2">
              <strong>{{beforeUpload.source.name}}</strong>
            </div>
            <div class="py-2">
              <button @click="resetPreviewFile()">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </header>
          <div class="card-body">
            <aside>
              <img :src="beforeUpload.url" v-if="beforeUpload.source.type.indexOf('image/') === 0" controls>
              <audio :src="beforeUpload.url" v-else-if="beforeUpload.source.type.indexOf('audio/') === 0" controls preload="metadata"></audio>
              <video :src="beforeUpload.url" v-else-if="beforeUpload.source.type.indexOf('video/') === 0" controls></video>
              <object :data="beforeUpload.url" type="application/pdf" v-else-if="beforeUpload.source.type.indexOf('pdf') !== -1">
                <p>Seu navegador não suporta PDF</p>
              </object>
              <h3 v-else>Pré-visualização indisponível</h3>
              <div class="d-flex justify-content-between live-file-preview-meta">
                <div>{{beforeUpload.source.size | fileSize}}</div>
                <div>{{beforeUpload.source.type}}</div>
              </div>
            </aside>
          </div>
        </div>
        <div class="live-file-preview live-record-audio card" v-if="recordResolving || recorder !== null || audioURL !== null">
          <header class="card-header d-flex justify-content-between">
            <div class="py-2">
              <strong>Gravando áudio</strong>
            </div>
            <div class="py-2">
              <button @click="resetRecordAudio()">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </header>
          <div class="card-body">
            <aside>
              <div class="recording-write" v-if="recordResolving">
                <p>Comprimindo a gravação</p>
                <b-spinner type="grow" small></b-spinner>
                <b-spinner type="grow" small></b-spinner>
                <b-spinner type="grow" small></b-spinner>
              </div>
              <div class="record" v-else-if="audioURL !== null">
                <audio :src="audioURL" ref="audioURLRef" controls preload></audio>
                <div class="row">
                  <div class="col-5">
                    <button class="btn bg-success audio-send" @click="sendRecordAudio()">Enviar</button>
                  </div>
                  <div class="col-2"></div>
                  <div class="col-5">
                    <button class="btn bg-danger audio-cancel" @click="resetRecordAudio()">Cancelar</button>
                  </div>
                </div>
              </div>
              <div class="record" v-else>
                <div class="timer">{{recordTimer | roundTime}}</div>
                <button class="audio-finish" @click="finishRecordAudio()">
                  <i class="fas fa-microphone"></i>
                </button>
                <footer>
                  <strong>Dica:</strong> clique no ícone para concluir
                </footer>
              </div>
            </aside>
          </div>
        </div>
        <div class="alert alert-warning black-color" v-if="isTemplate">
          Passou de 24h, você precisa enviar um template <button class="btn btn-primary btn-sm to-right" @click="sendTemplate">{{$t('generic-str.send')}}</button>
          <div style="clear:both;"></div>
        </div>
        <Emojis @select="addEmoji" icon="emoji_emotions" setClass="none" left="10px" bottom="52px" />
        <!-- <button>
          <i class="fas fa-paperclip icon-live c-pointer" style="margin-left: 10px"></i>
        </button> -->
        <!--
        <b-modal id="live-modal-templates" ref="ref-templates" title="Selecionar template">
          <template>
            <p>teste</p>
          </template>
          <template #modal-footer="{ ok, cancel }">
            <b-button size="md" variant="danger" @click="cancel()">
              Cancelar
            </b-button>
            <b-button size="md" variant="success" @click="ok()">
              Selecionar
            </b-button>
          </template>
        </b-modal>
        -->
        <b-dropdown id="live-attach" dropup v-if="beforeUpload === null">
          <template #button-content>
            <i class="fas fa-ellipsis-v"></i>
          </template>
          <!--
          <b-dropdown-item href="#" @click="showTemplates()">
            <i class="far fa-file-alt"></i> Selecionar um template
          </b-dropdown-item>
          -->
          <b-dropdown-item href="#" @click="requestFile">
            <i class="fas" :class="{
              'fa-spinner': uploading,
              'fa-paperclip': !uploading,
            }"></i>
            {{uploading ? 'Enviando...' : 'Enviar arquivo'}}
          </b-dropdown-item>
        </b-dropdown>
        <input
          type="file"
          id="live-attach-input"
          ref="attachInput"
          @change="previewFileFromInput()" />
        <div class="live-input-out" v-if="roomId">
          <div class="live-input-in">
            <aside class="window-listing-sample-msgs card" :class="{ show: autocompleteSampleVisible }">
              <div class="p-2" v-if="$live.sampleMessagesLoading">
                <div class="qt-loader qt-loader-mini text-center">
                  <b-spinner variant="primary" type="grow" small></b-spinner>
                  <b-spinner variant="primary" type="grow" small></b-spinner>
                  <b-spinner variant="primary" type="grow" small></b-spinner>
                </div>
              </div>
              <nav class="list-group" v-else-if="autocompleteSampleMsgs.length">
                <template v-for="(sample, i) in autocompleteSampleMsgs">
                  <button class="list-group-item list-group-item-action" :key="i" @click="chooseSampleMessage(sample)">
                    <div class="d-flex">
                      <strong class="flex-fill">{{sample.key}}</strong>
                      <div class="flex-fill">
                        <div class="sample-item">{{sample.message}}</div>
                      </div>
                    </div>
                  </button>
                </template>
              </nav>
              <div class="p-2 notfound" v-else>
                Não há mensagens prontas
              </div>
            </aside>
            <aside class="window-create-sample-msg card" v-if="createSampleMsg">
              <div class="relative">
                <button class="window-create-sample-close" @click="toggleCreateSampleMessage()">
                  <i class="fas fa-times"></i>
                </button>
                <div class="qt-block-ui" v-if="$live.departmentsLoading || $live.sampleMessagesLoading || createSampleMsgNew == 2"></div>
                <div class="row" v-if="createSampleMsgNew === 0">
                  <div class="col-6">
                    <h1>Mensagens Prontas</h1>
                    <p>Crie uma mensagem pronta</p>
                    <div>
                      <img src="@/assets/sample-messages.svg" alt="sample message" height="142" />
                    </div>
                    <button class="btn btn-primary" @click="createSampleMsgNew = 1">Nova mensagem</button>
                  </div>
                  <div class="col-6">
                    <div class="input-group filter-msgs mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="filter-sample-messages">
                          <i class="fas fa-search"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Filtrar"
                        aria-label="Filtrar mensagens"
                        aria-describedby="filter-sample-messages"
                        v-model="filterSamples">
                    </div>
                    <div class="sample-messages">
                      <div class="p-2" v-if="!filtredSamples.length">
                        Sem mensagens
                      </div>
                      <nav class="list-group" v-else>
                        <template v-for="(sample, i) in filtredSamples">
                          <button class="list-group-item list-group-item-action" :key="i" @click="chooseSampleMessage(sample)">
                            <strong>{{sample.key}}</strong>
                            <div class="sample-dones">{{sample.message}}</div>
                          </button>
                        </template>
                      </nav>
                    </div>
                  </div>
                </div>
                <template v-else-if="createSampleMsgNew !== 0">
                  <h1>Mensagens Prontas</h1>
                  <div class="row new-sample">
                    <div class="col-6">
                      <div class="form-group">
                        <label for="newSampleKey" class="form-label">Titulo</label>
                        <input
                          id="newSampleKey"
                          type="text"
                          class="form-control"
                          placeholder="Titulo..."
                          v-model="sampleForm.key">
                      </div>
                      <div class="form-group">
                        <label for="newSampleMessage" class="form-label">Conteudo</label>
                        <textarea
                          id="newSampleMessage"
                          class="form-control"
                          v-model="sampleForm.message"></textarea>
                      </div>
                      <div class="row form-footer">
                        <div class="col-6">
                          <button class="btn btn-block btn-primary" @click="createSampleAction">Salvar</button>
                        </div>
                        <div class="col-6">
                          <button class="btn btn-block btn-outline-primary" @click="createSampleMsgNew = 0">Cancelar</button>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <strong class="mb-2 d-block">Disponível para</strong>
                      <div class="form-check">
                        <input
                          id="all"
                          value="all"
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          v-model="sampleForm.availableTo">
                        <label class="form-check-label" for="all">Todos da equipe</label>
                      </div>
                      <div class="form-check">
                        <input
                          id="me"
                          value="me"
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          v-model="sampleForm.availableTo">
                        <label class="form-check-label" for="me">Apenas para mim</label>
                      </div>
                      <hr>
                      <div class="p-2" v-if="!$live.departments.length">
                        Não há departamentos cadastrados
                      </div>
                      <template v-for="(department, i) in $live.departments">
                        <div class="form-check" :key="i">
                          <input
                            :id="`department_${department.id}`"
                            :value="department.id"
                            class="form-check-input"
                            type="radio"
                            v-model="sampleForm.availableTo">
                          <label class="form-check-label" :for="`department_${department.id}`">
                            {{department.name}}
                          </label>
                        </div>
                      </template>
                    </div>
                  </div>
                </template>
                <!-- <template v-else-if="createSampleMsgNew === 2">
                  I am popover <b>component</b> content! 2
                </template> -->
              </div>
            </aside>
            <button class="btn-create-sample-msg" @click="toggleCreateSampleMessage()">
              <!-- <i class="fas fa-archive fa-fw"></i> -->
              <!-- <i class="fas fa-sparkles fa-fw"></i>
              <i class="fas fa-comments-alt fa-fw"></i>
              <i class="fas fa-comment-alt-check fa-fw"></i> -->
              <img src="@/assets/fast-messages.svg" alt="Mensagens prontas" />
            </button>
            <textarea
              ref="inputMessage"
              type="text"
              v-model="messages[$parent.currentRoom.roomId]"
              :style="{height:`${message.split('\n').length + 1}em`}"
              :readonly="createSampleMsg"
              class="live-input-textarea"
              @focus="beforeSentTyping(true)"
              @blur="beforeSentTyping(false)"
              @keydown.enter.prevent="sendMessage"
              @input="refreshMessage += 1"
              @paste="previewFileFromClipboard"></textarea>
            <div class="live-input-count" v-if="messageDetails.length > 0">
              <span :class="{
                exceed: messageDetails.length > messageDetails.limit
              }">{{messageDetails.length}}</span>/{{messageDetails.limit}}
            </div>
          </div>
        </div>
        <div>
          <button
            class="live-speak"
            v-if="beforeUpload === null && !message"
            @click="startRecordAudio()"
          >
            <i class="fas fa-microphone"></i>
            <span class="sr-only">Gravar áudio</span>
          </button>
          <button
            class="live-enter"
            @click="sendMessage()"
            v-else-if="!isTemplate"
            :disabled="beforeUpload === null && (!message || !messageDetails.length || messageDetails.length > messageDetails.limit)"
          >
            <span class="sr-only">Enviar mensagem</span>
          </button>
        </div>
        <!-- <i class="fas fa-paperclip icon-live c-pointer" style="margin-left: 10px" @click="sendFile"></i> -->
      </div>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import Emojis from '@/components/Emojis.vue';
import MicRecorder from 'mic-recorder-to-mp3';

import SmsInput from '@/components/form/SmsInput.vue';
import SelectInput from '@/components/SelectInput.vue';

import WhatsAppService from '@/services/whatsapp.service';
import axios from '@/plugins/axios';

import Swal from 'sweetalert2';

import WhatsappSendForm from '@/components/whatsappj/WhatsappSendForm.vue';

// @ is an alias to /src
// import InfiniteLoading from 'vue-infinite-loading';
// import LiveService from '@/services/live.service';

// console.log('teste', [
//   LiveService.messages,
//   LiveService.history,
//   LiveService.hasNotifications,
//   LiveService.online,
//   LiveService.launch(),
// ]);

const INTERACTIVE_CONTENT = `<div>
  <button data-interactive="copy">
    <i class="far fa-copy"></i>
  </button>
</div>`;

export default {
  name: 'LiveView',
  components: {
    // InfiniteLoading,
    Emojis,
    SmsInput,
    SelectInput,
    WhatsappSendForm,
  },
  props: ['roomId', 'readOnly'],
  data() {
    return {
      createSampleMsg: false,
      createSampleMsgNew: 0, // 0 intro, 1 new, 2 saving

      sampleForm: {
        key: '',
        message: '',
        availableTo: 'me',
      },

      filterSamples: '',
      // samples: [
      //   { key: 'aaa', message: 'gato gato gato gato gato gato gato gato gato gato gato gato gato gato gato gato' },
      //   { key: 'bbb', message: 'rato rato rato rato rato rato rato rato rato rato rato rato rato rato' },
      //   { key: 'ccc', message: 'foo bar baz foo bar baz foo bar baz foo bar baz foo bar baz' },
      // ],

      markAsSolvedAfter: false,
      audioURL: null,
      audioData: null,
      recorder: null,
      recordTimer: 0,
      recordMax: 120,
      recordInterval: 0,
      recordResolving: false,
      markdownDom: null,
      // message: '',
      refreshMessage: 0,
      messages: {},
      limits: {
        whatsapp: 4096,
        'whatsapp-java': 4096,

        widget: 4096,
        'widget-java': 4096,

        messenger: 4096,
        telegram: 4096,
        instagram: 2000,
        rcs: 255,
        'rcs-google': 255,
        smsGSM: 160,
        smsUnicode: 70,
        'google-business': 996,
      },
      isTemplate: false,
      currentChannel: null,
      templateName: null,
      timeoutTyping: 0,
      // messageDetails.limit: 0,
      // messageDetails.length: 0,
      uploading: false,
      beforeUpload: null,
      managerEnter: false,
      scrollTimeout: 0,

      whatsAppTemplates: [],
      whatsAppTemplate: null,
      whatsAppActive: false,

      // new whatsapp
      newWhatsAppId: null,

      newWhatsAppNumbersFetched: false,
      newWhatsAppNumbers: [],

      newWhatsAppTemplatesFetched: false,
      newWhatsAppTemplates: [],

      newWhatsAppTemplateIdSelected: null,
      newWhatsAppTemplateSelected: null,

      tabResumeIndex: 0,

      resume: {
        sending: null,
        sms: {
          form: {
            clientId: '',
            body: '',
            normalize: false,
          },
        },
        whatsapp: {
          form: {
            clientId: '',
            type: 'simple',
            from: 'Default',
            to: '',
            body: '',
            file: '',
            normalize: false,
            flash: false,
            template: {},
            params: null,
          },
        },
      },
    };
  },
  filters: {
    fileSize: (value) => {
      if (value > 1073741824) return `${Math.floor(value / 1073741824)} GB`;

      if (value > 1048576) return `${Math.floor(value / 1048576)} MB`;

      if (value > 1024) return `${Math.floor(value / 1024)} kB`;

      return `${Math.floor(value * 1024)} bytes`;
    },
    roundTime: (value) => {
      const hours = Math.floor(value / 60 / 60);
      const mins = Math.floor((value / 60) % 60);
      const secs = Math.floor(value % 60);
      return `${hours}:${mins}:${secs}`.replace(/\b(\d)\b/g, '0$1').replace(/^00:/, '');
    },
  },
  computed: {
    message() {
      this.refreshMessage;
      const roomId = this.$parent?.currentRoom?.roomId;
      console.info('chooseSampleMessage:message', [roomId, this.refreshMessage]);
      return this.messages[roomId] || '';
    },
    messageDetails() {
      const data = { limit: 0, length: 0 };

      if (!this.$parent.currentRoom) return data;

      const channel = this.$parent.currentRoom.channel;
      const message = this.message;

      if (channel === 'sms') {
        if (/^[ -~\n]*$/.test(message)) {
          // GSM
          data.limit = this.limits.smsGSM;
          data.length = message.length;
        } else {
          // UNICODE
          data.limit = this.limits.smsUnicode;
          data.length = message.replace(/%([0-9A-F]{2})/ig, (match, hex) => String.fromCharCode(parseInt(hex, 16))).length;
        }
      } else {
        data.limit = this.limits[channel];
        data.length = message.length;
      }

      return data;
    },
    filtredSamples() {
      const filter = this.filterSamples;
      const samples = this.$live.sampleMessages;

      if (!filter) return samples;

      return samples.filter((sample) => sample.key.indexOf(filter) !== -1 || sample.message.indexOf(filter) !== -1);
    },
    autocompleteSampleMsgs() {
      let filter = this.message.trim();
      const samples = this.$live.sampleMessages;

      // console.log('autocompleteSampleMsgs1', { filter });

      if (filter.indexOf('/') !== 0) return [];

      if (samples.length === 0) this.$live.requestSampleMessages();

      if (filter === '/') return samples;

      filter = filter.replace(/^\/(\w+).*/, '$1');

      // console.log('autocompleteSampleMsgs2', { filter });

      return samples.filter((sample) => sample.key.indexOf(filter) === 0);
    },
    autocompleteSampleVisible() {
      return !this.createSampleMsg && (this.autocompleteSampleMsgs.length > 0 || (
        this.$live.sampleMessagesLoading && this.message.trim().indexOf('/') === 0
      ));
      // return this.autocompleteSampleMsgs.length;
    },
    resumeOpeningOpts() {
      const room = this.$parent.actualRoom;
      return room && room.session !== 'closed';
    },
    account() {
      return this.$store.state.account;
    },
    smsFields() {
      const body = this.resume.sms?.form?.body;
      return body && body.length > 3;
    },
    whatsAppFields() {
      if (!this.whatsAppTemplate || !this.whatsAppTemplates.length) return false;

      const params = this.resume.whatsapp?.form?.params;

      if (!params) return true;

      return params.length && params.findIndex((param) => param.text === '') === -1;
    },
    whatsAppBody() {
      const form = this.resume.whatsapp?.form;

      if (!form?.body) return '';

      const params = form.params;

      return form.body.replace(/\{\{(.*?)\}\}/g, (original, index) => {
        const entry = params.find((param) => param.index === parseInt(index, 10));
        return entry?.text || '';
      });
    },
    newWhatsApp() {
      const account = this.$store.state.account;
      return account.whitelabel_id === null || this.$live.isMeta;
    },
    oldWhatsApp() {
      const account = this.$store.state.account;
      return account.whitelabel_id === null || !this.newWhatsApp;
    },
    roomNumber() {
      return this.$parent?.actualRoom?.contact?.mobileNumber || null;
    },
    currentMessages() {
      let messages;

      if (this.$parent.fromTranscript) {
        messages = this.$live.associativeTranscriptsMessages;
      } else {
        messages = this.$live.messages;
      }

      if (messages?.length) {
        return messages.sort((a, b) => {
          const result = new Date(a.sentAt) - new Date(b.sentAt);

          if (result < 0) return -1;

          if (result > 0) return 1;

          return 0;
        });
      }

      return [];
    },
  },
  mounted() {
    // this.$live.totalMessages[this.$parent.currentRoom.roomId]
    this.$root.$on('template.send', this.sendMessageTemplate);
  },
  beforeDestroy() {
    // console.error('beforeDestroy');
    this.resetRecordAudio();
  },
  watch: {
    whatsAppTemplate(newVal) {
      this.insertTemplate(newVal);
    },
    roomId() {
      this.managerEnter = false;
      this.markAsSolvedAfter = false;

      // console.log('roomId', this.roomId, this.readOnly);
      this.resetRecordAudio();

      setTimeout(() => {
        // console.log('this.readOnly', this.readOnly);
        if (!this.readOnly) this.reqTriggerScroll();
      }, 100);

      this.resume.sending = null;
    },
    readOnly() {
    },
    // totalMessages() {
    //   console.log('totalMessages', this.totalMessages);
    // },
    messages: {
      handler(newValue, oldValue) {
        console.log('#messages', { newValue, oldValue });
        this.refreshMessage += 1;
      },
      deep: true,
    },
  },
  methods: {
    bootWhatsAppTemplates() {
      this.whatsAppTemplates = [];

      // WhatsAppService.getClient().then((response) => {
      //   const active = response.data.active || false;

      //   this.whatsAppActive = active;

      //   if (active) this.fetchWhatsAppTemplates();
      // }).catch((ee) => {
      //   this.$toast.show({
      //     title: 'Conta WhatsApp',
      //     content: String(ee),
      //     type: 'danger',
      //   });
      // });

      this.whatsAppActive = true;

      this.fetchWhatsAppTemplates();
      // this.simulateWhatsAppTemplates();
    },
    bootWhatsAppTemplatesx() {
      this.whatsAppTemplates = [];

      // 360
      // WhatsAppService.getClient().then((response) => {
      //   const active = response.data.active || false;

      //   this.whatsAppActive = active;

      //   if (active) this.fetchWhatsAppTemplates();
      // }).catch((ee) => {
      //   this.$toast.show({
      //     title: 'Conta WhatsApp',
      //     content: String(ee),
      //     type: 'danger',
      //   });
      // });

      // Novo
      // if (this.newWhatsApp) {
      //   this.getWhatsNumbers();
      // } else {
      //   this.whatsAppActive = true;
      //   this.fetchWhatsAppTemplates();
      // }

      // Simulado
      // this.simulateWhatsAppTemplates();
    },
    simulateWhatsAppTemplates() {
      const response = {
        status: 'success',
        total: 6,
        data: {
          count: 6,
          filters: {},
          limit: 10,
          offset: 0,
          sort: [
            'id',
          ],
          total: 6,
          waba_templates: [
            {
              category: 'MARKETING',
              components: [
                {
                  text: 'Olá.\n\nEscolha a opção desejada: ',
                  type: 'BODY',
                },
                {
                  text: 'Tst- Vinicius',
                  type: 'FOOTER',
                },
                {
                  buttons: [
                    {
                      text: 'Suporte',
                      type: 'QUICK_REPLY',
                    },
                    {
                      text: 'Financeiro',
                      type: 'QUICK_REPLY',
                    },
                    {
                      text: 'Comercial',
                      type: 'QUICK_REPLY',
                    },
                  ],
                  type: 'BUTTONS',
                },
              ],
              language: 'pt_BR',
              name: 'tstvinicius_botao03',
              namespace: '93c67064_8fce_47a9_b786_d80bb15a5d5f',
              rejected_reason: '',
              status: 'approved',
            },
            {
              category: 'ACCOUNT_UPDATE',
              components: [
                {
                  example: {
                    header_handle: [
                      'https://scontent.whatsapp.net/v/t61.29466-34/145750688_470574831486430_3739523328288718763_n.png?ccb=1-7&_nc_sid=57045b&_nc_ohc=CFQ_j9qjGJMAX9bSMm9&_nc_ht=scontent.whatsapp.net&edm=AH51TzQEAAAA&oh=01_AVzHH7sPME_ikvD4qrPqwJE5oRjSh-gq-WeQyhO5LfnXsw&oe=629C6A1D',
                    ],
                  },
                  format: 'IMAGE',
                  type: 'HEADER',
                },
                {
                  text: 'Olá tudo bem? 🤩\n\nVocê entrou em contato conosco fora de nosso horário de atendimento, somos da *Yup Chat*, em que podemos ajudar? 😇',
                  type: 'BODY',
                },
                {
                  text: 'Yup Chat | Relacionamento Inteligente',
                  type: 'FOOTER',
                },
                {
                  buttons: [
                    {
                      text: 'Acesse nosso site',
                      type: 'URL',
                      url: 'https://yup.chat/',
                    },
                    {
                      phone_number: '+558006911000',
                      text: 'Ligue para nós',
                      type: 'PHONE_NUMBER',
                    },
                  ],
                  type: 'BUTTONS',
                },
              ],
              language: 'pt_BR',
              name: 'inicio_atendimento',
              namespace: '93c67064_8fce_47a9_b786_d80bb15a5d5f',
              rejected_reason: 'NONE',
              status: 'approved',
            },
            {
              category: 'ACCOUNT_UPDATE',
              components: [
                {
                  example: {
                    body_text: [
                      [
                        'a',
                        'b',
                        'c',
                        'd',
                        'e',
                        'f',
                      ],
                    ],
                  },
                  text: 'Corpo {{2}}{{1}}{{3}}{{4}}{{5}}{{6}}',
                  type: 'BODY',
                },
                {
                  format: 'TEXT',
                  text: 'Cabeçalho',
                  type: 'HEADER',
                },
              ],
              language: 'pt_BR',
              name: 'ola',
              namespace: '93c67064_8fce_47a9_b786_d80bb15a5d5f',
              rejected_reason: '',
              status: 'approved',
            },
            {
              category: 'ACCOUNT_UPDATE',
              components: [
                {
                  buttons: [
                    {
                      text: 'Sim ',
                      type: 'QUICK_REPLY',
                    },
                    {
                      text: 'Não',
                      type: 'QUICK_REPLY',
                    },
                  ],
                  type: 'BUTTONS',
                },
                {
                  text: 'Tst',
                  type: 'FOOTER',
                },
                {
                  text: 'Essa mensagem é um conteúdo para testes de envio interno.\n\nCaso tenha recebido, selecione uma das opções abaixo:',
                  type: 'BODY',
                },
                {
                  format: 'TEXT',
                  text: 'Olá',
                  type: 'HEADER',
                },
              ],
              language: 'pt_BR',
              name: 'test_vinicius101',
              namespace: '93c67064_8fce_47a9_b786_d80bb15a5d5f',
              rejected_reason: '',
              status: 'approved',
            },
            {
              category: 'TRANSACTIONAL',
              components: [
                {
                  text: 'Olá, podemos conversar agora?',
                  type: 'BODY',
                },
                {
                  format: 'TEXT',
                  text: 'Yup Chat',
                  type: 'HEADER',
                },
              ],
              language: 'pt_BR',
              name: 'reconnect_yup',
              namespace: '93c67064_8fce_47a9_b786_d80bb15a5d5f',
              rejected_reason: '',
              status: 'approved',
            },
            {
              category: 'AUTO_REPLY',
              components: [
                {
                  text: 'Olá, tudo bem?\n\nObrigado pela participação em nosso treinamento e a curiosidade para conhecer melhor o WhatsApp Business API.\n\nAvalie de 0 a 5 a eficiência de envio desta mensagem.',
                  type: 'BODY',
                },
                {
                  text: 'Yup Chat | Relacionamento Inteligente',
                  type: 'FOOTER',
                },
                {
                  buttons: [
                    {
                      text: 'Visite nosso site...',
                      type: 'URL',
                      url: 'https://yup.chat/',
                    },
                  ],
                  type: 'BUTTONS',
                },
              ],
              language: 'pt_BR',
              name: 'treinamento_v1',
              namespace: '93c67064_8fce_47a9_b786_d80bb15a5d5f',
              rejected_reason: 'NONE',
              status: 'approved',
            },
          ],
        },
      };

      const templates = response?.data?.['waba_templates'];

      if (templates) this.parseWhatsAppData(templates);
    },

    /* old */
    fetchWhatsAppTemplates() {
      axios().post('whatsapp/templates/list').then((response) => {
        const templates = response?.data?.data?.['waba_templates'];

        console.log('fetchWhatsAppTemplates', templates, response.data);

        if (templates) {
          this.parseWhatsAppData(templates);
        } else {
          this.$toast.show({
            title: 'Templates WhatsApp',
            content: 'Formato inesperado do servidor',
            type: 'danger',
          });
        }
      }).catch((ee) => {
        this.$toast.show({
          title: 'Templates WhatsApp',
          content: String(ee),
          type: 'danger',
        });
      });
    },
    parseWhatsAppData(templates) {
      this.whatsAppTemplates = templates.filter((template) => {
        if (template.status !== 'approved') return false;

        return template.components.findIndex((component) => component.format && component.format !== 'TEXT');
      });
    },

    /* new */
    getWhatsNumbers() {
      this.newWhatsAppNumbersFetched = false;

      axios().get('whatsapp/new/numbers').then((response) => {
        this.newWhatsAppNumbers = [];
        response.data.forEach((data) => {
          this.newWhatsAppNumbers.push(data);
        });
        this.newWhatsAppId = this.newWhatsAppNumbers[0].whatsapp_identification;
        this.fetchWhatsAppTemplatesNew(1);
      }, (error) => {
        console.error(error);
      }).finally(() => {
        this.newWhatsAppNumbersFetched = true;
      });
    },
    fetchWhatsAppTemplatesNew(page) {
      this.newWhatsAppTemplatesFetched = false;

      WhatsAppService.getNewTemplates(this.newWhatsAppId, {
        params: { page },
      }).then((response) => {
        console.log('Templates');
        this.newWhatsAppTemplates = [];

        if (response && response.data && response.data.content) {
          response.data.content.forEach((template) => {
            if (
              !template.buttons &&
              template.body.text.indexOf('{{') === -1 &&
              template.category === 'TRANSACTIONAL' &&
              template.status === 'APPROVED' &&
              !template.footer &&
              template.status === 'APPROVED'
            ) {
              this.newWhatsAppTemplates.push(template);
            }
          });
        }
        this.fetchedItens = true;
      }, (error) => {
        console.error(error);
      }).finally(() => {
        this.newWhatsAppTemplatesFetched = true;
      });
    },
    setWhatsAppTemplate() {
      const id = this.newWhatsAppTemplateIdSelected;

      this.newWhatsAppTemplateSelected = this.newWhatsAppTemplates.find((template) => template.id === id);
    },

    markAsRead() {
      const room = this.$parent.currentRoom;

      if (room && !this.$parent.isManager) this.$live.reqFlagMessages('READ', room);
    },
    exitRoom() {
      this.$parent.chooseRoom(null, false);
      this.resetRecordAudio();
    },
    async delay(timeout) {
      return new Promise((resolve) => {
        setTimeout(() => { resolve(); }, timeout);
      });
    },
    async startRecordAudio() {
      if (this.recorder !== null || this.audioURL !== null) return;

      this.resetPreviewFile();

      try {
        const recorder = new MicRecorder({ bitRate: 128 });

        recorder.start().then(() => {
          this.recorder = recorder;

          if (this.recordInterval) clearInterval(this.recordInterval);

          this.recordTimer = 0;

          this.recordInterval = setInterval(() => {
            this.recordTimer += 1;

            if (this.recordTimer >= this.recordMax) this.finishRecordAudio();
          }, 1000);
        }).catch((ee) => {
          console.error('audio:catch', ee);
        });
      } catch (ee) {
        console.error('audio:catch', ee);
      }
    },
    async finishRecordAudio() {
      if (this.recordInterval) clearInterval(this.recordInterval);

      const channel = this.$parent.currentRoom?.channel;

      if (!channel) return;

      if (this.recorder !== null) {
        this.recordResolving = true;
        this.recorder.stop().getMp3().then(async ([buffer, blob]) => {
          console.log('ffmpeg:#1');

          /*
          <i class="fas fa-globe" v-if="$parent.actualRoom.channel === 'widget'"></i>
          <i class="fab fa-facebook-messenger" v-else-if="$parent.actualRoom.channel === 'messenger'"></i>
          <i class="fab fa-whatsapp" v-else-if="$parent.actualRoom.channel === 'whatsapp'"></i>
          <i class="fab fa-google" v-else-if="$parent.actualRoom.channel === 'rcs'"></i>
          <i class="fab fa-telegram" v-else-if="$parent.actualRoom.channel === 'telegram'"></i>
          <i class="fab fa-instagram" v-else-if="$parent.actualRoom.channel === 'instagram'"></i>
          <i class="fas fa-envelope" v-else-if="$parent.actualRoom.channel === 'email'"></i>
          <i class="fab fa-google" v-else-if="$parent.actualRoom.channel === 'google-business'"></i>
          */

          // Instagram e whatsapp usará m4a (aac)
          // channel === 'messenger'

          this.recorder = null;

          this.finishRecordAudio2(blob);

          // let type = null;
          // let output;
          // let params = [];

          // if (channel === 'instagram') {
          //   type = 'audio/aac';
          //   output = 'm4a';
          //   // type = 'audio/mpeg';
          //   // output = 'mp4';
          // } else if (channel === 'messenger') {
          //   type = 'audio/mpeg';
          //   output = 'mp4';
          // } else if (channel === 'whatsapp') {
          //   type = 'audio/ogg';
          //   output = 'oga';
          //   params = '-c:a libopus -b:a 16k'.split(/\s+/);
          // }

          // if (type) {
          //   const url = URL.createObjectURL(blob);

          //   console.log('$ffmpeg', { url, output, params });

          //   this.$ffmpeg(url, output, params).then(async (data) => {
          //     console.log('ffmpeg:#2', data, data.buffer);
          //     URL.revokeObjectURL(url);
          //     this.finishRecordAudio2(new Blob([data], { type }));
          //   }).catch((err) => {
          //     this.recordResolving = false;

          //     console.error('ffmpeg', err);

          //     this.$toast.show({
          //       title: 'Upload',
          //       type: 'danger',
          //       content: err.toString(),
          //     });
          //   }).finally(() => {
          //     URL.revokeObjectURL(url);
          //   });
          // } else {
          //   // outros canais usarão mp3
          //   if (channel === 'whatsapp') {
          //     blob = blob.slice(0, blob.size, 'audio/mpeg');
          //   }
          //   this.finishRecordAudio2(blob);
          // }
        });
      }
    },
    async finishRecordAudio2(blob) {
      this.audioData = blob;
      this.audioURL = URL.createObjectURL(this.audioData);

      let ref = this.$refs.audioURLRef;

      if (!ref) {
        await this.delay(60);

        ref = this.$refs.audioURLRef;

        this.recordResolving = false;

        if (!ref) return;
      }

      ref.muted = true;
      ref.play();

      await this.delay(250);

      ref.currentTime = 100;
      ref.pause();

      await this.delay(100);

      ref.currentTime = 0;
      ref.muted = false;

      this.recorder = null;
      this.recordResolving = false;
    },
    async resetRecordAudio() {
      console.log('ondataavailable:reset', this.recorder?.state);
      this.recordResolving = false;

      this.finishRecordAudio();

      if (this.audioURL !== null) URL.revokeObjectURL(this.audioURL);

      this.audioData = null;
      this.audioURL = null;
      this.recorder = null;
    },
    async sendRecordAudio() {
      this.finishRecordAudio();

      if (this.audioURL !== null) {
        const data = this.audioData;
        const type = this.audioData.type;

        console.log('sendRecordAudio', this.audioURL, this.audioData);

        let ext = '';

        if (type.indexOf('/mpeg') !== -1) {
          ext = '.mp3';
          // this.audioData = this.audioData.slice(0, this.audioData.size, 'audio/mpeg');
        } else if (type.indexOf('/ogg') !== -1) {
          ext = '.ogg';
        } else if (type.indexOf('/webm') !== -1) {
          ext = '.webm';
        } else if (type.indexOf('/mp3') !== -1) {
          ext = '.mp3';
        } else if (type.indexOf('/aac') !== -1) {
          ext = '.m4a';
        }

        console.log('sendRecordAudio#2', ext, this.audioData);

        this.sendFile(this.audioData, `audio${ext}`);
      }
    },
    requestFile() {
      if (!this.uploading) this.$refs.attachInput.click();
    },
    async previewFileFromClipboard(event) {
      const source = event.clipboardData?.files?.[0];

      if (source.type && source.type.indexOf('image/') === 0) {
        this.previewFile(source);
      }
    },
    async previewFileFromInput() {
      const source = this.$refs.attachInput.files.length ? this.$refs.attachInput.files[0] : null;

      if (source) this.previewFile(source);
    },
    async previewFile(source) {
      const room = this.$parent.currentRoom;

      console.log('previewFile', source, room);

      if (!source || !room) return;

      const channel = room.channel;

      if (this.$live.validateFile(source, channel)) {
        // this.sendFile();
        const url = URL.createObjectURL(source);

        if (source.type.indexOf('image/') === 0) {
          source = await this.$live.imageToJpeg(url);

          console.info('validateFile:source', source);

          if (!source) {
            this.$toast.show({
              title: 'Upload',
              type: 'danger',
              content: 'Imagem inválida ou danificada',
            });
            return;
          }
        }

        this.beforeUpload = { url, source };
      } else {
        this.$toast.show({
          title: 'Upload',
          type: 'danger',
          content: `Tamanho inválido do arquivo ${source.size} para o canal ${channel}`,
        });
      }
    },
    resetPreviewFile() {
      this.beforeUpload = null;
      this.$refs.attachInput.value = '';
    },
    sendFile(file, fileName) {
      // const file = this.audioData;

      console.log('sendMessage:sendFile#2', this.uploading, file);

      this.resetRecordAudio();

      if (this.uploading) return;

      this.resetPreviewFile();

      this.uploading = true;

      const mime = file.type.replace(/\/x-/, '').replace(/(\s+)?;.*/, '');

      if (!fileName) {
        fileName = String(Date.now());

        switch (mime) {
          case 'image/jpeg':
          case 'image/jpg':
          case 'image/jpe':
            fileName += '.jpg';
            break;
          case 'image/png':
            fileName += '.png';
            break;
          case 'image/gif':
            fileName += '.gif';
            break;
          case 'image/svg':
          case 'image/svg+xml':
          case 'application/svg+xml':
            fileName += '.svg';
            break;
          case 'video/mpeg':
          case 'video/mp4':
            fileName += '.mp4';
            break;
          default:
            console.info('sendFile', mime);
            fileName += file.name.replace(/^.*?\.([^.]+$)/, '.$1');
        }
      }

      console.log('sendFile:', { file, fileName });

      this.reqTriggerScroll();

      const channel = this.$parent.currentRoom.channel;
      const requestConvert = mime === 'audio/mp3' &&
              ['instagram', 'whatsapp', 'whatsapp-java', 'messenger'].indexOf(channel) !== -1;

      this.$live.sendFile(this.$parent.currentRoom.roomId, file, fileName, requestConvert, this.message, channel).then((data) => {
        console.log('sendFile/UPLOAD', data);
      }).catch((error) => {
        console.error('sendFile/UPLOAD', error);
        this.$toast.show({
          title: 'Upload',
          type: 'danger',
          content: 'Falha ao enviar o arquivo',
        });
      }).finally(() => {
        this.uploading = false;
        console.info('sendFile/UPLOAD', 'finally');
      });
    },
    // sendFile() {
    //   this.$parent.$refs['live-send-file-modal'].show();
    // },
    callLight(message) {
      this.$emit('callLight', message);
    },
    addEmoji(emoji) {
      const roomId = this.$parent.currentRoom.roomId;

      if (!roomId) return;

      if (this.message) {
        this.messages[roomId] += ` ${emoji}`;

        const input = this.$refs.inputMessage;

        if (input) input.scrollTop = input.scrollHeight;
      } else {
        this.messages[roomId] = emoji;
      }

      this.refreshMessage += 1;
    },
    sendMessageTemplate(form) {
      this.messages[this.$parent.currentRoom.roomId] = form.body;
      this.templateName = form.templateName;
      this.sendMessage();
    },
    sendTemplate() {
      this.$root.$emit('show.modal', 'send-m');
    },
    showTemplates() {
      this.$refs['ref-templates'].show();
    },
    sendMessage(event) {
      const message = this.message.trim();
      const room = this.$parent.currentRoom;
      const source = this.beforeUpload?.source;

      if (message.indexOf('/') === 0) return;

      if (message || source) {
        if (event && event.type === 'keydown' && (event.ctrlKey || event.shiftKey)) {
          this.messages[room.roomId] += '\n';
        } else if (this.messageDetails.length <= this.messageDetails.limit) {
          if (source) {
            console.log('sendMessage:sendFile#1', source);
            this.sendFile(source, null);
          } else {
            console.log('sendMessage:this.$live.sendMessage');
            this.$live.sendMessage(room, message);
          }

          this.messages[room.roomId] = '';
          this.refreshMessage += 1;
          this.templateName = null;
          // console.log('Enviado');

          this.beforeSentTyping(false);

          if (this.$refs.inputMessage) this.$refs.inputMessage.focus();
        }
      }

      this.refreshMessage += 1;
    },
    beforeSentTyping(typing) {
      // if (this.timeoutTyping) clearTimeout(this.timeoutTyping);

      // this.timeoutTyping = setTimeout(() => this.sentTyping(typing), 500);
    },
    sentTyping(typing) {
      // console.log('XXXXXXX', this.$live.online, this.$live.id);

      if (this.$parent.currentRoom && this.message !== '' && this.statusTyping !== typing) {
        // console.log('sentTyping', typing, this.$parent.currentRoom);

        this.statusTyping = typing;

        this.$live.emit('typing', {
          typing,
          roomId: this.$parent.currentRoom.roomId,
        });
      }
    },
    reqTriggerScroll() {
      clearTimeout(this.scrollTimeout);

      this.scrollTimeout = setTimeout(() => {
        this.triggerScroll();
      }, 100);
    },
    triggerScroll() {
      // console.log('$$$triggerScroll');

      const area = this.$refs.transcript;

      if (area) area.scrollTop = area.scrollHeight - area.clientHeight;
    },
    reqResumeOpen() {
      const room = this.$parent.currentRoom;

      Swal.fire({
        title: 'Sessão',
        text: 'Iniciar uma nova sessão para esse cliente?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (!result.value) return;

        if (room.fromIdle) {
          this.$live.openIdleNow(room);
        } else {
          this.$live.openNow(room);
        }
      });
    },
    sendResumeSms() {
      const markAsSolved = this.markAsSolvedAfter;
      const room = this.$parent.actualRoom;
      const mobileNumber = room?.contact?.mobileNumber;

      if (!mobileNumber) {
        console.error('sendResumeSms', room);
        return;
      }

      this.resume.sending = 'sending';

      const form = this.resume.sms.form;

      form.agentId = this.$live.myUserId;
      form.clientId = room.clientId;
      form.phone = mobileNumber;

      console.log('sendResumeSms', form);

      axios().post('webchat/resume/sms', form).then((response) => {
        this.resume.sending = 'done';
        this.reqTriggerScroll();

        if (markAsSolved) this.$live.markAsSolved(room);
      }).catch((ee) => {
        this.resume.sending = 'none';

        this.$toast.show({
          title: 'Falha ao enviar SMS',
          content: String(ee),
          type: 'danger',
        });
      });
    },
    sendResumeWhatsApp(newSystem) {
      const markAsSolved = this.markAsSolvedAfter;
      const room = this.$parent.actualRoom;
      const mobileNumber = room?.contact?.mobileNumber;

      if (!mobileNumber) {
        console.error('sendResumeWhatsApp', room);
        return;
      }

      this.resume.sending = 'sending';

      let request = null;

      if (newSystem) {
        const template = this.newWhatsAppTemplateSelected;
        const form = {
          button_parameter: null,
          client_number: mobileNumber.replace(/\D+/g, ''),
          header_parameter: null,
          header_type: null,
          language_code: template.language,
          template_name: template.name,
          business_id: template.business_id,
          type: 'template',
        };

        console.info('send whatsapp', { form, template });

        request = axios().post('whatsapp/new/send', form);
      } else {
        const form = this.resume.whatsapp.form;

        form.agentId = this.$live.myUserId;
        form.clientId = room.clientId;
        form.phone = mobileNumber;

        console.log('sendResumeWhatsApp', form);

        request = axios().post('webchat/resume/whatsapp', form);
      }

      request.then((response) => {
        this.resume.sending = 'done';
        this.reqTriggerScroll();

        if (markAsSolved) this.$live.markAsSolved(room);
      }).catch((ee) => {
        this.resume.sending = 'none';

        this.$toast.show({
          title: 'Falha ao enviar WhatsApp',
          content: String(ee),
          type: 'danger',
        });
      });
    },
    insertTemplate(template) {
      const form = this.resume.whatsapp.form;

      form.template = {};
      form.template.name = template.name;

      template.components.forEach((element) => {
        form.template.type = 'text';

        switch (element.type) {
          case 'HEADER':
          case 'FOOTER':
            form.template.header = element;
            form.template.type = 'rich';

            if (element.type === 'HEADER' && element.format === 'IMAGE') {
              form.template.type = 'image';
            }
            break;
          case 'BODY':
            form.template.body = element.text;
            form.body = element.text;
            break;
          case 'BUTTONS':
            form.template.buttons = element.buttons;
            form.template.type = 'rich';
            break;
          default:
            console.info(element);
        }
      });

      const params = form.template.body.match(/\{\{(.*?)\}\}/g);

      if (params) {
        form.params = params.map((_, index) => ({ index: index + 1, text: '' }));
      } else {
        form.params = null;
      }

      console.log('form.params', form.params);
    },
    createSampleAction() {
      this.createSampleMsgNew = 2;

      this.$live.createSampleMessage(
        this.sampleForm.key,
        this.sampleForm.message,
        this.sampleForm.availableTo,
      ).then(() => {
        this.toggleCreateSampleMessage(false);
        this.createSampleMsgNew = 0;
      });
    },
    toggleCreateSampleMessage(force) {
      if (typeof force === 'boolean') {
        this.createSampleMsg = force;
      } else {
        this.createSampleMsg = !this.createSampleMsg;
      }

      if (this.createSampleMsg) this.$live.requestSampleMessages();
    },
    chooseSampleMessage(sample) {
      const roomId = this.$parent.currentRoom.roomId;

      if (!roomId) return;

      this.messages[roomId] = sample.message;
      Vue.set(this.messages, roomId, sample.message);

      this.toggleCreateSampleMessage(false);

      console.info('chooseSampleMessage', [roomId, this.message]);

      if (this.$refs.inputMessage) this.$refs.inputMessage.focus();

      this.refreshMessage += 1;
    },
    interactive(content) {
      return content.replace(/([<]span data-interactive="true"[>])/g, `$1${INTERACTIVE_CONTENT}`);
    },
    handleInteractive(event) {
      const target = event.target;

      if (target.matches('[data-interactive="copy"], [data-interactive="copy"] *')) {
        const data = target.closest('[data-interactive="true"]').textContent.trim();

        if (this.$clipboard(data)) {
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: 'Copiado',
            type: 'success',
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.whatsapp-bg {
  background: #efeae2 url('/assets/img/chat-bg.png');
  margin: 1.25rem -1.25rem;
  padding: 1rem;
  pointer-events: none;
}

.whatsapp-message {
  display: inline-block;
  max-width: 65%;
  min-width: 224px;
}

.whatsapp-preview {
  margin: 0 0 .1rem 0;

  & > p {
    margin: 0;
  }
}

.qt-loader {
  text-align: center;
  padding: 32px;
}

.recording-write {
  text-align: center;

  &::v-deep .spinner-grow {
    margin: 0 .22em;
  }
}

.live-resume-contact {
  margin-top: 1em;

  &::v-deep > .card > .tabs {
    & > .card-header {
      padding-bottom: 0;

      & > .nav {
        margin-bottom: 0;
      }
    }
  }
}

.live-media-preview {
  background: transparent;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;

  video {
    background: #333;
  }

  & > * {
    pointer-events: none;
    min-height: 16px;
    min-width: 16px;
    display: block;
  }
}

.live-conversation {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  position: relative;
}

.live-old-load {
  padding: 1em;
  text-align: center;
}

.live-transcript {
  height: calc(100% - 172px);
  overflow: hidden;
  overflow-y: auto;

  &.readonly, &.live-transcript-old {
    height: calc(100% - 80px);
    background: url('data:image/svg+xml,%3Csvg width="220px" height="220px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">%3Cg fill="%23eee">%3Cpath fill="none" d="M0 0H24V24H0z"/>%3Cpath d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12h2c0 4.418 3.582 8 8 8s8-3.582 8-8-3.582-8-8-8C9.25 4 6.824 5.387 5.385 7.5H8v2H2v-6h2V6c1.824-2.43 4.729-4 8-4zm1 5v4.585l3.243 3.243-1.415 1.415L11 12.413V7h2z"/>%3C/g>%3C/svg>') bottom right no-repeat;
  }
}

.live-transcript-old + .live-transcript {
  height: 0 !important;
  padding: 0 !important;
}

.live-manager-actions {
  position: relative;
  height: 92px;
  text-align: right;

  &.live-resume-actions {
    width: 204px;
    position: absolute;
    bottom: 0;
    right: .5em;
  }

  width: 204px;
  position: absolute;
  bottom: 0;
  right: .5em;

  .live-manager-enter {
    color: #fff;
    background: #0E1421;
    padding: 1em 2em;
    border-radius: 50px;
    border: none;
    font-size: 9pt;
    display: block;
    width: 98%;
    height: 40px;
    white-space: nowrap;

    & ~ .live-manager-enter {
      margin-top: .3em;
    }

    &, i {
      vertical-align: middle;
    }

    i {
      font-size: 13pt;
      margin-right: .5em;
      color: #fff;
    }
  }
}

.live-input {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: normal;
  align-items: center;
  border-top: thin solid #e8e4e4;
  background: #fff;
  padding: 1em 1em 0 1em;
  height: 92px !important;
  position: relative;
  z-index: 90;

  @media (max-width: 800px) {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
  }

  & > * {
    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: 57px;
    align-self: auto;
    order: 0;
    height: 62px;

    @media (max-width: 480px) {
      flex-basis: 45px;
    }
  }

  .live-file-preview {
    position: absolute;
    z-index: 90;
    left: 0;
    bottom: 90px;
    width: 100%;
    height: 60vh;
    max-height: 640px;
    margin: 0;
    border: 0;
    border-radius: 0;
    background: #fff;
    border: 0 solid #e8e4e4;
    border-top-width: thin;
    box-shadow: 0 -31px 31px -22px rgba(0,0,0,.2);

    aside {
      min-width: 200px;

      img, audio, video {
        display: block;
        margin: 0 auto;
      }
    }

    &.live-record-audio {
      bottom: 0;
      z-index: 91;

      .record {
        text-align: center;

        .timer {
          font-size: 9pt;
        }

        .audio-finish {
          margin: 10px 0;
          border-radius: 48px;
          background: #f00;
          border: none;
          height: 92px;
          width: 92px;
          font-size: 32pt;
          box-shadow: 0 0 0 rgba(0,0,0,.02), 0 0 0 0 rgba(0,0,0,.06);
          animation-duration: .4s;
          animation-name: audioWave;
          animation-iteration-count: infinite;
          animation-direction: alternate;

          i, & {
            color: #fff !important;
          }

          @keyframes audioWave {
            from {
              box-shadow: 0 0 0 0 rgba(0,0,0,.02), 0 0 0 0 rgba(0,0,0,.06);
            }

            to {
              box-shadow: 0 0 0 8px rgba(0,0,0,.2), 0 0 0 4px rgba(0,0,0,.2);
            }
          }
        }

        footer {
          font-size: 7pt;
        }

        .row {
          padding: 20px 0;
        }

        .row > div {
          padding: 0;
        }

        .audio-cancel, .audio-send {
          border-radius: 24px !important;
          color: #fff !important;
          width: 100%;
        }
      }
    }

    .card-header {
      height: 72px;

      button {
        background: transparent;
        border: none;
        padding: 0;

        i {
          font-size: 16pt;
        }
      }
    }

    .card-body {
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100% - 72px);
      position: relative;

      aside {
        img, object, video {
          position: relative;
          border: thin solid #e8e4e4;
          object-fit: contain;
          height: 100%;
          max-height: 240px;

          @media (max-height: 500px) {
            max-height: 150px;
          }

          @media (max-height: 420px) {
            max-height: 110px;
          }
        }

        .d-flex {
          @media (max-height: 420px), (max-width: 240px) {
            font-size: 8pt;
          }
        }
      }
    }
  }

  .live-file-preview-meta {
    font-size: 9pt;
  }

  .live-input-out {
    flex-grow: 1;
    flex-basis: auto;
    // height: 52px;
    padding: 0 12px;
    height: auto !important;
  }
}

.live-input {
  border-top: thin solid #e8e4e4;
  background: #fff;
  padding: 0 1em;

  ::v-deep button > span, ::v-deep button > i {
    display: block;
  }

  .live-input-in {
    background: #e7e6e6;
    border-radius: 27px;
    position: relative;

    .live-input-textarea {
      background: #fc0;
      width: calc(100% - 40px);
      border: none;
      background: transparent;
      // background: #fc0;
      margin: 7px 20px;
      resize: none;

      // padding: .5em 0 0 0;
      // max-height: 6em;

      padding: .4em 0 0 0;
      max-height: 3.2em;

      // font-size: .92rem;

      &[readonly] {
        color: #cdcdcd;
      }
    }

    .window-listing-sample-msgs {
      position: absolute;
      left: 1.2rem;
      bottom: .82rem;
      background: #fff;
      max-width: 320px;
      min-width: 100px;
      overflow: auto;
      overflow-x: hidden;
      max-height: 300px;
      display: none;

      &.show {
        display: block;
      }

      .notfound {
        font-size: 10pt;
      }
    }

    .list-group-item {
      padding: 0;
      font-size: 8pt;
      text-align: left;

      & > strong,
      & .sample-item,
      & > div > strong {
        padding: .35rem;
        display: block;
      }

      .sample-dones {
        position: relative;
        padding: .35rem;
        padding-top: 0;

        &::after {
          position: absolute;
          left: -5%;
          bottom: 0;
          content: "";
          height: 10px;
          width: 110%;
          box-shadow: 5px -5px 6px 0 rgba(255,255,255,0.5) inset;
        }
      }

      .sample-item {
        width: 92px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #c0c0c0;
      }
    }

    .filter-msgs {
      .input-group-prepend {
        & > .input-group-text {
          background: transparent;
        }
      }
    }

    .window-create-sample-msg {
      position: absolute;
      z-index: 110;
      right: .32rem;
      bottom: .82rem;
      background: #fff;
      width: 420px;

      .window-create-sample-close {
        border: none;
        border-radius: 20px;
        background: #000;
        position: absolute;
        right: -15px;
        top: -15px;
        width: 32px;
        height: 32px;

        i {
          color: #fff;
        }
      }

      h1 {
        font-size: 12pt;
      }

      & > .relative {
        position: relative;
        padding: .7rem;
      }

      .qt-block-ui {
        position: absolute !important;
      }
    }

    .btn-create-sample-msg {
      background: transparent !important;
      border: none !important;
      height: auto !important;
      position: absolute;
      right: .64rem;
      top: .42rem;
      padding: .4rem .2rem;

      // i {
      //   color: #fff;
      // }

      & + textarea {
        width: calc(100% - 40px - 1.5rem);
      }
    }

    .sample-messages {
      height: 262px;
      overflow: auto;
      overflow-x: hidden;

      .list-group-item {
        text-align: left;

        & > div {
          max-height: 52px;
          overflow: hidden;
        }
      }
    }

    .new-sample {
      margin: 0 !important;

      * {
        font-size: 9pt !important;
      }

      textarea {
        min-height: 140px;
      }

      & > :first-child {
        padding: 0;
      }

      & > :last-child {
        max-height: 300px;
        overflow: auto;
        overflow-x: hidden;
      }

      .form-check-label {
        width: 160px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .form-check {
        & > input, & > label {
          vertical-align: middle;
        }

        & > label {
          margin-top: .1rem;
        }
      }

      .form-footer {
        & > :first-child {
          padding-right: .3rem !important;
        }

        & > :last-child {
          padding-left: .3rem !important;
        }
      }
    }
  }

  ::v-deep .emojis .popup-box-container button > span {
    display: block;
    height: 25px;
  }

  .live-templates, ::v-deep .emojis .popup-box-container button {
    border: none !important;
    background: transparent !important;
    padding: 0 !important;
    box-shadow: none !important;
    width: 48px;
    height: 62px;

    i {
      font-size: 18pt;
      color: #c1c7cd;
    }

    @media (max-width: 480px) {
      width: 22px;

      i {
        font-size: 12pt;
      }
    }
  }
}

/*
  .live-enter {
    float: right;
    height: 62px;
    width: 62px;
  }

  ::v-deep .emojis {
    float: left;
  }

  .live-templates, ::v-deep .emojis .popup-box-container button {
    border: none !important;
    background: transparent !important;
    padding: 0 !important;
    box-shadow: none !important;
    float: left;
    width: 48px;
    height: 62px;

    i {
      font-size: 18pt;
      color: #c1c7cd;
    }

    @media (max-width: 480px) {
      width: 22px;

      i {
        font-size: 12pt;
      }
    }
  }
}*/

.live-inner-header {
  border-bottom-width: thin;
  position: relative;

  .live-status {
    font-size: 7pt;
    font-weight: bold;
    vertical-align: middle;
  }

  .live-back {
    font-size: 14pt;
    float: left;
    width: 42px;
    height: 100%;
    background: transparent;
    border: none;

    &:hover {
      background: #fcfcfc;
    }

    @media (min-width: 801px) {
      display: none;
    }
  }

  & > .live-photo {
    margin: 18px 10px 0 10px;
    float: left;
    height: 48px;
    width: 48px;
    position: relative;
    border-radius: 0;
    background: transparent;

    .live-channel {
      position: absolute;
      right: -2px;
      top: -2px;
      font-size: 8pt;
      height: 16px;
      width: 16px;
      background: #fff;
      border-radius: 16px;
    }

    button {
      background: #dfdfdf;
      border: none;
      border-radius: 48px;
      float: left;
      height: 100%;
      width: 100%;
      font-size: 12pt;
      padding: 0;
      overflow: hidden;
      border-radius: 24px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  & > .live-header-name {
    padding-top: 28px;
    float: left;
    width: 50%;
    font-size: 12pt;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    .sub {
      margin-right: 5px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  @media (max-width: 920px) {
    & > .live-header-name {
      width: calc(80% - 100px);
    }

    .live-header-tools-m {
      width: 32px;
      margin-right: 15px;

      ::v-deep button.dropdown-toggle {
        background: #fff !important;
        border: none;
        padding: 8px;
        width: 100%;

        &::after {
          display: none;
        }
      }
    }
  }
}

.live-history-header {
  text-align: right;
  padding: .5em;
  border: solid #f7d85c;
  border-width: thin 0;
  background: #ffefad;
  font-size: 9pt;

  button {
    background: rgba(0,0,0,.1);
    border-radius: 14px;
    border: none;
    padding: 2px 10px;
    margin-left: 6px;
    transition: background-color .3s ease;
    vertical-align: middle;

    &:hover {
      background: rgba(0,0,0,.3);
    }
  }
}

.live-header-tools {
  padding: 21px 5px 0 5px;
  float: right;
  width: 220px;
  text-align: right;

  @media (max-width: 920px) {
    display: none;
  }

  &::v-deep button, .menu-item {
    border: none !important;
    padding: 0 !important;
    border-radius: 50%;
    height: 32px !important;
    width: 32px !important;
    margin-left: 4px;
    vertical-align: middle;
  }

  .menu-item {
    display: inline-block;

    &::v-deep .dropdown {
      &, button {
        color: #788db4 !important;
        margin: 0 !important;
      }

      button {
        box-shadow: none !important;
        background: transparent !important;
        border: none;
        padding: 8px;
        width: 100%;

        &::after {
          display: none;
        }

        &:hover, &:active, &:focus {
          box-shadow: none !important;
          background: #fafafa !important;
        }
      }
    }
  }

  button::v-deep {
    box-shadow: none !important;
    background: transparent !important;

    &:hover, &:active, &:focus {
      box-shadow: none !important;
      background: #fafafa !important;
    }
  }
}

.live-header-tools-m {
  display: none;
  width: 20%;
  float: right;
  margin-top: 22px;
  text-align: center;

  @media (max-width: 920px) {
    display: block;
  }
}

.live-inner-input {
  border-top-width: thin;
}

.live-transcript {
  padding: 1.5em;
}

.live-message {
  font-size: 10pt;
  min-width: 240px;

  .live-agent {
    position: absolute;
    right: -10px;
    top: -10px;
    color: #fff;
    font-size: 7pt;
    text-transform: uppercase;
    padding: .5em !important;
    line-height: 1 !important;

    &.finished {
      background: #f00 !important;
    }
  }

  .live-message-box {
    min-width: 200px;
    max-width: 60%;
    float: left;

    .icon-fail {
      font-size: 8pt;
      color: #fff;
    }

    .live-message-source {
      padding: 4px 2px 20px 2px;
      font-size: 10pt;

      i {
        vertical-align: middle;
        margin-right: 2px;
      }
    }
  }

  .live-message-content {
    overflow-wrap: break-word;
    // word-break: break-all;
  }

  .live-message-content::v-deep {
    code {
      overflow-wrap: break-word;
      white-space: pre-wrap;
      color: inherit;
    }

    [data-interactive] {
      display: inline-block;
      position: relative;
      font-weight: 400;

      & > div {
        position: absolute;
        right: -45%;
        top: -82%;
        opacity: 0;
        transition: .2s all ease-out;

        & > button {
          box-shadow: 0 1px 1px 1px rgba(0,0,0,.1);
          border-radius: .1rem;
          border: thin solid #c1c1c1;
          background: #fff;
          color: #c1c1c1;
        }
      }

      &:hover > div {
        opacity: 1;
      }
    }
  }

  .live-message-inner {
    position: relative;
    border: 1px solid #d6dadd !important;
    background: #fff;
    padding: 14px 14px 32px 14px;
    border-radius: 1em;
    letter-spacing: normal !important;

    .live-message-opts {
      position: absolute;
      right: 10px;
      top: 10px;

      &::v-deep .dropdown-toggle {
        visibility: hidden !important;
        background: transparent !important;
        padding: .1em .4em;
        height: auto;
        line-height: 1;
      }
    }

    &:hover .live-message-opts {
      &::v-deep .dropdown-toggle {
        visibility: visible !important;
        background: transparent !important;
        padding: .1em .4em;
        height: auto;
        line-height: 1;
      }
    }

    & > picture {
      position: absolute;
      right: -10px;
      top: -10px;
      color: #fff;
      background: #cfcfcf;
      width: 24px;
      height: 24px;
      border-radius: 12px;
      overflow: hidden;
      margin-right: 4px;
      pointer-events: none;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    & > strong {
      display: block;
      padding-bottom: .4em;
    }

    .live-message-details {
      position: absolute;
      right: 10px;
      bottom: 10px;
      font-size: 80%;
    }
    .live-message-time {
      opacity: .7;
      margin-right: 5px;
    }
  }

  &:not(.me) .live-message-inner {
    border-top-left-radius: 0;
  }

  &.another-agent .live-message-inner {
    background: #f5eaf9 !important;
  }

  &.place-offline .live-message-inner {
    background: #f5eaf9 !important;
  }

  &.automatic .live-message-inner {
    background: #ffedc7 !important;
  }

  &.me .live-message-inner {
    border-top-right-radius: 0 !important;
    background: #752de6 !important;
    border: none !important;
    color: #fff;
  }

  &.fail {
    .live-message-inner {
      background: var(--danger) !important;
      color: #fff;
    }
  }

  &.deleted {
    .live-message-content {
      color: #cfcfcf !important;

      .live-message-text {
        font-style: italic;
      }
    }
  }

  &.me .live-message-box, &.automatic .live-message-box {
    float: right;

    .live-message-source {
      text-align: right;
    }
  }

  &::after {
    content: "";
    display: block;
    clear: both;
  }

  .live-message-attachments {
    video, img, audio {
      max-height: 320px;
      max-width: 100%;
    }
  }

  &.upload {
    .live-message-box {
      .live-message-inner {
        padding-bottom: 14px;
        white-space: nowrap;

        > span {
          margin-right: 4px;
        }
      }

      text-align: center;
      min-width: 1px;
      max-width: 60%;
    }
  }
}

.toggle-meta {
  @media (min-width: 1231px) {
    display: none;
  }
}

.live-input-count {
  position: absolute;
  right: 22px;
  bottom: 2px;
  font-size: 7pt;

  .exceed {
    font-weight: bold;
    color: #f00;
  }
}

.live-speak, .live-enter {
  cursor: pointer;
  padding: 0;
  float: right;
  width: 62px;
  height: 62px;
  border-radius: 62px;
  border: none;
  background: #d494ea;
  font-size: 16pt;
  transition: .3s background ease;
  outline: none;

  &:enabled, .live-speak {
    background: #752de6;
  }

  i {
    color: #fff;
    display: block;
  }
}

.live-enter::before {
  margin: 0 auto;
  display: block;
  content: "";
  height: 50%;
  width: 50%;
  background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 441.30001" version="1.0">%3Cpath fill="%23fff" d="M 39.500001,0.69252634 C 17.300001,5.1925263 1.4042055e-6,26.692526 1.4042055e-6,49.692526 c 0,3.2 5.3999999957945,26.200002 14.5999995957945,62.700004 8.1,31.7 16.7,65.7 19.2,75.4 l 4.4,17.8 H 129.9 c 85.2,0 91.8,0.1 95.1,1.8 10.7,5.3 10.7,21.1 0,26.4 -3.3,1.7 -9.9,1.8 -95.1,1.8 H 38.200001 l -4.4,17.7 c -2.5,9.8 -11.1,43.8 -19.2,75.5 -9.1999996,36.5 -14.5999995957945,59.5 -14.5999995957945,62.7 0,23.8 17.5999995957945,44.9 40.9999995957945,49.1 7.7,1.4 16.7,0.7 24.1,-1.9 12.6,-4.4 418.899999,-173.5 423.799999,-176.4 13.6,-8.1 23.1,-25.3 23.1,-41.7 0,-16.4 -9.5,-33.6 -23.1,-41.7 C 484,175.99253 77.900001,6.9925263 65.000001,2.3925263 c -6.6,-2.29999996 -18.6,-3.09999996 -25.5,-1.69999996 z"/>%3C/svg>') center no-repeat;
  background-size: contain;
}

/*
#live-attach {
  float: left;
}
*/

#live-attach-input {
  display: none;
}

.live-external {
  position: relative;
  background: rgba(0,0,0,.15);
  border-radius: 3px;
  display: block;
  color: #fff;
  overflow: hidden;

  & > i {
    color: inherit;
    text-align: center;
    vertical-align: middle;
    width: 62px;
    font-size: 42pt;
    padding: 8px;
    display: inline-block;
  }

  .live-attach-name {
    vertical-align: middle;
    padding: 3px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: calc(100% - 70px);
    display: inline-block;
    text-align: right;
    direction: rtl;
  }

  .live-attach-type {
    background: rgba(0,0,0,.15);
    padding: 3px 6px;
    clear: both;
  }
}

#live-attach ::v-deep {
  button {
    border: none !important;
    background: transparent !important;
    height: 62px !important;
    font-size: 18pt;
    box-shadow: none !important;

    &:hover, &:active {
      background: #fcfcfc !important;
    }
  }

  ul {
    top: auto !important;
    left: 0 !important;
    right: auto !important;
    bottom: 50px !important;
  }

  .dropdown-toggle::after {
    display: none;
  }

  @media (max-width: 480px) {
    button {
      height: 58px !important;
      font-size: 14pt;
      padding: 0 15px;
    }

    ul {
      bottom: 38px !important;
    }
  }
}
.icon-live {
  font-size: 18pt;
  color: #c1c7cd;
  float: left;
  margin-top: 18px;
}
.fab-item-image{
  text-align: center !important;
}
.fab-item-image i{
  display: block;
  color: rgb(140, 140, 140);
  text-align: center;
  margin-top: 8px;
}

.fa-spinner {
  animation: rotation .32s linear infinite;
  transform-origin: 50% 50%;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
